import React from "react";

function CommunityGuidelines() {
  return (
    <div>
      <div className="pt-4">
        <div className="card">
          <div className="card-header">
            <h3 className="fw-bold">Community Guidelines</h3>
          </div>
          <div className="card-body">
            <div>
              <p>
              Welcome to LiveInGo! Our community is built on respect, inclusivity, and mutual support. To ensure everyone has a positive experience, we've outlined some guidelines for participation. By using LiveInGo, you agree to abide by these guidelines:
              </p>            

              <p>
              <span className="fw-bold">Respect Others</span>: Treat all members of the LiveInGo community with kindness and consideration. Harassment, bullying, hate speech, or discrimination of any kind will not be tolerated.
              </p>

              <p>
              <span className="fw-bold">Be Inclusive</span>: Embrace diversity and inclusivity. LiveInGo is for everyone, regardless of race, ethnicity, gender, sexual orientation, religion, disability, or any other characteristic.
              </p>
              <p>
              <span className="fw-bold">Keep it Safe</span>: Your safety is important to us. Avoid sharing personal information that could compromise your privacy or security. If you feel unsafe or encounter any concerning behavior, please report it to our moderators immediately.
              </p>
              <p>
              <span className="fw-bold">Stay on Topic</span>: Keep discussions relevant to the purpose of LiveInGo, which is to foster meaningful connections and support within the community. Avoid spamming, off-topic posts, or excessive self-promotion.
              </p>
              <p>
              <span className="fw-bold">Respect Intellectual Property</span>: Respect the intellectual property rights of others. Only share content that you have the right to share, and give credit where it's due. Plagiarism and copyright infringement are not permitted.
              </p>

              <p>
              <span className="fw-bold">Mindful Communication</span>: Engage in constructive communication. Disagreements are natural, but be respectful when expressing differing opinions. Refrain from engaging in flame wars or disruptive behavior.
              </p>
              <p>
              <span className="fw-bold">No Illegal Activity</span>: Do not engage in or promote any illegal activities on LiveInGo. This includes but is not limited to the sale of illegal goods, sharing of pirated content, or facilitating unlawful behavior.
              </p>
              <p>
              <span className="fw-bold">Moderation</span>: Our moderators are here to help maintain a positive environment. Follow their guidance and decisions. If you have concerns about moderation, please reach out to us privately.
              </p>
              <p>
              <span className="fw-bold">Use Common Sense</span>: Use your best judgment when interacting on LiveInGo. If something feels inappropriate or questionable, refrain from engaging in it and report it if necessary.
              </p>
              <p>
              <span className="fw-bold">Continuous Improvement</span>: We value feedback from our community members. If you have suggestions for improving LiveInGo or encounter any issues, please let us know. We're committed to making LiveInGo a better place for everyone.
              </p>

              <p>
              Failure to comply with these guidelines may result in warnings, temporary suspension, or permanent expulsion from LiveInGo. Let's work together to create a supportive and welcoming community where everyone feels valued and respected.
              </p>
              <p>Thank you for being a part of LiveInGo!</p>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommunityGuidelines;
