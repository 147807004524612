import React, { useEffect, useState } from "react";

import Banner from '../assets/img/liveingo-banner.jpg'

import { Link } from 'react-router-dom';


import AuthUser from "./AuthUser";
import { useNavigate } from 'react-router-dom';

function Home () {

    const {http} = AuthUser();
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [gender, setGender] = useState([]);
    const [selectedGender, setSelectedGender] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const cityResponse = await http.get(`/all-cities`);
                setCities(cityResponse.data);
                const genderResponse = await http.get(`/all-gender`);
                setGender(genderResponse.data);
            }
            catch(error){
                console.error("Error fetching data:", error);
            }
        }
        fetchData()
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate('/livein-partner', { state: { selectedGender, selectedCity } });
    };

        return (
            <>
            <div className='main-body'>
                <div className='banner-image'>
                    <img src={Banner} alt="" className='img-fluid'/>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="banner-form">
                        <h3 className="text-danger text-left banner-form-heading pb-3 pt-3">
                            Find your partner for Live-in
                        </h3>
                        <div className="row align-items-center row-cols-xl-5 row-cols-lg-3 row-cols-sm-2 row-cols-1">
                            <div className="col-6">
                                <label>Looking for</label>
                                <div className="banner-input">
                                <select
                                    id="gender_id"
                                    name="gender_id"
                                    value={selectedGender || ''}
                                    onChange={(e)=> setSelectedGender(e.target.value)}
                                    >
                                    <option value="">Select Gender</option>
                                    {gender.map((gender,key) => (
                                        <option key={key} value={gender.id}>
                                        {gender.title}
                                        </option>
                                    ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col">
                                <label>City</label>
                                <div className="row">
                                    <div className="banner-input">
                                    <select
                                        id="city_id"
                                        name="city_id"
                                        value={selectedCity || ''}
                                        onChange={(e)=> setSelectedCity(e.target.value)}
                                        >
                                        <option value="">Select City</option>
                                        {cities.map((city,key) => (
                                            <option key={key} value={city.id}>
                                            {city.name}
                                            </option>
                                        ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <label></label>
                                <button type="submit" className="btn btn-danger d-block"><span>Find Your Partner</span></button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className='container pt-4'>
 
                <div className='mb-5 font-18'>
                    We understand that relationships come in all shapes and forms, and live-in relationships are no exception. Whether you’re exploring the idea of living together with your partner or searching for someone to share this unique lifestyle with, you’ve found the right place - Liveingo.com
                </div>

                <div className="mb-4">
                    <h3 className='mb-4 text-center'>Why Choose a Live-In Relationship</h3>
                    <div className="d-flex justify-content-center h-100 align-items-baseline">
                        <div className='col-md-3 text-center d-flex flex-column p-3 font-14'>
                            <h5 className='py-3'>Freedom and Flexibility</h5>
                            <div>Live-in relationships offer the freedom and flexibility to build a partnership on your own terms, without the constraints of traditional marriage.</div>
                        </div>
                        <div className='col-md-3 text-center d-flex flex-column p-3 font-14'>
                            <h5 className='py-3'>Shared Expenses</h5>
                            <div>Sharing living expenses with a partner can alleviate financial burdens and allow for a more comfortable lifestyle.</div>
                        </div>
                        <div className='col-md-3 text-center d-flex flex-column p-3 font-14'>
                            <h5 className='py-3'>Emotional Support</h5>
                            <div>Living with a partner provides constant emotional support and companionship, enhancing overall well-being and happiness.</div>
                        </div>
                        <div className='col-md-3 text-center d-flex flex-column p-3 font-14'>
                            <h5 className='py-3'>Testing Compatibility</h5>
                            <div>For couples considering marriage, living together can serve as a trial period to test compatibility and ensure a strong foundation for a long-term commitment.</div>
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <h3 className='mb-4 text-center'>What We Offer</h3>
                    <div className="d-flex justify-content-center h-100 align-items-baseline">
                        <div className='col-md-4 text-center d-flex flex-column p-3 font-14'>
                            <h5 className='py-3'>Safe and Supportive Environment</h5>
                            <div>Our platform provides a safe and supportive space for individuals to explore the concept of live-in relationships without judgment or stigma.</div>
                        </div>
                        <div className='col-md-4 text-center d-flex flex-column p-3 font-14'>
                            <h5 className='py-3'>Connecting Like-minded Individuals</h5>
                            <div>We connect like-minded individuals who share similar values and aspirations for a live-in partnership, fostering meaningful connections that go beyond conventional dating norms.</div>
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <div className='font-18 text-center'>
                    At LiveInGo.com, you can search and check out for profiles, create your profile.
                    </div>
                </div>

            </div>
            </>
        );
}

export default Home;