import { useEffect } from 'react';

function SidebarToggle() {
  useEffect(() => {
    const handleSidebarToggle = () => {
      document.body.classList.toggle('sb-sidenav-toggled');
      localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
    };

    const sidebarToggle = document.body.querySelector('#sidebarToggle');
    if (sidebarToggle) {
      sidebarToggle.addEventListener('click', handleSidebarToggle);
    }

    // Clean up event listener on component unmount
    return () => {
      if (sidebarToggle) {
        sidebarToggle.removeEventListener('click', handleSidebarToggle);
      }
    };
  }, []); // Empty dependency array means this effect runs only once after the initial render

  return null; // SidebarToggle component doesn't render anything
}

export default SidebarToggle;
