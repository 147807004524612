import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthUser from "../../../AuthUser";
import DataTable from "react-data-table-component";

function ReadHeight() {

    const { http } = AuthUser();
    const [apidata, setApidata] = useState([]);
    const [filterText, setFilterText] = useState('');
    const navigate = useNavigate();
  
    const columns = [
      { name: "Name", selector: (row) => row.title, sortable:true},      
      {
        name: 'Status',
        cell: (row) => (
         <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault"
          checked={row.status} // Set checked based on isChecked value
          disabled
        />
      </div>
        ),
      },
      {
        name: 'Options',
        cell: (row) => (
          <div>
            <button className="btn btn-success rounded-10 me-2" onClick={() => { handleEdit(row.id) }}>
              <i className="fa fa-edit"></i>
            </button>
            <button
              className="btn btn-danger rounded-10"
              onClick={() => {
                if (window.confirm("Are you sure to delete data ??")) {
                  handleDelete(row.id);
                }
              }}
            >
              <i className="fa fa-trash-alt"></i>
            </button>
          </div>
        ),
      },
    ];
  
    function getdata() {
      http.get("/height").then((res) => {
        console.log(res.data);
        setApidata(res.data);
      });
    }
  
    function handleDelete(id) {
      http.delete(`/height/${id}`).then(() => {
        getdata();
        toast.success("height Deleted successfully");
      });
    }
  
    function handleEdit(id) {
      navigate(`/admin/master/height/edit/${id}`);
    }
  
    useEffect(() => {
      getdata();
    }, []);
  
    console.log(apidata);
  
    const filteredData = apidata.filter(item => 
      item.title.toLowerCase().includes(filterText.toLowerCase())
    );
  
    const paginationOptions = {
      rowsPerPageText: 'Items per Page' , // Text for "Rows per page" label
    };

    
    return (
        <div>
        <div className="container-fluid pt-4 px-4">
          <div className="d-flex align-items-center justify-content-center mb-4">
            <div className="col-md-10">
              
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h4 className="">heights</h4>
                <Link to={"/admin/master/height/create"} className="btn btn-primary">Add New heights</Link>
              </div>
  
              <div className="bg-light text-center rounded p-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h5 className="mb-0">All heights</h5>
                  <div className="col-4">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="form-control"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                    </div>
                </div>
                                 
                <DataTable
                  columns={columns}
                  data={filteredData}
                  pagination
                  paginationPerPage={5}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                  paginationComponentOptions={paginationOptions}
                ></DataTable>
  
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default ReadHeight;