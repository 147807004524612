import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from '../components/includes/header.jsx';
import MainHeader from '../components/includes/mainHeader.jsx';
import Dashboard from '../components/admin/dashboard.jsx';
import Footer from '../components/includes/footer.jsx';
import CityForm from './admin/masters/city/CityForm.jsx';
import ReadCity from '../components/admin/masters/city/ReadCity.jsx';
import CountryForm from './admin/masters/country/CountryForm.jsx';
import ReadCountry from '../components/admin/masters/country/ReadCountry.jsx';
import StateForm from './admin/masters/state/StateForm.jsx';
import ReadState from '../components/admin/masters/state/ReadState.jsx';
import ReadEducations from './admin/masters/education/ReadEducations.jsx';
import EducationsForm from './admin/masters/education/EducationsForm.jsx';
import InterestForm from './admin/masters/interest/InterestForm.jsx';
import ReadInterest from './admin/masters/interest/ReadInterest.jsx';
import MaritalstatusForm from './admin/masters/maritalstatus/MaritalstatusForm.jsx';
import ReadMaritalstatus from './admin/masters/maritalstatus/ReadMaritalstatus.jsx';
import SalaryRangeForm from './admin/masters/salaryrange/SalaryRangeForm.jsx';
import ReadSalaryRange from './admin/masters/salaryrange/ReadSalaryRange.jsx';
import ReligionForm from './admin/masters/religion/ReligionForm.jsx';
import ReadReligion from './admin/masters/religion/ReadReligion.jsx';
import ReadCaste from './admin/masters/caste/ReadCaste.jsx';
import CasteForm from './admin/masters/caste/CasteForm.jsx';
import ReadInterestCategory from './admin/masters/interestCategory/ReadInterestCategory.jsx';
import InterestCategoryForm from './admin/masters/interestCategory/InterestCategoryForm.jsx';
import SidebarToggle from './includes/SidebarToggle.jsx';
import LanguageForm from './admin/masters/language/LanguageForm.jsx';
import ReadLanguage from './admin/masters/language/ReadLanguage.jsx';
import ReadGender from './admin/masters/gender/ReadGender.jsx';
import GenderForm from './admin/masters/gender/GenderForm.jsx';
import OccupationForm from './admin/masters/occupation/OccupationForm.jsx';
import ReadOccupation from './admin/masters/occupation/ReadOccupation.jsx';
import ReadProfession from './admin/masters/profession/ReadProfession.jsx';
import ProfessionForm from './admin/masters/profession/ProfessionForm.jsx';
import UserForm from './admin/user/UserForm.jsx';
import ReadUser from './admin/user/ReadUser.jsx';
import BodyTypeForm from './admin/masters/bodyType/BodyTypeForm.jsx';
import ReadBodyType from './admin/masters/bodyType/ReadBodyType.jsx';
import ComplexionForm from './admin/masters/complexion/ComplexionForm.jsx';
import ReadComplexion from './admin/masters/complexion/ReadComplexion.jsx';
import HeightForm from './admin/masters/height/HeightForm.jsx';
import ReadHeight from './admin/masters/height/ReadHeight.jsx';
import WeightForm from './admin/masters/weight/WeightForm.jsx';
import ReadWeight from './admin/masters/weight/ReadWeight.jsx';

function Auth() {
  return (
    <div>
      <div className="position-relative bg-white p-0">
      <MainHeader />
        <div id="layoutSidenav">          
          <Header />    
          <SidebarToggle/>      
          <div id="layoutSidenav_content">
          <main>
          <Routes>
            <Route path="admin/dashboard" element={<Dashboard />} />
            <Route path="admin/master/city/create" element={<CityForm />} />
            <Route path="admin/master/city/read" element={<ReadCity />} />
            <Route path="admin/master/city/edit/:id" element={<CityForm />} />

            <Route path="admin/master/country/create" element={<CountryForm />} />
            <Route path="admin/master/country/read" element={<ReadCountry />} />
            <Route path="admin/master/country/edit/:id" element={<CountryForm />} />

            <Route path="admin/master/state/create" element={<StateForm />} />
            <Route path="admin/master/state/read" element={<ReadState />} />
            <Route path="admin/master/state/edit/:id" element={<StateForm />} />

            <Route path="admin/master/education/create" element={<EducationsForm />} />
            <Route path="admin/master/education/read" element={<ReadEducations />} />
            <Route path="admin/master/education/edit/:id" element={<EducationsForm />} />
            
            <Route path="admin/master/interest/create" element={<InterestForm />} />
            <Route path="admin/master/interest/read" element={<ReadInterest />} />
            <Route path="admin/master/interest/edit/:id" element={<InterestForm />} />
            
            <Route path="admin/master/maritalstatus/create" element={<MaritalstatusForm />} />
            <Route path="admin/master/maritalstatus/read" element={<ReadMaritalstatus />} />
            <Route path="admin/master/maritalstatus/edit/:id" element={<MaritalstatusForm />} />
            
            <Route path="admin/master/salaryrange/create" element={<SalaryRangeForm />} />
            <Route path="admin/master/salaryrange/read" element={<ReadSalaryRange />} />
            <Route path="admin/master/salaryrange/edit/:id" element={<SalaryRangeForm />} />
            
            <Route path="admin/master/religion/create" element={<ReligionForm />} />
            <Route path="admin/master/religion/read" element={<ReadReligion />} />
            <Route path="admin/master/religion/edit/:id" element={<ReligionForm />} />
            
            <Route path="admin/master/caste/create" element={<CasteForm />} />
            <Route path="admin/master/caste/read" element={<ReadCaste />} />
            <Route path="admin/master/caste/edit/:id" element={<CasteForm />} />
            
            <Route path="admin/master/interestCategory/create" element={<InterestCategoryForm />} />
            <Route path="admin/master/interestCategory/read" element={<ReadInterestCategory />} />
            <Route path="admin/master/interestCategory/edit/:id" element={<InterestCategoryForm />} />
            
            <Route path="admin/master/language/create" element={<LanguageForm />} />
            <Route path="admin/master/language/read" element={<ReadLanguage />} />
            <Route path="admin/master/language/edit/:id" element={<LanguageForm />} />

            <Route path="admin/master/gender/create" element={<GenderForm />} />
            <Route path="admin/master/gender/read" element={<ReadGender />} />
            <Route path="admin/master/gender/edit/:id" element={<GenderForm />} />

            <Route path="admin/master/occupation/create" element={<OccupationForm />} />
            <Route path="admin/master/occupation/read" element={<ReadOccupation />} />
            <Route path="admin/master/occupation/edit/:id" element={<OccupationForm />} />

            <Route path="admin/master/profession/create" element={<ProfessionForm />} />
            <Route path="admin/master/profession/read" element={<ReadProfession />} />
            <Route path="admin/master/profession/edit/:id" element={<ProfessionForm />} />
            
            <Route path="admin/master/bodyType/create" element={<BodyTypeForm />} />
            <Route path="admin/master/bodyType/read" element={<ReadBodyType />} />
            <Route path="admin/master/bodyType/edit/:id" element={<BodyTypeForm />} />
            
            <Route path="admin/master/complexion/create" element={<ComplexionForm />} />
            <Route path="admin/master/complexion/read" element={<ReadComplexion />} />
            <Route path="admin/master/complexion/edit/:id" element={<ComplexionForm />} />
            
            <Route path="admin/master/height/create" element={<HeightForm />} />
            <Route path="admin/master/height/read" element={<ReadHeight />} />
            <Route path="admin/master/height/edit/:id" element={<HeightForm />} />
            
            <Route path="admin/master/weight/create" element={<WeightForm />} />
            <Route path="admin/master/weight/read" element={<ReadWeight />} />
            <Route path="admin/master/weight/edit/:id" element={<WeightForm />} />

            <Route path="admin/user/create" element={<UserForm />} />
            <Route path="admin/user/read" element={<ReadUser/>} />
            <Route path="admin/user/edit/:id" element={<UserForm />} />
            
          </Routes>
          </main>
          <Footer />
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default Auth;
