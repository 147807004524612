import React from "react";
import "../assets/css/style.css";
import { Route, Routes } from "react-router-dom";
import Home from "../components/Home.jsx";
import WebNav from "../components/includes/WebNav.jsx";
import WebFooter from "../components/includes/WebFooter.jsx";
import Registration from "./user/Registration.jsx";
import AfterRegistration from "./user/AfterRegistration.jsx";
import VerifiedSuccessfully from "./user/VerifiedSuccessfully.jsx"
import UserLogin from "./user/UserLogin.jsx";
import PrivacyPolicy from "./PrivacyPolicy.jsx";
import RefundCancellationPolicy from "./RefundCancellationPolicy.jsx";
import TermsCondition from "./TermsCondition.jsx";
import CommunityGuidelines from "./CommunityGuidelines.jsx";
import ContactUs from "./ContactUs.jsx";
import Faqs from "./Faqs.jsx";
import SafetyTips from "./SafetyTips.jsx";
import PrivacySetting from "./user/PrivacySetting.jsx";
import UserProfile from "./user/UserProfile.jsx";
import ProfilePerformance from "./user/ProfilePerformance.jsx";
import MyFavourites from "./user/MyFavourites.jsx";
import MessageBox from "./user/MessageBox.jsx";
import Profile from "./user/Profile.jsx";
import LiveinPartner from "./LiveinPartner.jsx";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useAuth } from "./AuthContext.jsx";
import PageNotFound from "./PageNotFound.jsx";
import ProfileDetails from "./user/ProfileDetails.jsx";

function AuthRoutes() {
  return (
    <Routes>
      
      <Route path="/user/privacy-setting" element={<PrivacySetting />} />
      <Route
        path="/user/profile-performance"
        element={<ProfilePerformance />}
      />
      <Route path="/user/favourite-partners" element={<MyFavourites />} />
      <Route path="/user/message" element={<MessageBox />} />      
      {/* <Route path="/user/profile" element={<Profile />} /> */}
      {/* Add a catch-all route to redirect to the user profile */}
      {/* <Route path="*" element={<Navigate to="/user/my-profile" />} /> */}
    </Routes>
  );
}

function Guest() {
  return (
    <div>
      <GoogleOAuthProvider clientId="999817219665-4e7m5cu7qtumvbrm6i0l8sooqkk5fro6.apps.googleusercontent.com">
        <WebNav />
        <div className="container-fluid position-relative bg-white px-0 mt-5">
          <Routes>
            <Route path="user/login" element={<UserLogin />} />
            <Route path="/" element={<Home />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/refund-cancellation-policy"
              element={<RefundCancellationPolicy />}
            />
            <Route path="/terms-condition" element={<TermsCondition />} />
            <Route
              path="/community-guidelines"
              element={<CommunityGuidelines />}
            />
            <Route
              path="/safety-tips-for-livein-partner"
              element={<SafetyTips />}
            />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/faq" element={<Faqs />} />
            <Route path="/livein-partner" element={<LiveinPartner />} />            
            <Route path="/user/registration" element={<Registration />} />
            <Route path="/user/after-registration" element={<AfterRegistration />} />
            <Route path="/user/verified-registration" element={<VerifiedSuccessfully />} />
            <Route path="/user/profile" element={<Profile />} />
            <Route path="/user/my-profile" element={<UserProfile />} />
            <Route path="/user/profile-details/:id" element={<ProfileDetails />} />

            {/* <Route path="*" element={<Navigate to="/user/login" />} /> */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
        <WebFooter />
      </GoogleOAuthProvider>
      
    </div>
  );
}

function GuestOrAuth() {
  const { isAuthenticated } = useAuth(); // Assuming you have a hook or context to check authentication
  return isAuthenticated ? <AuthRoutes /> : <Guest />;
}

export default GuestOrAuth;
