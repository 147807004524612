import React, { useState,useEffect  } from 'react';
import { Modal } from 'react-bootstrap';

import Banner from '../../assets/img/pageheader.jpg';
import NoImage from '../../assets/img/no_profile.jpg';
import UserForm from '../admin/user/UserForm'; // Import the StepOne component
import AuthUser from '../AuthUser';
import { calculateAge,calculateDaysAgo } from '../utility/dateUtils';
import { ProgressBar } from 'react-bootstrap';
import { Hearts } from 'react-loader-spinner'
import {Card,Button } from 'antd';
import DragAndDrop from '../user/DragAndDrop.jsx';
import { toast } from 'react-toastify';

function ProfileDetails() {
    const [show, setShow] = useState(true);
    const {http,getUser} = AuthUser();
    const currentUser = getUser();
    const userId = currentUser?.id
    const [userdata,setUserData] = useState({});
    const [userProfiledata,setUserProfileData] = useState({});
    const [age, setAge] = useState(null);
    const [lastActive, setActive] = useState('');
    const [loading, setLoading] = useState(false);
    const [completionPercentage, setCompletionPercentage] = useState(0);
    const [userProfileID, setUserProfileID] = useState('');
    const imagePath = process.env.REACT_APP_IMAGE_PATH;

    const prefCitiesTitles = userProfiledata.data?.pref_city_titles;
    const prefGenderTitles = userProfiledata.data?.pref_gender_titles;
    const prefEducationTitles = userProfiledata.data?.pref_education_titles;
    const prefLanguageTitles = userProfiledata.data?.pref_language_titles;
    const prefMaritalstatusTitles = userProfiledata.data?.pref_maritalstatus_titles;
    const prefOccupationTitles = userProfiledata.data?.pref_occupation_titles;
    const prefProfessionTitles = userProfiledata.data?.pref_profession_titles;
    const prefReligionTitles = userProfiledata.data?.pref_religion_titles;
    const prefCasteTitles = userProfiledata.data?.pref_caste_titles;
    const prefBodyTypeTitles = userProfiledata.data?.pref_bodyType_titles;
    const prefComplexionTitles = userProfiledata.data?.pref_complexion_titles;

    const [files, setFiles] = useState([]);
    useEffect(() => {
        setShow(false);
        fetchData();
      }, []);



      const fetchData = async () => {
        setLoading(true);
        try {
          const userRes = await http.get(`user/${userId}`);
          const apidata = userRes.data;
          setUserData(apidata);

          if (apidata.profileExists) {
            const profileRes = await http.get(`user/profile/${userId}`);
            setUserProfileData(profileRes.data);
            setAge(calculateAge(profileRes.data.data.dob));
            setActive(calculateDaysAgo(profileRes.data.data.updated_at));
            setCompletionPercentage(profileRes.data.completion_percentage);
            setUserProfileID(profileRes.data.data.id);
          }
      
          // Use a separate useEffect or callback to log the updated state
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        finally {
            setLoading(false);
          }
      };
    
    const handleClose = () => setShow(false);
    const handleCompleteProfile = () => {
        setShow(true);
      };

      const handleFormSubmit = async () => {
        fetchData();
    };

    const addFile = (file) => {
        setFiles(prevFiles => [...prevFiles, file]);
      };

      const removeFile = (file) => {
        setFiles((prevFiles) => prevFiles.filter((f) => f.uid !== file.uid));
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
      //  setLoading(true);
        if (files.length === 0) {
            toast.error('Please select files to upload');
          return;
        }
    
        const formData = new FormData();
        formData.append('user_id', userId); // Example user_id, replace with actual value
        formData.append('user_profile_id',userProfileID)
        files.forEach((file) => {
            formData.append('images[]', file);
          });

        try {
          const response = await http.post('/upload-images', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            
          });
          toast.success('Upload successful');
          setLoading(false);
          handleFormSubmit();
          console.log(response.data);
        } catch (error) {
            toast.error('Upload failed');
          console.error(error);
          setLoading(false);
        }
      };

    return (
        <div>
             {loading && (
        <div className="spinner-container">
          <Hearts
            height="80"
            width="80"
            color="red"
            ariaLabel="loading"
          />
        </div>
      )}
           <div>
            <img src={Banner} alt="" className='img-fluid'/>
           </div>
            
           <div className="group group--single padding-bottom" style={{background: '#f1f7fe'}}>
            <div className="group__top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 d-none d-xl-block"></div>
                        <div className="col-xl-9">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="gt1-tab" data-bs-toggle="tab" data-bs-target="#gt1" type="button" role="tab" aria-controls="gt1" aria-selected="true"><i className="fa fa-user"></i> Profile</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="gt2-tab" data-bs-toggle="tab" data-bs-target="#gt2" type="button" role="tab" aria-controls="gt2" aria-selected="false">
                                        <i className="fa fa-list-alt"></i> Summery 
                                    </button>
                                </li>                       
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
    <div className="group__bottom">
        <div className="container">
            <div className="row g-4">
                <div className="col-xl-6 order-xl-1">
                    <div className="group__bottom--left">
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="gt1" role="tabpanel" aria-labelledby="gt1-tab">
                            <div className="info shadow">
                               <div className='info-card mb-4 shadow'>
                                   {/* <div className="info-card-title"><h6>Profile Completion </h6></div> */}
                                   <div className='info-card-content d-flex justify-content-between'>
                                      <div className='w-75'>
                                        <ProgressBar now={completionPercentage} animated striped variant="success" className="my-3" />
                                        <h6>Profile Completion : {completionPercentage}%</h6>
                                        </div>
                                      <div><button className='btn btn-success btn-small btn_profile_complete' type='button' onClick={handleCompleteProfile}>{completionPercentage === 100 ? 'Update Profile' : 'Complete Now'}</button></div>
                                   </div>
                                </div>
                            </div>
                            <div className="info">
                                    <div className="info-card mb-4 shadow">
                                        <div className="info-card-title"><h6>Personal Details</h6></div>
                                        <div className="info-card-content">
                                            <ul className="info-list">
                                                <li>
                                                    <p className="info-name">Name</p>
                                                    <p className="info-details">{userdata?.data?.name}</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">Gender</p>
                                                    <p className="info-details">{userProfiledata?.data?.gender?.title}</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">Marital Status</p>
                                                    <p className="info-details">{userProfiledata?.data?.marital_status?.title}</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">Age</p>
                                                    <p className="info-details">{age} yr</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">Date of Birth</p>
                                                    <p className="info-details">{userProfiledata?.data?.dob}</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">Country</p>
                                                    <p className="info-details">{userProfiledata?.data?.country?.name}</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">State</p>
                                                    <p className="info-details">{userProfiledata?.data?.state?.name}</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">City</p>
                                                    <p className="info-details">{userProfiledata?.data?.city?.name}</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">Education</p>
                                                    <p className="info-details">{userProfiledata?.data?.education?.title}</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">Occupation</p>
                                                    <p className="info-details">{userProfiledata?.data?.occupation?.title}</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">Profession</p>
                                                    <p className="info-details">{userProfiledata?.data?.profession?.title}</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">Language</p>
                                                    <p className="info-details">{userProfiledata?.data?.language?.title}</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">Religion</p>
                                                    <p className="info-details">{userProfiledata?.data?.religion?.title}</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">Caste</p>
                                                    <p className="info-details">{userProfiledata?.data?.caste?.title}</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    <div className="info-card mb-4 shadow">
                                        <div className="info-card-title"><h6>Partner Preference Details</h6></div>
                                        <div className="info-card-content">
                                             <ul className="info-list">
                                                <li>
                                                    <p className="info-name">Country</p>
                                                    <p className="info-details">{userProfiledata?.data?.country?.name}</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">State</p>
                                                    <p className="info-details">{userProfiledata?.data?.state?.name}</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">City</p>
                                                    { prefCitiesTitles && ( 
                                                     <p className="info-details">{prefCitiesTitles.join(', ')}</p> 
                                                     )}
                                                </li>
                                                <li>
                                                    <p className="info-name">Mother Toung</p>
                                                    { prefLanguageTitles && ( 
                                                     <p className="info-details">{prefLanguageTitles.join(', ')}</p> 
                                                     )}
                                                </li>
                                                <li>
                                                    <p className="info-name">Education</p>
                                                    { prefEducationTitles && ( 
                                                     <p className="info-details">{prefEducationTitles.join(', ')}</p> 
                                                     )}
                                                </li>
                                                <li>
                                                    <p className="info-name">Marital Status</p>
                                                    { prefMaritalstatusTitles && ( 
                                                     <p className="info-details">{prefMaritalstatusTitles.join(', ')}</p> 
                                                     )}
                                                </li>
                                                <li>
                                                    <p className="info-name">Occupation</p>
                                                    { prefOccupationTitles && ( 
                                                     <p className="info-details">{prefOccupationTitles.join(', ')}</p> 
                                                     )}
                                                </li>
                                                <li>
                                                    <p className="info-name">Profession</p>
                                                    { prefProfessionTitles && ( 
                                                     <p className="info-details">{prefProfessionTitles.join(', ')}</p> 
                                                     )}
                                                </li>
                                                <li>
                                                    <p className="info-name">Religion</p>
                                                    { prefReligionTitles && ( 
                                                     <p className="info-details">{prefReligionTitles.join(', ')}</p> 
                                                     )}
                                                </li>
                                                <li>
                                                    <p className="info-name">Caste</p>
                                                    { prefCasteTitles && ( 
                                                     <p className="info-details">{prefCasteTitles.join(', ')}</p> 
                                                     )}
                                                </li>
                                                <li>
                                                    <p className="info-name">Gender</p>
                                                    { prefGenderTitles && ( 
                                                     <p className="info-details">{prefGenderTitles.join(', ')}</p> 
                                                     )}
                                                </li>
                                                <li>
                                                    <p className="info-name">Body Type</p>
                                                    { prefBodyTypeTitles && ( 
                                                     <p className="info-details">{prefBodyTypeTitles.join(', ')}</p> 
                                                     )}
                                                </li>
                                                <li>
                                                    <p className="info-name">Complexion</p>
                                                    { prefComplexionTitles && ( 
                                                     <p className="info-details">{prefComplexionTitles.join(', ')}</p> 
                                                     )}
                                                </li>
                                                <li>
                                                    <p className="info-name">Weight</p>
                                                    <p className="info-details">From {userProfiledata?.data?.pref_weight_from} kg to {userProfiledata?.data?.pref_weight_to} kg</p> 
                                                </li>
                                                <li>
                                                    <p className="info-name">Height</p>
                                                    <p className="info-details">From {userProfiledata?.data?.pref_height_from} cm to {userProfiledata?.data?.pref_height_to} cm</p> 
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="info-card mb-4 shadow">
                                        <div className="info-card-title"><h6>Hobbies & Interest</h6></div>
                                        <div className="info-card-content">

                                            
                                            
                                        {userProfiledata?.data?.pref_interest_titles.map((interest, index) => (
                                                <div key={index} className="badge mr-2 mb-2" style={{ cursor: "pointer" }}>
                                                    {interest}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="info-card mb-4 shadow">
                                        <div className="info-card-title"><h6>Contact Details</h6></div>
                                        <div className="info-card-content">
                                             <ul className="info-list">
                                                <li>
                                                    <p className="info-name">Mobile Number</p>
                                                    <p className="info-details">{userProfiledata?.data?.phone}</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">Email ID</p>
                                                    <p className="info-details">{userdata?.data?.email}</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="info-card shadow">
                                        <div className="info-card-title"><h6>Physical info</h6></div>
                                        <div className="info-card-content">
                                            <ul className="info-list">
                                                <li>
                                                    <p className="info-name">Height</p>
                                                    <p className="info-details">{userProfiledata?.data?.height}cm</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">Weight</p>
                                                    <p className="info-details">{userProfiledata?.data?.weight}Kg</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">Body Type</p>
                                                    <p className="info-details">{userProfiledata?.data?.body_type?.title}</p>
                                                </li>
                                                <li>
                                                    <p className="info-name">Complexion</p>
                                                    <p className="info-details">{userProfiledata?.data?.complexion?.title}</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="gt2" role="tabpanel" aria-labelledby="gt2-tab">
                                <div className="info-card shadow mb-4">
                                    <div className="info-card-title"><h6>Myself Summary</h6></div>
                                    <div className="info-card-content">
                                        <p>
                                        {userProfiledata?.data?.comments}</p>
                                    </div>
                                </div>
                            </div>                 
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 order-xl-0">
                    <div className="group__bottom--center shadow">
                        <div className="story__item style2">
                            <div className="story__inner">
                                <div className="story__thumb position-relative">
                                    <img 
                                    src={userProfiledata?.data?.profile_img ? `${imagePath}/${userId}/${userProfiledata?.data?.profile_img}` : NoImage}
                                    alt="dating thumb" className='img-fluid'/>
                                </div>
                                <div className="story__content px-0 pb-0">
                                    <h4 className='mt-3'>{userdata?.data?.name}</h4>
                                    <div className="story__content--content mb-2">
                                        <p><i className="fa fa-clock"></i> {`Active ${lastActive} Days Ago`}</p>
                                    </div>
                                    <p className="mb-2">{userProfiledata?.data?.short_dis}</p>
                                    <div className="story__content--author mt-3 pb-2">
                                        <h6 className="d-block w-100 mb-3">{userdata?.data?.name}'s Photos</h6>
                                        <div className="row g-2 mt-3">
                                        {userProfiledata?.data?.images.map((image, index) => (
                                                    <div className="col-4 uploaded_imgs" key={index}>
                                                    <img src={`${imagePath}/${userId}/${image.path}`} alt={image.path} className='img-fluid'/>
                                                    </div>
                                                ))}
                                        
                                        <form method="POST" encType="multipart/form-data">
                                            <Card
                                                style={{ margin: 'auto', width: '100%' }}
                                                actions={[<Button className='btn btn-custom-upload' type="primary" onClick={handleSubmit}>Upload Images</Button>]}
                                            >
                                                <DragAndDrop addFile={addFile} removeFile={removeFile} />
                                            </Card>
                                        </form>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 order-xl-2">
                    <div className="group__bottom--right shadow">
                        <div className="modal-content border-0 mb-4">
                            <div className="modal-header info-card-title"><h6 id="exampleModalLabel1">Filter your search</h6></div>
                            <div className="modal-body info-card-content">
                                <form action="#">
                                    <div className="banner__list">
                                        <div className="row align-items-center row-cols-1">
                                            <div className="col mb-3">
                                                <label className="mb-1">I am a</label>
                                                <div className="banner__inputlist">
                                                    <select className='form-select'>
                                                        <option value="gender">Select Gender</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="others">Others</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col mb-3">
                                                <label className="mb-1">Looking for</label>
                                                <div className="banner__inputlist">
                                                    <select defaultValue="">
                                                        <option value="" disabled>Select Gender</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="others">Others</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col mb-3">
                                                <label className="mb-1">Age</label>
                                                <div className="row g-3">
                                                    <div className="col-6">
                                                        <div className="banner__inputlist">
                                                            <select defaultValue="">
                                                                <option value="18">18</option>
                                                                <option value="19">19</option>
                                                                <option value="20">20</option>
                                                                <option value="21">21</option>
                                                                <option value="22">22</option>
                                                                <option value="23">23</option>
                                                                <option value="24">24</option>
                                                                <option value="25">25</option>
                                                                <option value="26">26</option>
                                                                <option value="27">27</option>
                                                                <option value="28">28</option>
                                                                <option value="29">29</option>
                                                                <option value="30">30</option>
                                                                <option value="31">31</option>
                                                                <option value="32">32</option>
                                                                <option value="33">33</option>
                                                                <option value="34">34</option>
                                                                <option value="35">35</option>
                                                                <option value="36">36</option>
                                                                <option value="37">37</option>
                                                                <option value="38">38</option>
                                                                <option value="39">39</option>
                                                                <option value="40">40</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 mb-3">
                                                        <div className="banner__inputlist">
                                                            <select defaultValue="">
                                                                <option value="18">18</option>
                                                                <option value="19">19</option>
                                                                <option value="20">20</option>
                                                                <option value="21">21</option>
                                                                <option value="22">22</option>
                                                                <option value="23">23</option>
                                                                <option value="24">24</option>
                                                                <option value="25">25</option>
                                                                <option value="26">26</option>
                                                                <option value="27">27</option>
                                                                <option value="28">28</option>
                                                                <option value="29">29</option>
                                                                <option value="30">30</option>
                                                                <option value="31">31</option>
                                                                <option value="32">32</option>
                                                                <option value="33">33</option>
                                                                <option value="34">34</option>
                                                                <option value="35">35</option>
                                                                <option value="36">36</option>
                                                                <option value="37">37</option>
                                                                <option value="38">38</option>
                                                                <option value="39">39</option>
                                                                <option value="40">40</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col mb-3">
                                                <label className="mb-1">Country</label>
                                                <div className="banner__inputlist">
                                                    <select defaultValue="">
                                                        <option value="Afganistan">India</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col mb-3">
                                                <button type="submit" className="btn btn-danger d-block w-100"><span>Find Your Partner </span></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
        <Modal show={show} onHide={handleClose} animation={true} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Profile Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                 <UserForm handleClose={handleClose} handleFormSubmit={handleFormSubmit}  />
            </Modal.Body>
        </Modal>
        </div>
    );
}

export default ProfileDetails;