import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import AuthUser from '../../../AuthUser';
import { useNavigate, useParams } from "react-router-dom";

function ProfessionForm() {
    // you can adjust the component's behavior based on the presence of a id prop. If the id prop is present, it means the component is in edit mode, and you fetch the data for the specific occupation. Otherwise, it's in add mode.
    const { http } = AuthUser();
    const [occupations, setOccupations] = useState([]);
    const [title, setTitle] = useState("");
    const [status, setStatus] = useState(0);
    const [selectedOccupation, setselectedOccupation] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await http.get('/occupation');
                setOccupations(response.data);
                if (id) {
                    // Fetch profession data if in edit mode
                    const professionResponse = await http.get(`/profession/${id}`);
                    const { title, occupation_id, status } = professionResponse.data;
                    setTitle(title);
                    setStatus(status);
                    //setting up occupation Dropdown
                    const selectedOccupationData = response.data.find(occupation => occupation.id === occupation_id);
                    if (selectedOccupationData) {
                        setselectedOccupation({ value: selectedOccupationData.id, label: selectedOccupationData.title });
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]); // Add id to dependency array to re-fetch profession data when id changes


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedOccupation || !title.trim()) {
            toast.error("Please fill all fields");
            return;
        }

        setLoading(true);
        try {
            if (id) {
                // If in edit mode, update existing profession
                await http.put(`/profession/${id}`, {
                    title: title,
                    occupation_id: selectedOccupation.value,
                    status: status
                });
                toast.success("profession updated successfully");
                navigate("/admin/master/profession/read");
            } else {
                // If in add mode, create new profession
                await http.post('/profession', {
                    title: title,
                    occupation_id: selectedOccupation.value,
                    status: status
                });
                toast.success("profession added successfully");
                navigate("/admin/master/profession/read");
            }
        } catch (error) {
            console.error("Failed to save profession:", error);
            toast.error("Failed to save profession");
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = async () => {
        setStatus((prevStatus) => prevStatus === 1 ? 0 : 1); // Toggle status between 0 and 1
    };

    return (
        <div>
            <div className="container-fluid p-4">
                <div className="d-flex align-items-center justify-content-center mb-4">
                    <div className="col-md-6 bg-light p-3">
                        <div className="mb-4">
                            <h4>{id ? 'Edit profession' : 'Add New profession'}</h4>
                        </div>
                        <hr />
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mb-3">
                                <label htmlFor="occupationInput">Occupation</label>
                                <Select
                                    id="occupationInput"
                                    options={occupations.map(occupation => ({ value: occupation.id, label: occupation.title }))}
                                    value={selectedOccupation}
                                    onChange={setselectedOccupation}
                                    isSearchable={true}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="professionInput">Profession</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="professionInput"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                            <div className="form-check form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                    checked={status === 1}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                    Status
                                </label>
                            </div>
                            <button type="submit" className="btn btn-success mb-2" disabled={loading}>
                                {loading ? 'Submitting...' : id ? 'Update' : 'Submit'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfessionForm;