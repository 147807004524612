import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import AuthUser from '../../../AuthUser';
import { useNavigate, useParams } from "react-router-dom";

function CasteForm() {
    // you can adjust the component's behavior based on the presence of a id prop. If the id prop is present, it means the component is in edit mode, and you fetch the data for the specific Religion. Otherwise, it's in add mode.
    const { http } = AuthUser();
    const [religions, setReligions] = useState([]);
    const [title, setTitle] = useState("");
    const [status, setStatus] = useState(0);
    const [selectedReligion, setSelectedReligion] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await http.get('/religion');
                setReligions(response.data);
                if (id) {
                    // Fetch Caste data if in edit mode
                    const casteResponse = await http.get(`/caste/${id}`);
                    const { title, religion_id, status } = casteResponse.data;
                    setTitle(title);
                    setStatus(status);
                    //setting up Religion Dropdown
                    const selectedReligionData = response.data.find(religion => religion.id === religion_id);
                    if (selectedReligionData) {
                        setSelectedReligion({ value: selectedReligionData.id, label: selectedReligionData.title });
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]); // Add id to dependency array to re-fetch Caste data when id changes


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedReligion || !title.trim()) {
            toast.error("Please fill all fields");
            return;
        }

        setLoading(true);
        try {
            if (id) {
                // If in edit mode, update existing Caste
                await http.put(`/caste/${id}`, {
                    title: title,
                    religion_id: selectedReligion.value,
                    status: status
                });
                toast.success("Caste updated successfully");
                navigate("/admin/master/caste/read");
            } else {
                // If in add mode, create new Caste
                await http.post('/caste', {
                    title: title,
                    religion_id: selectedReligion.value,
                    status: status
                });
                toast.success("Caste added successfully");
                navigate("/admin/master/caste/read");
            }
        } catch (error) {
            console.error("Failed to save caste:", error);
            toast.error("Failed to save caste");
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = async () => {
        setStatus((prevStatus) => prevStatus === 1 ? 0 : 1); // Toggle status between 0 and 1
    };

    return (
        <div>
            <div className="container-fluid p-4">
                <div className="d-flex align-items-center justify-content-center mb-4">
                    <div className="col-md-6 bg-light p-3">
                        <div className="mb-4">
                            <h4>{id ? 'Edit caste' : 'Add New caste'}</h4>
                        </div>
                        <hr />
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mb-3">
                                <label htmlFor="ReligionInput">Religion</label>
                                <Select
                                    id="ReligionInput"
                                    options={religions.map(religion => ({ value: religion.id, label: religion.title }))}
                                    value={selectedReligion}
                                    onChange={setSelectedReligion}
                                    isSearchable={true}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="casteInput">Caste</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="casteInput"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                            <div className="form-check form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                    checked={status === 1}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                    Status
                                </label>
                            </div>
                            <button type="submit" className="btn btn-success mb-2" disabled={loading}>
                                {loading ? 'Submitting...' : id ? 'Update' : 'Submit'}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CasteForm;