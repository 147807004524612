import React from "react";
import { Link } from "react-router-dom";
import '../../assets/css/webFooter.css';

function WebFooter() {
    const year = new Date().getFullYear();

  return (
    <div>
      <footer>
        <div className="container">
          <div className="d-block justify-content-between text-center d-lg-flex"> 
          <div className="box">
              <span className="copyright quick-links">
                Copyright &copy; {year} LiveInGo
              </span>
            </div>          
            <div className="box">
              <ul className="list-inline social-buttons">
                <li className="list-inline-item">
                  <Link to={''}>
                    <i className="fab fa-twitter"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={''}>
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to={''}>
                    <i className="fab fa-linkedin-in"></i>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="box">
              <ul className="list-inline quick-links">
                <li className="list-inline-item">
                  <Link to={'/privacy-policy'}>Privacy Policy</Link>
                </li>
                <li className="list-inline-item">
                  <Link to={'/refund-cancellation-policy'}>Refund and Cancellation Policy</Link>
                </li>
                <li className="list-inline-item">
                  <Link to={'/terms-condition'}>Terms &amp; Condition</Link>
                </li>
                <li className="list-inline-item">
                  <Link to={'/community-guidelines'}>Community Guidelines</Link>
                </li>
                <li className="list-inline-item">
                  <Link to={'/safety-tips-for-livein-partner'}>Safety Tips</Link>
                </li>
                <li className="list-inline-item">
                  <Link to={'/admin/login'}>Admin</Link>
                </li>
              </ul>
            </div>
          </div>
         
        </div>
      </footer>
    </div>
  );
}

export default WebFooter;
