import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import DataTable from "react-data-table-component";
import AuthUser from "../../AuthUser";

function ReadState() {
  const {http} = AuthUser();
  const [apidata, setApidata] = useState([]);
   const [filterText, setFilterText] = useState('');
   const navigate = useNavigate();
   const imagePath = process.env.REACT_APP_IMAGE_PATH;

  const columns = [
    {
        name: "Profile Img",
        cell: (row) => (
         <div className="d-flex justify-content-center w-100">
             <img src={`${imagePath}/${row.user_id}/${row.profile_img}`} alt={`Profile Img`} style={{ width: '50px', borderRadius: '2%', textAlign: 'center' }}/>
         </div>
        ),
      },
      { name: "Name", selector: (row) => row.user?.name, sortable: true },
      { name: "Email", selector: (row) => row.user?.email, sortable: true },
      { name: "Phone", selector: (row) => row.phone, sortable:true},
    { name: "Country", selector: (row) => row.country?.name, sortable: true }, // Display country name
    // { name: "State", selector: (row) => row.state.name, sortable: true }, // Display country name
    { name: "City", selector: (row) => row.city?.name, sortable: true }, // Display country name
    { name: "Religion", selector: (row) => row.religion?.title, sortable: true }, // Display country name

    {
      name: "Options",
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn btn-success rounded-10 me-2"
            onClick={() => {
              handleEdit(row.id);
            }}
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            className="btn btn-danger rounded-10"
            onClick={() => {
              if (window.confirm("Are you sure to delete data ??")) {
                handleDelete(row.id);
              }
            }}
          >
            <i className="fa fa-trash-alt"></i>
          </button>
        </div>
      ),
    },
  ];



  useEffect(() => {
    getdata();
  }, []);
  
  function getdata() {
    http.get("/user-profile").then((res) => {
    //   console.log(res.data);
      setApidata(res.data);
    });
  }

  function handleDelete(id) {
    http.delete(`/user-profile/${id}`).then(() => {
      getdata();
      toast.success("user profile Deleted successfully");
    }).catch(()=>{
        toast.success("user profile Deleted fail");
    });
  }
  
  function handleEdit(id) {
    navigate(`/admin/user/edit/${id}`);
  }  



  const filteredData = apidata.filter(item => {
    const countryName = item.country?.name || ''; // handle null or undefined
    const cityName = item.city?.name || ''; // handle null or undefined
    const userName = item.user?.name || ''; // handle null or undefined
    const userEmail = item.user?.email || ''; // handle null or undefined
    const phone = item.phone || ''; // handle null or undefined
    
    return (
      countryName.toLowerCase().includes(filterText.toLowerCase()) ||
      cityName.toLowerCase().includes(filterText.toLowerCase()) ||
      userName.toLowerCase().includes(filterText.toLowerCase()) ||
      userEmail.toLowerCase().includes(filterText.toLowerCase()) ||
      phone.toLowerCase().includes(filterText.toLowerCase())
    );
  });
  

  const paginationOptions = {
    rowsPerPageText: 'Items per Page' , // Text for "Rows per page" label
  };

  
  return (
    <div>
      <div className="container-fluid pt-4 px-4">
        <div className="d-flex align-items-center justify-content-center mb-4">
          <div className="col-md-12">
            
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h4 className="">Users</h4>
              <Link to={"/admin/user/create"} className="btn btn-primary">Add New User</Link>
            </div>

            <div className="bg-light text-center rounded p-4">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="mb-0">All User</h5>
                <div className="col-4">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="form-control"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                  </div>
              </div>
                               
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                paginationComponentOptions={paginationOptions}
              ></DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReadState;

