import React from 'react';
import { Link } from 'react-router-dom';

function PageNotFound() {
        return (
            <div>
                <div className="p-5 d-flex justify-content-center align-items-center">
                    <div className='col-md-8'>
                    <div className="font-10rem fw-bold text-danger text-center">404</div>
                    <p className='mb-3 text-center'>
                    Uh-oh! It looks like you've stumbled upon a page that doesn't exist.
                    </p>
                    <div className='mb-4 text-left'>
                        <p><span className='fw-bold'>Check the URL</span>: Make sure you entered the web address correctly. Typos happen, and they can lead you to the wrong place.</p>

                        <p><span className='fw-bold'>Go to the homepage</span>: You can start fresh by visiting our homepage. From there, you can navigate to the content you were looking for.</p>

                        <p><span className='fw-bold'>Contact us</span>: If you believe there's a technical issue or if you need further assistance, feel free to reach out to our support team. We're here to help resolve any problems you may encounter.</p>
                    </div>
                    <div className='text-center'>
                        <p>While you're here, why not take a moment to explore some of our popular pages:</p>
                        <Link to={'/'} className='btn btn-primary me-2'>Home</Link>
                        <Link to={'/'} className='btn btn-primary'>Contact Us</Link>
                    </div>
                    </div>
                </div>
            </div>
        );
}

export default PageNotFound;