import React, { useState, useEffect } from "react";
import Select from "react-select";
import AuthUser from "../../AuthUser";
import { Hearts } from 'react-loader-spinner'

function StepThree({ handleChange, errors,setFormData,profileExists }) {
  const {http,getUser} = AuthUser();
  const currentUser = getUser();
  const userId = currentUser?.id
  const [id] = useState();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [filteredStates, setFilteredStates] = useState([]);
  const [filteredCity, setFilteredCity] = useState([]);

  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [educations, setEducations] = useState([]);
  const [selectedEducation, setSelectedEducation] = useState(null);
  const [salaryranges, setSalaryranges] = useState([]);
  const [selectedSalaryrange, setSelectedSalaryrange] = useState(null);
  const [maritalstatus, setMaritalstatus] = useState([]);
  const [selectedMaritalstatus, setSelectedMaritalstatus] = useState(null);

  const [occupation, setOccupation] = useState([]);
  const [selectedOccupation, setSelectedOccupation] = useState(null);

  const [profession, setProfession] = useState([]);
  const [selectedProfession, setSelectedProfession] = useState(null);
  const [filteredProfession, setFilteredProfession] = useState([]);

  const [religion, setReligion] = useState([]);
  const [selectedReligion, setSelectedReligion] = useState(null);

  const [caste, setCaste] = useState([]);
  const [selectedCaste, setSelectedCaste] = useState(null);
  const [filteredCaste, setFilteredCaste] = useState([]);

  const [gender, setGender] = useState([]);
  const [selectedGender, setSelectedGender] = useState(null);

  const [bodyType, setBodyType] = useState([]);
  const [selectedBodyType, setSelectedBodyType] = useState(null);

  const [complexion, setComplexion] = useState([]);
  const [selectedComplexion, setSelectedComplexion] = useState(null);

  const [weight, setWeight] = useState([]);
  const [selectedWeight, setSelectedWeight] = useState(null);

  const [height, setHeight] = useState([]);
  const [selectedHeight, setSelectedHeight] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const heights = [
    { value: "Below 137", label: "Below 4ft 6in - 137cm" },
    { value: "137", label: "4ft 6in - 137cm" },
    { value: "139", label: "4ft 7in - 139cm" },
    { value: "142", label: "4ft 8in - 142cm" },
    { value: "144", label: "4ft 9in - 144cm" },
    { value: "147", label: "4ft 10in - 147cm" },
    { value: "149", label: "4ft 11in - 149cm" },
    { value: "152", label: "5ft - 152cm" },
    { value: "154", label: "5ft 1in - 154cm" },
    { value: "157", label: "5ft 2in - 157cm" },
    { value: "160", label: "5ft 3in - 160cm" },
    { value: "162", label: "5ft 4in - 162cm" },
    { value: "165", label: "5ft 5in - 165cm" },
    { value: "167", label: "5ft 6in - 167cm" },
    { value: "170", label: "5ft 7in - 170cm" },
    { value: "172", label: "5ft 8in - 172cm" },
    { value: "175", label: "5ft 9in - 175cm" },
    { value: "177", label: "5ft 10in - 177cm" },
    { value: "180", label: "5ft 11in - 180cm" },
    { value: "182", label: "6ft - 182cm" },
    { value: "185", label: "6ft 1in - 185cm" },
    { value: "187", label: "6ft 2in - 187cm" },
    { value: "190", label: "6ft 3in - 190cm" },
    { value: "193", label: "6ft 4in - 193cm" },
    { value: "195", label: "6ft 5in - 195cm" },
    { value: "198", label: "6ft 6in - 198cm" },
    { value: "200", label: "6ft 7in - 200cm" },
    { value: "203", label: "6ft 8in - 203cm" },
    { value: "205", label: "6ft 9in - 205cm" },
    { value: "208", label: "6ft 10in - 208cm" },
    { value: "210", label: "6ft 11in - 210cm" },
    { value: "213", label: "7ft - 213cm" },
    { value: "Above 213", label: "Above 7ft - 213cm" }
  ];
  const weights = Array.from({ length: 101 }, (_, i) => ({
    value: (i + 40).toString(),
    label: `${i + 40} Kg`,
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const countryResponse = await http.get("/countries");
        setCountries(countryResponse.data);

        const stateResponse = await http.get(`/states`);
        setStates(stateResponse.data);

        const cityResponse = await http.get(`/cities`);
        setCities(cityResponse.data);

        const languageResponse = await http.get(`/language`);
        setLanguages(languageResponse.data);

        const educationResponse = await http.get(`/educations`);
        setEducations(educationResponse.data);

        const salaryrangeResponse = await http.get(`/salaryrange`);
        setSalaryranges(salaryrangeResponse.data);

        const maritalstatusResponse = await http.get(`/maritalstatus`);
        setMaritalstatus(maritalstatusResponse.data);

        const occupationResponse = await http.get(`/occupation`);
        setOccupation(occupationResponse.data);

        const professionResponse = await http.get(`/profession`);
        setProfession(professionResponse.data);

        const religionResponse = await http.get(`/religion`);
        setReligion(religionResponse.data);

        const casteResponse = await http.get(`/caste`);
        setCaste(casteResponse.data);

        const genderResponse = await http.get(`/gender`);
        setGender(genderResponse.data);

        const bodyTypeResponse = await http.get(`/bodyType`);
        setBodyType(bodyTypeResponse.data);

        const complexionResponse = await http.get(`/complexion`);
        setComplexion(complexionResponse.data);

        const weightResponse = weights;
        setWeight(weightResponse);

        const heightResponse = heights;
        setHeight(heightResponse);

        if (userId && profileExists) {
          const userResponse = await http.get(`/user/profile/${userId}`);
          const {
            country_id,
            state_id,
            city_id,
            language_id,
            education_id,
            salaryrange_id,
            maritalstatus_id,
            occupation_id,
            profession_id,
            religion_id,
            caste_id,
            gender_id,
            body_type_id,
            complexion_id,
            weight,
            height
          } = userResponse.data.data;

          // Populate the form data
          setFormData(prevFormData => ({
            ...prevFormData,
              country_id: country_id,
              state_id: state_id,
              city_id: city_id,
              language_id: language_id,
              education_id: education_id,
              salaryrange_id: salaryrange_id,
              maritalstatus_id: maritalstatus_id,
              occupation_id: occupation_id,
              profession_id: profession_id,
              religion_id: religion_id,
              caste_id: caste_id,
              gender_id: gender_id,
              body_type_id: body_type_id,
              complexion_id: complexion_id,
              weight: weight,
              height: height,
            }));
          const selectedCountryData = countryResponse.data.find(
            (country) => country.id === country_id
          );
          if (selectedCountryData) {
            setSelectedCountry({
              value: selectedCountryData.id,
              label: selectedCountryData.name,
            });
          }

          const selectedStateData = stateResponse.data.find(
            (state) => state.id === state_id
          );
          if (selectedStateData) {
            setSelectedState({
              value: selectedStateData.id,
              label: selectedStateData.name,
            });
          }

          const selectedCityData = cityResponse.data.find(
            (city) => city.id === city_id
          );
          if (selectedCityData) {
            setSelectedCity({
              value: selectedCityData.id,
              label: selectedCityData.name,
            });
          }

          const selectedLanguageData = languageResponse.data.find(
            (language) => language.id === language_id
          );
          if (selectedLanguageData) {
            setSelectedLanguage({
              value: selectedLanguageData.id,
              label: selectedLanguageData.title,
            });
          }

          const selectedEducationData = educationResponse.data.find(
            (education) => education.id === education_id
          );
          if (selectedEducationData) {
            setSelectedEducation({
              value: selectedEducationData.id,
              label: selectedEducationData.title,
            });
          }

          const selectedSalaryrangeData = salaryrangeResponse.data.find(
            (salaryrange) => salaryrange.id === salaryrange_id
          );
          if (selectedSalaryrangeData) {
            setSelectedSalaryrange({
              value: selectedSalaryrangeData.id,
              label: selectedSalaryrangeData.title,
            });
          }

          const selectedMaritalstatusData = maritalstatusResponse.data.find(
            (maritalstatus) => maritalstatus.id === maritalstatus_id
          );
          if (selectedMaritalstatusData) {
            setSelectedMaritalstatus({
              value: selectedMaritalstatusData.id,
              label: selectedMaritalstatusData.title,
            });
          }

          const selectedOccupationData = occupationResponse.data.find(
            (occupation) => occupation.id === occupation_id
          );
          if (selectedOccupationData) {
            setSelectedOccupation({
              value: selectedOccupationData.id,
              label: selectedOccupationData.title,
            });
          }

          const selectedProfessionData = professionResponse.data.find(
            (profession) => profession.id === profession_id
          );
          if (selectedProfessionData) {
            setSelectedProfession({
              value: selectedProfessionData.id,
              label: selectedProfessionData.title,
            });
          }

          const selectedReligionData = religionResponse.data.find(
            (religion) => religion.id === religion_id
          );
          if (selectedReligionData) {
            setSelectedReligion({
              value: selectedReligionData.id,
              label: selectedReligionData.title,
            });
          }

          const selectedCasteData = casteResponse.data.find(
            (caste) => caste.id === caste_id
          );
          if (selectedCasteData) {
            setSelectedCaste({
              value: selectedCasteData.id,
              label: selectedCasteData.title,
            });
          }

          const selectedGenderData = genderResponse.data.find(
            (gender) => gender.id === gender_id
          );
          if (selectedGenderData) {
            setSelectedGender({
              value: selectedGenderData.id,
              label: selectedGenderData.title,
            });
          }

          const selectedBodyTypeData = bodyTypeResponse.data.find(
            (bodyType) => bodyType.id === body_type_id
          );
          if (selectedBodyTypeData) {
            setSelectedBodyType({
              value: selectedBodyTypeData.id,
              label: selectedBodyTypeData.title,
            });
          }

          const selectedComplexionData = complexionResponse.data.find(
            (complexion) => complexion.id === complexion_id
          );
          if (selectedComplexionData) {
            setSelectedComplexion({
              value: selectedComplexionData.id,
              label: selectedComplexionData.title,
            });
          }
          setLoading(false);
          const selectedWeightData = weightResponse.find(
            (wt) => wt.value === (weight).toString()
          );
          
          if (selectedWeightData) {
            setSelectedWeight({
              value: selectedWeightData.value,
              label: selectedWeightData.label,
            });
          }

          const selectedHeightData = heightResponse.find(
            (ht) => ht.value === (height).toString()
          );
          if (selectedHeightData) {
            setSelectedHeight({
              value: selectedHeightData.value,
              label: selectedHeightData.label,
            });
          }
          console.log("loading false calleed");
          

        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const initialFilteredStates = states.filter(
      (state) => state.country_id === selectedCountry?.value
    );
    setFilteredStates(initialFilteredStates);

    fetchData();
  }, [id]);

  useEffect(() => {
    if (selectedCountry) {
      const filteredStates = states.filter(state => state.country_id === selectedCountry.value);
      setFilteredStates(filteredStates);
    }
  }, [selectedCountry, states]);

  useEffect(() => {
    if (selectedState) {
      const filteredCities = cities.filter(city => city.state_id === selectedState.value);
      setFilteredCity(filteredCities);
    }
  }, [selectedState, cities]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    handleChange({ target: { name: "country_id", value: selectedOption ? selectedOption.value : null } });
    // Reset selected state when country changes
     setSelectedState(null);
     setSelectedCity(null);
    // Filter states based on selected country
    const filteredStates = states.filter(
      (state) => state.country_id === selectedOption.value
    );
    setFilteredStates(filteredStates);
   // Clear filtered city options
  setFilteredCity([]);

  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    handleChange({ target: { name: "state_id", value: selectedOption ? selectedOption.value : null } });

    // Filter cities based on selected state
    const filteredCity = cities.filter(
      (city) => city.state_id === selectedOption.value
    );
    setFilteredCity(filteredCity);

    setSelectedCity(null);
  };

  const handleOccupationChange = (selectedOption) => {
    setSelectedOccupation(selectedOption);
    handleChange({ target: { name: "occupation_id", value: selectedOption ? selectedOption.value : null } });
    // Filter states based on selected country
    const filteredProfession = profession.filter(
      (profession) => profession.occupation_id === selectedOption.value
    );
    setFilteredProfession(filteredProfession);

    // Reset selected state when country changes
    setSelectedProfession(null);
  };

  const handleReligionChange = (selectedOption) => {
    setSelectedReligion(selectedOption);
    handleChange({ target: { name: "religion_id", value: selectedOption ? selectedOption.value : null } });
    // Filter states based on selected country
    const filteredCaste = caste.filter(
      (caste) => caste.religion_id === selectedOption.value
    );
    setFilteredCaste(filteredCaste);

    // Reset selected state when country changes
    setSelectedCaste(null);
  };


  return (
    <div>
      <form>
        <div className="mb-3">
          <h4>User Details</h4>
        </div>
        {loading ? (
        <div className="spinner-container">
          <Hearts
            height="80"
            width="80"
            color="red"
            ariaLabel="loading"
          />
        </div>
      ) :
      <div className="row mb-4">
          <div className="col-sm-4">
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                Country
              </label>
              <Select
                id="countryInput"
                options={countries.map((country) => ({
                  value: country.id,
                  label: country.name,
                }))}
                value={selectedCountry}
                onChange={(selectedOption) => {
                  handleCountryChange(selectedOption);
                }}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                State
              </label>
              <Select
                id="StateInput"
                options={filteredStates.map((state) => ({
                  value: state.id,
                  label: state.name,
                }))} // Use filteredStates
                value={selectedState}
                // onChange={handleStateChange} // Keep it as it is if you don't need any additional logic
                onChange={(selectedOption) => {
                  handleStateChange(selectedOption);
                  handleChange({
                    target: {
                      name: "state_id",
                      value: selectedOption ? selectedOption.value : null,
                    },
                  });
                }}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                City
              </label>
              <Select
                id="CityInput"
                options={filteredCity.map((city) => ({
                  value: city.id,
                  label: city.name,
                }))}
                value={selectedCity}
                onChange={(selectedOption) => {
                  setSelectedCity(selectedOption);
                  handleChange({
                    target: {
                      name: "city_id",
                      value: selectedOption ? selectedOption.value : null,
                    },
                  });
                }}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-6 mb-4">
            <div>
              <label htmlFor="">Mother Toung</label>
              <Select
                options={languages.map((language) => ({
                  value: language.id,
                  label: language.title,
                }))}
                value={selectedLanguage}
                onChange={(selectedOption) => {
                  setSelectedLanguage(selectedOption);
                  handleChange({
                    target: {
                      name: "language_id",
                      value: selectedOption ? selectedOption.value : null,
                    },
                  });
                }}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div>
              <label htmlFor="">Education</label>

              <Select
                options={educations.map((education) => ({
                  value: education.id,
                  label: education.title,
                }))}
                value={selectedEducation}
                onChange={(selectedOption) => {
                  setSelectedEducation(selectedOption);
                  handleChange({
                    target: {
                      name: "education_id",
                      value: selectedOption ? selectedOption.value : null,
                    },
                  });
                }}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-6 mb-4">
            <div>
              <label htmlFor="">Salary Range</label>
              <Select
                options={salaryranges.map((salaryrange) => ({
                  value: salaryrange.id,
                  label: salaryrange.title,
                }))}
                value={selectedSalaryrange}
                onChange={(selectedOption) => {
                  setSelectedSalaryrange(selectedOption);
                  handleChange({
                    target: {
                      name: "salaryrange_id",
                      value: selectedOption ? selectedOption.value : null,
                    },
                  });
                }}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div>
              <label htmlFor="">Maretial Status</label>
              <Select
                options={maritalstatus.map((maritalstatus) => ({
                  value: maritalstatus.id,
                  label: maritalstatus.title,
                }))}
                value={selectedMaritalstatus}
                onChange={(selectedOption) => {
                  setSelectedMaritalstatus(selectedOption);
                  handleChange({
                    target: {
                      name: "maritalstatus_id",
                      value: selectedOption ? selectedOption.value : null,
                    },
                  });
                }}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-6 mb-4">
            <div>
              <label htmlFor="">Occupation</label>
              <Select
                options={occupation.map((occupation) => ({
                  value: occupation.id,
                  label: occupation.title,
                }))}
                value={selectedOccupation}
                onChange={(selectedOption) => {
                  handleOccupationChange(selectedOption);
                  handleChange({
                    target: {
                      name: "occupation_id",
                      value: selectedOption ? selectedOption.value : null,
                    },
                  });
                }}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div>
              <label htmlFor="">Profession</label>
              <Select
                options={filteredProfession.map((profession) => ({
                  value: profession.id,
                  label: profession.title,
                }))}
                value={selectedProfession}
                onChange={(selectedOption) => {
                  setSelectedProfession(selectedOption);
                  handleChange({
                    target: {
                      name: "profession_id",
                      value: selectedOption ? selectedOption.value : null,
                    },
                  });
                }}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-4 mb-4">
            <div>
              <label htmlFor="">Religion</label>
              <Select
                options={religion.map((religion) => ({
                  value: religion.id,
                  label: religion.title,
                }))}
                value={selectedReligion}
                onChange={(selectedOption) => {
                  handleReligionChange(selectedOption);
                  handleChange({
                    target: {
                      name: "religion_id",
                      value: selectedOption ? selectedOption.value : null,
                    },
                  });
                }}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-4 mb-4">
            <div>
              <label htmlFor="">Caste</label>
              <Select
                options={filteredCaste.map((caste) => ({
                  value: caste.id,
                  label: caste.title,
                }))}
                value={selectedCaste}
                onChange={(selectedOption) => {
                  setSelectedCaste(selectedOption);
                  handleChange({
                    target: {
                      name: "caste_id",
                      value: selectedOption ? selectedOption.value : null,
                    },
                  });
                }}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-4 mb-4">
            <div>
              <label htmlFor="">Gender</label>
              <Select
                options={gender.map((gender) => ({
                  value: gender.id,
                  label: gender.title,
                }))}
                value={selectedGender}
                onChange={(selectedOption) => {
                  setSelectedGender(selectedOption);
                  handleChange({
                    target: {
                      name: "gender_id",
                      value: selectedOption ? selectedOption.value : null,
                    },
                  });
                }}
                isSearchable={true}
              />
               {errors.gender_id && (
                <div className="text-danger">{errors.gender_id}</div>
              )}
            </div>
          </div>
          <div className="col-sm-6 mb-4">
            <div>
              <label htmlFor="">Body Type</label>
              <Select
                options={bodyType.map((bodyType) => ({
                  value: bodyType.id,
                  label: bodyType.title,
                }))}
                value={selectedBodyType}
                onChange={(selectedOption) => {
                  setSelectedBodyType(selectedOption);
                  handleChange({
                    target: {
                      name: "body_type_id",
                      value: selectedOption ? selectedOption.value : null,
                    },
                  });
                }}
                isSearchable={true}
              />
               {errors.body_type_id && (
                <div className="text-danger">{errors.body_type_id}</div>
              )}
            </div>
          </div>

          <div className="col-sm-6 mb-4">
            <div>
              <label htmlFor="">Complexion</label>
              <Select
                options={complexion.map((complexion) => ({
                  value: complexion.id,
                  label: complexion.title,
                }))}
                value={selectedComplexion}
                onChange={(selectedOption) => {
                  setSelectedComplexion(selectedOption);
                  handleChange({
                    target: {
                      name: "complexion_id",
                      value: selectedOption ? selectedOption.value : null,
                    },
                  });
                }}
                isSearchable={true}
              />
            </div>
          </div>

          <div className="col-sm-6 mb-4">
            <div>
              <label htmlFor="">Weight</label>
              <Select
                options={weight.map((weight) => ({
                  value: weight.value,
                  label: weight.label,
                }))}
                value={selectedWeight}
                onChange={(selectedOption) => {
                  setSelectedWeight(selectedOption);
                  handleChange({
                    target: {
                      name: "weight",
                      value: selectedOption ? selectedOption.value : null,
                    },
                  });
                }}
                isSearchable={true}
              />
            </div>
          </div>

          <div className="col-sm-6 mb-4">
            <div>
              <label htmlFor="">Height</label>
              <Select
                options={height.map((height) => ({
                  value: height.value,
                  label: height.label,
                }))}
                value={selectedHeight}
                onChange={(selectedOption) => {
                  setSelectedHeight(selectedOption);
                  handleChange({
                    target: {
                      name: "height",
                      value: selectedOption ? selectedOption.value : null,
                    },
                  });
                }}
                isSearchable={true}
              />
            </div>
          </div>
        </div>
      }
        
      </form>
    </div>
  );
}

export default StepThree;
