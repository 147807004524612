
import React, { useState,useRef ,useEffect } from "react";
import { NavLink } from "react-router-dom";
import '../../assets/css/webCss.css';
// import { useAuth } from "./Auth"; // Import the useAuth hook
import AuthUser from "../AuthUser";

function WebNav() {
  const { logout,getToken,getUser } = AuthUser(); // Get the authentication status
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isAuthenticated = !!getToken();
  const currentUser = getUser();
  const userId = currentUser?.id
  const userName = currentUser?.name
  const dropdownRef = useRef(null);
  const handleLogout = () => {
    logout(); // Call the logout function
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  }
  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <div>
     <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-0 py-3 fixed-top">
      <div className="container-xl">
        <NavLink className="navbar-brand" to={''}>
          LiveInGo
        </NavLink>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav my-auto ml-3">
            <NavLink className="nav-item nav-link btn btn-sm btn-danger" to={'/'} aria-current="page">Home</NavLink>
            <NavLink className="nav-item nav-link btn btn-sm btn-danger" to={'/faq'}>FAQs</NavLink>
            {/* {isAuthenticated && (
              <>
                <NavLink className="nav-item nav-link " to={'/user/prime-membership'}>PrimeLIGo</NavLink>
                <NavLink className="nav-item nav-link" to={'/user/message'}>Message</NavLink>
                <NavLink className="nav-item nav-link" to={'/user/profile-performance'}>Profile Performance</NavLink>
                <NavLink className="nav-item nav-link" to={'/user/favourite-partners'}>My Favourites</NavLink>
                <NavLink className="nav-item nav-link" to={'/user/my-profile'}>My Profile</NavLink>
                <NavLink className="nav-item nav-link" to={'/user/profile'}>Profile</NavLink>
                <NavLink className="nav-item nav-link" to={'/user/privacy-setting'}>Privacy Setting</NavLink>
              </>
            )} */}
          </div>
          <div className="navbar-nav ms-auto">
            {isAuthenticated ? (
              <div className={`nav-item dropdown ${dropdownOpen ? 'show' : ''}`} ref={dropdownRef}>
                <button className="nav-link dropdown-toggle btn btn-sm btn-danger" onClick={toggleDropdown}>
                  {userName}
                </button>
                <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
                  <NavLink className="dropdown-item" to={'/user/profile-details/'+`${userId}`}>Profile</NavLink>
                  <NavLink className="dropdown-item" to={'/user/profile-performance'}>Profile Performance</NavLink>
                  <NavLink className="dropdown-item" to={'/user/privacy-setting'}>Privacy Setting</NavLink>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item" onClick={handleLogout}>Sign Out</button>
                </div>
              </div>
            ) : (
              <>
                <NavLink className="nav-item nav-link btn btn-sm btn-danger" to={'/user/login'}>Sign in</NavLink>
                <NavLink className="nav-item nav-link btn btn-sm btn-danger" to={'/user/registration'}>Register</NavLink>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
    </div>
  );
}

export default WebNav;
