import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import FacebookLogin from 'react-facebook-login';
import bg1 from './../../assets/img/bg1.jpg';
// import { GoogleLogin } from '@react-oauth/google';
import AuthUser from "../AuthUser";
import { toast } from 'react-toastify';

function UserLogin() {
  const {http, setToken,setUser} = AuthUser();
  const [email, setEmail] = useState('');
  const [password, SetPassword] = useState('');
    const navigate =  useNavigate();
    const onSignUpClick= () =>{
        navigate('/registration');
    }


  function handelSubmit(event) {
    event.preventDefault();
  
    if (validate()) {
      http.post('/login', { email: email, password: password })
        .then((response) => {
          if (response.data.status) {
            // Login successful            
            setToken(response.data.token); // Set token in local storage or state
            setUser(response.data.user); // Set token in local storage or state           
          } else {
            // Login failed
            console.error(response.data.message); // Log error message
            toast.error(response.data.message); // Display error message to user
          }
        })
        .catch((error) => {
          // Handle network or server errors
          console.error(error);
          toast.error('An error occurred. Please try again later.');
        });
    }
  }
  
  

  const validate = ()=>{
    let result=true;

    if(email === '' || email === null){
      result = false
      toast.warning('please enter Username')
    }
    if(password === '' || password === null){
      result = false
      toast.warning('please enter Password')
    }

    return result
  }

  return (
    <div>
      <div className="container-fluid pt-4 login-bg-color">
        <div className="row p-5">
          <div className="offset-sm-1 col-sm-5 ">
            <img src={bg1} alt="" className='img-fluid'/>
          </div>
          <div className="offset-sm-1 col-sm-5">
            <div className="p-3">
              <div className="card p-4 bg-light shadow">
                <h4 className="text-center mb-4">Login</h4>
                <form onSubmit={handelSubmit}>
                  <div className="form-group mb-4">
                    <div className="input-group flex-nowrap">
                      <span className="input-group-text">
                        <i className="fas fa-at"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Email ID"
                        aria-label="Username"
                        aria-describedby="addon-wrapping"
                        value={email}
                        onChange={(element)=>setEmail(element.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <div className="input-group flex-nowrap">
                      <span className="input-group-text">
                        <i className="fas fa-lock"></i>
                      </span>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter Password"
                        aria-label="Password"
                        aria-describedby="addon-wrapping"
                        value={password}
                        onChange={(element)=> SetPassword(element.target.value)}
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-center mb-3">
                    <button className="btn btn-danger w-100">Sign In</button>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button className="btn btn-outline-danger w-100" onClick={()=> onSignUpClick()}>
                      Sing Up Now
                    </button>
                  </div>
                </form>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserLogin;
