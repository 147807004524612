import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for react-toastify
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import { AuthProvider } from './components/AuthContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>     
    <BrowserRouter basename="/">
    <AuthProvider>
      <App />
      </AuthProvider>
      <ToastContainer/>
    </BrowserRouter>    
  </React.StrictMode>
);

reportWebVitals();
