import AuthUser from "../../../AuthUser";
import React, { useState , useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify"; // Import toast from react-toastify

function EducationsForm() {
  // you can adjust the component's behavior based on the presence of a id prop. If the id prop is present, it means the component is in edit mode, and you fetch the data for the specific country. Otherwise, it's in add mode.
  const { http } = AuthUser();
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
          if (id) {
              // Fetch country data if in edit mode
              const response = await http.get(`/educations/${id}`);
              const { title, status } = response.data;
              setTitle(title);
              setStatus(status);
          }
      } catch (error) {
          console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (title.trim() === "") {
        toast.error("Please enter a country");
        return;
    }

    setLoading(true);
      try {
          if (id) {
              // If in edit mode, update existing Country
              await http.put(`/educations/${id}`, {
                title: title,
                  status: status
              });
              toast.success("Country updated successfully");
              navigate("/admin/master/education/read");
          } else {
              // If in add mode, create new Country
              await http.post('/educations', {
                  title: title,
                  status: status
              });
              toast.success("Country added successfully");
              navigate("/admin/master/education/read");
          }
      } catch (error) {
          console.error("Failed to save Education:", error);
          toast.error("Failed to save Education");
      } finally {
          setLoading(false);
      }
  };

  const handleCheckboxChange = async () => {
    setStatus((prevStatus) => prevStatus === 1 ? 0 : 1); // Toggle status between 0 and 1
  };

  return (
    <div>
      <div className="container-fluid p-4">
        <div className="d-flex align-items-center justify-content-center mb-4">
          <div className="col-md-6 bg-light p-3">
            <div className="mb-4">
              <h4>{id ? 'Edit Education' : 'Add New Education'}</h4>
            </div>
            <hr />
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-3">
                <label htmlFor="cityInput">Education</label>
                <input
                  id="cityInput"
                  type="text"
                  className="form-control"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
              </div>
              <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        checked={status === 1}
                        onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                        Status
                    </label>
              </div>
              <button type="submit" className="btn btn-success mb-2">
                {loading ? 'Submitting...' : id ? 'Update' : 'Submit'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EducationsForm;