import React from "react";

function RefundCancellationPolicy() {
  return (
    <div>
      <div className="pt-4">
        <div className="card">
          <div className="card-header">
            <h3 className="fw-bold">
            Refund and Cancellation Policy
            </h3>
          </div>
          <div className="card-body">
            <div className="mb-3">
            Liveingo is the product of Sterling Technologies. By using the service you are deemed to have accepted Refund & Cancellation for your purchase on Liveingo.com website.<br/>
            Liveingo is an online platform where Live in relationship interested people can contact each other, using which users can get information and contact data or personal details from his/her wished partner on the basis of information made available by his/her wished partner.
            </div>
            <div className="mb-3">
            Note: Refund and cancellation policy is part of our terms & conditions.
            </div>
            
            <div className="mb-3">
            <h3 className="fw-bold">Membership cancellation</h3>
            <p>You may terminate Your membership at any time, for any reason, effective upon receipt of Your written notice of termination by Liveingo.com customer support staff. Your membership will be canceled immediately, however any fees charged for previous membership, active and used subscription will not be refunded, notwithstanding the provisions of the refund as set out in this Refund and Cancellation Policy.Kindly note, 'change of mind' will not applicable for refund.</p>
            </div>

            <div className="mb-3">
            <h3 className="fw-bold">How to Request Refund or Membership Cancellation</h3>
            <p>For enforcement of the monetary refund or membership cancellation please contact info@Liveingo.com on the website. Once your request is approved, you will receive a refund to your designated account, as set forth above. We will review and get back to you with an initial reply to your request within 48 hours.</p>
            </div>

            <div className="mb-3">
            <h3 className="fw-bold">Declaration</h3>
            <p>Please contact our support if you demand a refund for the above reasons. Once your case is qualified, you will be able to receive the refund. Any Chargeback of credit card payment without previous contact with our site would be considered as dishonest or deceitful. We reserve the right for taking appropriate legal actions at our sole discretion.</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default RefundCancellationPolicy;
