import React from "react";
import { Link } from 'react-router-dom';
import AuthUser from '../AuthUser';

function MainHeader () {
    const{token, logout} = AuthUser();

    const logoutUser = () => {
        if(token !== undefined){
            logout();
        }
    }


        return (
            <div>
                 
            <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
            <Link className="navbar-brand ps-3" to={'/'}>LiveInGo</Link>
            <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" to="#!"><i className="fas fa-bars"></i></button>
            
            <ul className="navbar-nav ms-auto me-3 me-lg-8">
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" id="navbarDropdown" to={''} role="button" data-bs-toggle="dropdown" aria-expanded="false"><span className="text-white"></span><i className="fas fa-user fa-fw"></i></Link>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        {/* <li><Link to="{'/'}" className="dropdown-item">My Profile</Link></li>
                        <li><Link to="{'/'}" className="dropdown-item">Settings</Link></li>
                        <li><hr className="dropdown-divider" /></li> */}
                        <li><button  className="dropdown-item" onClick={logoutUser}>Log Out</button></li>
                    </ul>
                </li>
            </ul>
        </nav>
            </div>
        );
    }

export default MainHeader;