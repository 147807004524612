import React from 'react';

function ContactUs() {
        return (
            <div>
                <div className="container pt-5">
                    <div className="d-flex justify-content-center">
                        <div className="col-md-8 text-center font-18">We're thrilled to hear from you! Whether you have a question about our products, need assistance with an order, or just want to say hello, we're here and ready to help.
                        </div>
                    </div>
        <div className="mx-auto w-50">
          <div className="p-3">           
            <div className="card p-4 bg-light">
              <h4 className="text-center mb-4">Contact Us</h4>
              <form action="">
                  <div className="mb-4">
                    <div className="form-group">
                      <div className="input-group flex-nowrap">
                        <span className="input-group-text">
                          <i className="fas fa-user"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Full Name"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="form-group">
                      <div className="input-group flex-nowrap">
                        <span className="input-group-text">
                          <i className="fas fa-at"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email ID"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="form-group">
                      <div className="input-group flex-nowrap">
                        <span className="input-group-text">
                        <i className="fas fa-phone-alt"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Phone No."
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="form-group">
                      <div className="input-group flex-nowrap">
                        <span className="input-group-text">
                        <i className="far fa-edit"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Subject"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="form-group">
                      <div className="input-group flex-nowrap">
                        <span className="input-group-text">
                        <i className="fas fa-envelope"></i>
                        </span>
                       <textarea name="" id="" cols="100" rows="2" placeholder='Message' className='form-control'></textarea>
                      </div>
                    </div>
                  </div>
                <div className="d-flex justify-content-center">
                    <button className="btn btn-danger w-50">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
            </div>
        );
}

export default ContactUs;