import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import AuthUser from "../../../AuthUser";
import { useNavigate, useParams } from "react-router-dom";

function CityForm() {
  const { http } = AuthUser();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [name, setName] = useState("");
  const [status, setStatus] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [filteredStates, setFilteredStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseCountries = await http.get("/countries");
        const responseStates = await http.get("/states");

        setCountries(responseCountries.data);
        setStates(responseStates.data);

        if (id) {
          const stateResponse = await http.get(`/cities/${id}`);
          // console.log(stateResponse.data);
          const { name, state_id, country_id, status } = stateResponse.data;
          setName(name);
          setStatus(status);

          const selectedCountryData = responseCountries.data.find(
            (country) => country.id === country_id
          );
          if (selectedCountryData) {
            setSelectedCountry({
              value: selectedCountryData.id,
              label: selectedCountryData.name,
            });
          }

          const selectedStateData = responseStates.data.find(
            (state) => state.id === state_id
          );
          if (selectedStateData) {
            setSelectedState({
              value: selectedStateData.id,
              label: selectedStateData.name,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);

    // Filter states based on selected country
    const filteredStates = states.filter(
      (state) => state.country_id === selectedOption.value
    );
    setFilteredStates(filteredStates);

    // Reset selected state when country changes
    setSelectedState(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCountry || !selectedState || !name.trim()) {
      toast.error("Please fill all fields");
      return;
    }

    setLoading(true);
    try {
      if (id) {
        // If in edit mode, update existing city
        await http.put(`/cities/${id}`, {
          name: name,
          state_id: selectedState.value,
          country_id: selectedCountry.value,          
          status: status,
        });
        toast.success("State updated successfully");
        navigate("/admin/master/city/read");
      } else {
        // If in add mode, create new city
        await http.post("/cities", {
          name: name,
          state_id: selectedState.value,
          country_id: selectedCountry.value,          
          status: status,
        });
        toast.success("State added successfully");
        navigate("/admin/master/city/read");
      }
    } catch (error) {
      console.error("Failed to save state:", error);
      toast.error("Failed to save state");
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = async () => {
    setStatus((prevStatus) => (prevStatus === 1 ? 0 : 1)); // Toggle status between 0 and 1
  };

  return (
    <div>
      <div className="container-fluid p-4">
        <div className="d-flex align-items-center justify-content-center mb-4">
          <div className="col-md-6 bg-light p-3">
            <div className="mb-4">
              <h4>{id ? "Edit CIty" : "Add New City"}</h4>
            </div>
            <hr />
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-3">
                <label htmlFor="countryInput">Country</label>
                <Select
                  id="countryInput"
                  options={countries.map((country) => ({
                    value: country.id,
                    label: country.name,
                  }))}
                  value={selectedCountry}
                  onChange={handleCountryChange} // Change to handleCountryChange
                  isSearchable={true}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="StateInput">State</label>
                <Select
                  id="StateInput"
                  options={filteredStates.map((state) => ({
                    value: state.id,
                    label: state.name,
                  }))} // Use filteredStates
                  value={selectedState}
                  onChange={setSelectedState} // Keep it as it is if you don't need any additional logic
                  isSearchable={true}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="stateInput">City</label>
                <input
                  type="text"
                  className="form-control"
                  id="stateInput"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={status === 1}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexSwitchCheckDefault"
                >
                  Status
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-success mb-2"
                disabled={loading}
              >
                {loading ? "Submitting..." : id ? "Update" : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CityForm;
