import React, { Component } from 'react';

class Dashboard extends Component {
    render() {
        return (
            <div>

            
            <div className="container-fluid pt-4 px-4">
                <div className="row g-4">
                    <div className="col-sm-6 col-xl-3">
                        <div className="bg-primary text-white rounded d-flex align-items-center justify-content-between p-4">
                            {/* <i className="fa fa-chart-line fa-3x text-primary"></i> */}
                            <div className="ms-3">
                                <p className="mb-2">Total Members</p>
                                <h6 className="mb-0">20</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="bg-success text-white rounded d-flex align-items-center justify-content-between p-4">
                            {/* <i className="fa fa-chart-bar fa-3x text-primary"></i> */}
                            <div className="ms-3">
                                <p className="mb-2">Premium Members</p>
                                <h6 className="mb-0">10</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="bg-warning text-white rounded d-flex align-items-center justify-content-between p-4">
                            {/* <i className="fa fa-chart-area fa-3x text-primary"></i> */}
                            <div className="ms-3">
                                <p className="mb-2">Free Members</p>
                                <h6 className="mb-0">4</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-3">
                        <div className="bg-danger text-white rounded d-flex align-items-center justify-content-between p-4">
                            {/* <i className="fa fa-chart-pie fa-3x text-primary"></i> */}
                            <div className="ms-3">
                                <p className="mb-2">Blocked Members</p>
                                <h6 className="mb-0">2</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
           
            
            <div className="container-fluid pt-4 px-4">
                <div className="bg-light text-center rounded p-4">
                    <div className="d-flex align-items-center justify-content-between mb-4">
                        <h6 className="mb-0">All members</h6>
                    </div>
                    <div className="table-responsive">
                        <table className="table text-start align-middle table-bordered table-hover mb-0">
                            <thead>
                                <tr className="text-dark"> 
                                    <th scope="col">Image</th>
                                    <th scope="col">Member Code</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Gender</th>
                                    <th scope="col">Verification Status</th>
                                    <th scope="col">Profile Reported</th>
                                    <th scope="col">Member Science</th>
                                    <th scope="col">Member Status</th>
                                    <th scope="col">Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Image</td>
                                    <td>BA5D7EEC</td>
                                    <td>Alex Reynolds</td>
                                    <td>Male</td>
                                    <td>Approved</td>
                                    <td>0</td>
                                    <td>12-04-2021</td>
                                    <td>Active</td>
                                    <td>edit, delete</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
           
            
           
                
            </div>
        );
    }
}

export default Dashboard;