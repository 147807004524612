// import React from "react";

// function StepTwo({
//   formData,
//   handleChange,
//   errors,
//   imagePreviewUrl,
//   setImagePreviewUrl,
//   setProfileImagePath,
// }) {
//   // const handleImageChange = (e) => {
//   //   e.preventDefault();

//   //   const reader = new FileReader();
//   //   const file = e.target.files[0];

//   //   reader.onloadend = () => {
//   //     setImagePreviewUrl(reader.result);
//   //     setProfileImagePath(reader.result); // Update profile image path
//   //   };

//   //   if (file) {
//   //     reader.readAsDataURL(file);
//   //   }
//   // };

//   return (
//     <div>
//       <div className="mb-3">
//         <h2>Profile Image</h2>
//       </div>
//       <form action="/user-profile" method="POST" enctype="multipart/form-data">
//         {/* <div className="avatar-upload me-3">
//           <div className="avatar-edit">
//             <input
//               type="file"
//               id="imageUpload"
//               accept=".png, .jpg, .jpeg"
//               onChange={handleImageChange}
//               value={formData.profile_img}
//               name="profile_img"
//             />
//             <label htmlFor="imageUpload" className="bg-danger">
//               <i className="fas fa-camera text-white"></i>
//             </label>
//           </div>
//           <div className="avatar-preview">
//             <div
//               id="imagePreview"
//               style={{ backgroundImage: `url(${imagePreviewUrl})` }}
//             ></div>
//           </div>
//         </div> */}

//         <input
//           type="file"
//           name="profile_img"
//           id="profile_img"
//           className="form-control border w-100"
//           value={formData.profile_img}
//           onChange={handleChange}
//         />

//         {errors.imagePreview && (
//           <div className="text-danger mb-3">{errors.imagePreview}</div>
//         )}
//         {/* Additional form fields */}
//       </form>
//     </div>
//   );
// }

// export default StepTwo;


import React from "react";

function StepTwo({
  handleChange,
  errors
}) {
  return (
    <div className="mb-3">
      <div className="mb-3">
        <h2>Profile Image</h2>
      </div>
      <form method="POST" encType="multipart/form-data">
        <input
          type="file"
          name="profile_img"
          id="profile_img"
          className="form-control border w-100"
          onChange={handleChange}
        />
      </form>
    </div>
  );
}

export default StepTwo;
