import React from 'react';

function PrivacySetting() {
        return (
            <div>
                 <div className="container">
        <div className="mx-auto w-75">
          <div className="p-3">
            <div className="card p-4 bg-light shadow">
              <h4 className="text-center mb-4">Privacy Setting</h4>
              <form action="">
               <div className="form-group">
                    <div>Who can see your Whatsapp No?</div>
                    <div className="form-check mb-4 d-block d-md-flex">
                        <div className='w-25'>
                        <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    Male
                  </label>
                        </div>
                        <div className='w-25'>
                        <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    Female
                  </label>
                        </div>
                        <div className='w-25'>
                        <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    Other
                  </label>
                        </div>
                    </div>
                </div> 

                 <div className="form-group">
                    <div>Who can see your Phone No?</div>
                    <div className="form-check mb-4 d-block d-md-flex">
                        <div className='w-25'>
                        <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    Male
                  </label>
                        </div>
                        <div className='w-25'>
                        <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    Female
                  </label>
                        </div>
                        <div className='w-25'>
                        <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    Other
                  </label>
                        </div>
                    </div>
                </div>  

                 <div className="form-group">
                    <div>Who can send you Message?</div>
                    <div className="form-check mb-4 d-block d-md-flex">
                        <div className='w-25'>
                        <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    Male
                  </label>
                        </div>
                        <div className='w-25'>
                        <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    Female
                  </label>
                        </div>
                        <div className='w-25'>
                        <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    Other
                  </label>
                        </div>
                    </div>
                </div> 

                <div className="form-group">
                    <div>Who can email you?</div>
                    <div className="form-check mb-4 d-block d-md-flex">
                        <div className='w-25'>
                        <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    Male
                  </label>
                        </div>
                        <div className='w-25'>
                        <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    Female
                  </label>
                        </div>
                        <div className='w-25'>
                        <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    Other
                  </label>
                        </div>
                    </div>
                </div>               
                <div className="d-flex justify-content-left">
                    <button className="btn btn-danger px-5">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
            </div>
        );
}

export default PrivacySetting;