import React from "react";

function PrivacyPolicy() {
  return (
    <div>
      <div className="pt-4">
        <div className="card">
          <div className="card-header">
            <h3 className="fw-bold">
              Privacy Policy for LiveInGo
            </h3>
          </div>
          <div className="card-body">
            <div className="mb-4">
              At LiveInGo, we take your privacy seriously. This Privacy Policy
              describes how we collect, use, and share your personal information
              when you use our website (https://www.liveingo.com) or any related
              services.
            </div>
            <div className="mb-4">
              <h4 className="fw-bold mb-2"> 1. Information We Collect</h4>
              <p>
                <span className="fw-bold">Personal Information</span>: When you
                visit our website, register an account, or use our services, we
                may collect personal information such as your name, email
                address, postal address, phone number, and payment information.
              </p>

              <p>
                <span className="fw-bold">Usage Data</span>:We may also collect
                information about how you interact with our website and
                services, including IP address, browser type, pages visited, and
                timestamps.
              </p>

              <p>
                <span className="fw-bold">Cookies</span>: Like many websites, we
                use cookies and similar tracking technologies to enhance your
                browsing experience and analyze usage patterns.
              </p>
            </div>

            <div className="mb-4">
              <h4 className="fw-bold mb-2">2. How We Use Your Information</h4>
              <p>
              We may use the information we collect for various purposes, including:
              </p>
              <ul>
                <li>Providing and improving our services</li>
                <li>Personalizing your experience</li>
                <li>Communicating with you</li>
                <li>Analyzing usage trends and optimizing our website</li>
                <li>Preventing fraudulent activities and ensuring security</li>
                <li>Complying with legal obligations</li>
              </ul>
            </div>

            <div className="mb-4">
              <h4 className="fw-bold mb-2">3. Information Sharing and Disclosure</h4>
              <p>
              We do not sell, trade, or rent your personal information to third parties for marketing purposes. However, we may share your information with:
              </p>
              <ul>
                <li>Service providers and business partners who assist us in delivering our services</li>
                <li>Law enforcement agencies, regulatory authorities, or other third parties when required by law or to protect our rights</li>
                <li>Affiliates or successors in the event of a merger, acquisition, or similar corporate transaction</li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
