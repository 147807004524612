import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
function AuthUser () {
    const baseURL = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate();

    const getToken = ()=>{
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }

    const getUser = ()=>{
        const user = sessionStorage.getItem('user');
        const currentUser = JSON.parse(user);
        return currentUser;
    }

   const[token, setToken] = useState(getToken());
   const[user, setUser] = useState(getUser());

   const saveToken = (token) => {

        sessionStorage.setItem('token', JSON.stringify(token));

        setToken(token);
   }

   const saveUser = (user) => {
        sessionStorage.setItem('user', JSON.stringify(user));
        navigate(user?.role_name === 'admin' ? '/admin/dashboard' :  `/user/profile-details/${user.id}`);
    }

   const logout = () =>{
    
        sessionStorage.clear();
        navigate(user?.role_name === 'admin' ? '/admin/login' :  '/user/login');
   }

    const http = axios.create({
        baseURL: baseURL,
        headers:{
            "Content-Type" : "application/json",
            "Authorization": `Bearer ${token}`
        }
    })

        return {
            setToken:saveToken,
            setUser:saveUser,
            token,
            user,
            getToken,
            getUser,
            http,
            logout
        }
}

export default AuthUser;