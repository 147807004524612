import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import AuthUser from "../AuthUser";
import bg1 from './../../assets/img/bg1.jpg';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Hearts } from 'react-loader-spinner'

function Registration() {
  const {http} = AuthUser();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');  
  const [gender, setGender] = useState([]);
  const [selectedGender, setSelectedGender] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirmation, setPassword_confirmation] = useState('');
  const [loading, setLoading] = useState(false);

  const [acceptTerms, setAcceptTerms] = useState(false);

  console.log(gender);
  console.log('selectedGender',selectedGender);

  useEffect(() => {
    fetchGenders()
  }, []);

  const fetchGenders = async () => {
    const genderResponse = await http.get(`/all-gender`);
    setGender(genderResponse.data);
  }

  function handleSubmit(e)  {
    e.preventDefault();
    setLoading(true);
     if(validate()){
      http.post('/register', {name:name, email:email, password:password, password_confirmation:password_confirmation,gender_id:selectedGender})
      .then((res)=>{
        console.log(res);
        setLoading(false);
        toast.success('Register successful.');
        navigate("/user/after-registration");
      }).catch((error)=>{
        setLoading(false);
        // toast.error('Register Fail', err)
        if (error.response && error.response.status === 409) {
          toast.error('Email already exists. Please use a different email.');
        } else {
          console.error('Registration error:', error);
          toast.error('Register Fail. Please try again later.');
        }
      })
    }
    else{
      setLoading(false);
    }

  };

  const validate = ()=>{
    let result=true;
    
    if(name === '' || name === null){
      result = false
      toast.warning('please enter Username')
    }
    if(email === '' || email === null){
      result = false
      toast.warning('please enter Email')
    }
    if(password === '' || password === null){
      result = false
      toast.warning('please enter Valid Password')
    }
    if(selectedGender === '' || selectedGender === null){
      result = false
      toast.warning('please select Gender')
    }
    if(password !== password_confirmation){
      result = false
      toast.warning('Passwords do not match')
    }
    if (!acceptTerms) {
      result = false;
      toast.warning('You must accept the Terms and Conditions');
    }

    return result
  }

  return (
    <div>
        {loading && (
        <div className="spinner-container">
          <Hearts
            height="80"
            width="80"
            color="red"
            ariaLabel="loading"
          />
        </div>
      )}
      <div className="container-fluid pt-4 login-bg-color">
        <div className="row p-5">
          <div className="offset-sm-1 col-sm-5 ">
            <img src={bg1} alt="" className='img-fluid'/>
          </div>
          <div className="offset-sm-1 col-sm-5">
            <div className="p-3">
              <div className="card p-4 bg-light shadow">
                <h4 className="text-center mb-4">Create your profile</h4>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="mb-4">
                      <div className="form-group">
                        <div className="input-group flex-nowrap">
                          <span className="input-group-text">
                            <i className="fas fa-user"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Full Name"
                            name="name"
                            value={name}
                            onChange={(e)=> setName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="form-group">
                        <div className="input-group flex-nowrap">
                          <span className="input-group-text">
                            <i className="fas fa-at"></i>
                          </span>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter Email ID"
                            name="email"
                            value={email}
                            onChange={(e)=> setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="form-group">
                        <div className="input-group flex-nowrap">
                          <span className="input-group-text">
                            <i className="fas fa-lock"></i>
                          </span>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Enter Password"
                            name="password"
                            value={password}
                            onChange={(e)=> setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="form-group">
                        <div className="input-group flex-nowrap">
                          <span className="input-group-text">
                            <i className="fas fa-lock"></i>
                          </span>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Enter Confirm Password"
                            name="password_confirmation"
                            value={password_confirmation}
                            onChange={(e)=> setPassword_confirmation(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="form-group mb-3">
                        <select
                          id="gender_id"
                          name="gender_id"
                          value={selectedGender || ''}
                          onChange={(e)=> setSelectedGender(e.target.value)}
                        >
                          <option value="">Select Gender</option>
                          {gender.map((gender,key) => (
                            <option key={key} value={gender.id}>
                              {gender.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-check mb-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="acceptTerms"
                      checked={acceptTerms}
                      onChange={(e) => setAcceptTerms(e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="acceptTerms"
                    >
                      I confirm that I have read and accept LiveInGo.com's{" "}
                      <Link to="/terms-and-conditions">Terms & Conditions</Link>
                    </label>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button className="btn btn-danger w-50">Register</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registration;
