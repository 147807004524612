import React, { useState } from "react";
import Profile from "../../assets/img/no_image.png";
import Lock from "../../assets/img/lock.png";

function UserProfile() {
  const [imagePreviewUrl, setImagePreviewUrl] = useState(Profile);

  const handleImageChange = (e) => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <div className="card p-3 shadow">
        <div className="row">
          <div className="col-md-8">
            <div className="d-flex align-items-center border p-3 mb-4">
              <div className="avatar-upload me-3">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleImageChange}
                  />
                  <label htmlFor="imageUpload" className="bg-danger">
                    <i className="fas fa-camera text-white"></i>
                  </label>
                </div>
                <div className="avatar-preview">
                  <div
                    id="imagePreview"
                    style={{ backgroundImage: `url(${imagePreviewUrl})` }}
                  ></div>
                </div>
              </div>
              <div>
                <h2>test User</h2>
                <h4>Mumbai, Maharashtra, India</h4>
              </div>
            </div>

            <div className="border p-3">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="font-18">
                  Complete your profile and get 90% more views
                </div>
                <div>
                  <button className="btn btn-outline-danger px-4">Edit</button>
                </div>
              </div>

              <div>
                <form action="">
                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="Name"
                          className="col-form-label text-danger"
                        >
                          Name
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="Gender"
                          className="col-form-label text-danger"
                        >
                          Gender
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <select className="form-select">
                          <option value="" disabled>
                            Please Select
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Looking Gender
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <select className="form-select">
                          <option value="" disabled>
                            Please Select
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Location
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Preferred Location
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Mobile no
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          WhatsApp no
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Motto
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <select className="form-select">
                          <option value="" disabled>
                            Please Select
                          </option>
                          <option value="Serious">Serious</option>
                          <option value="Just for Fun">Just for Fun</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Duration
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <select className="form-select">
                          <option value="" disabled>
                            Please Select
                          </option>
                          <option value="Long term (more than 6 month)">
                            Long term (more than 6 month)
                          </option>
                          <option value="Short term (less than 6 month)">
                            Short term (less than 6 month)
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Marital Status
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <select className="form-select">
                          <option value="" disabled>
                            Please Select
                          </option>
                          <option value="Awaiting Divorce">
                            Awaiting Divorce
                          </option>
                          <option value="Divorced">Divorced</option>
                          <option value="Single">Single</option>
                          <option value="Widowed">Widowed</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Age
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <select className="form-select">
                          <option value="" disabled>
                            Please Select
                          </option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                          <option value="32">32</option>
                          <option value="33">33</option>
                          <option value="34">34</option>
                          <option value="35">35</option>
                          <option value="36">36</option>
                          <option value="37">37</option>
                          <option value="38">38</option>
                          <option value="39">39</option>
                          <option value="40">40</option>
                          <option value="41">41</option>
                          <option value="42">42</option>
                          <option value="43">43</option>
                          <option value="44">44</option>
                          <option value="45">45</option>
                          <option value="46">46</option>
                          <option value="47">47</option>
                          <option value="48">48</option>
                          <option value="49">49</option>
                          <option value="50">50</option>
                          <option value="51">51</option>
                          <option value="52">52</option>
                          <option value="53">53</option>
                          <option value="54">54</option>
                          <option value="55">55</option>
                          <option value="56">56</option>
                          <option value="57">57</option>
                          <option value="58">58</option>
                          <option value="59">59</option>
                          <option value="60">60</option>
                          <option value="61">61</option>
                          <option value="62">62</option>
                          <option value="63">63</option>
                          <option value="64">64</option>
                          <option value="65">65</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Preferred Age Group
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <select className="form-select">
                          <option value="" disabled>
                            Please Select
                          </option>
                          <option value="1">18-25</option>
                          <option value="2">26-32</option>
                          <option value="3">33-39</option>
                          <option value="4">40-49</option>
                          <option value="5">50 above</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Nationality
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <select className="form-select">
                          <option value="" disabled>
                            Please Select
                          </option>
                          <option value="1">Indian</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Religion
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <select className="form-select">
                          <option value="" disabled>
                            Please select
                          </option>
                          <option value="1">Hindu</option>
                          <option value="2">Muslim</option>
                          <option value="3">Christian</option>
                          <option value="4">Sikh</option>
                          <option value="5">Parsi</option>
                          <option value="6">Jain</option>
                          <option value="7">Buddhist</option>
                          <option value="8">Jewish</option>
                          <option value="9">No Religion</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Occupation
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <select
                          className="form-select"
                          data-val="true"
                          data-val-required="*"
                          id="job"
                          name="job"
                        >
                          <option value="" disabled>
                            Please Select
                          </option>
                          <option value="">Occupation</option>
                          <option value="1">Employed</option>
                          <option value="2">Unemployed</option>
                          <option value="3">Student</option>
                          <option value="4">Self Employed</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Profession
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <select className="form-select">
                          <option value="" disabled>
                            Please Select
                          </option>
                          <option value="">Profession</option>
                          <option value="34">Actor</option>
                          <option value="35">Actress</option>
                          <option value="47">Architect</option>
                          <option value="36">Artist</option>
                          <option value="37">Businessman</option>
                          <option value="38">Businesswoman</option>
                          <option value="39">Chef</option>
                          <option value="32">
                            Dietitians and Nutritionists
                          </option>
                          <option value="40">Doctor</option>
                          <option value="3">Engineer</option>
                          <option value="31">Fashion Designer</option>
                          <option value="41">Filmmaker</option>
                          <option value="33">Health Professional</option>
                          <option value="42">Lawyer</option>
                          <option value="45">Massage Therapist</option>
                          <option value="48">Musician</option>
                          <option value="43">Photographer</option>
                          <option value="30">Physiotherapist</option>
                          <option value="49">Psychiatrist</option>
                          <option value="46">Teacher</option>
                          <option value="44">Videographer</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Education
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <select
                          className="form-select"
                          data-val="true"
                          data-val-required="*"
                          id="education"
                          name="education"
                        >
                          <option value="" disabled>
                            Please Select
                          </option>
                          <option value="">Please select</option>
                          <option value="Professional Degree">
                            Professional Degree
                          </option>
                          <option value="Master Degree">Master Degree</option>
                          <option value="Bachelor Degree">
                            Bachelor Degree
                          </option>
                          <option value="Undergraduate">Undergraduate</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Hobbies/Interest
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Food
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <select className="form-select">
                          <option value="" disabled>
                            Please Select
                          </option>
                          <option value="">Please select</option>
                          <option value="1">Non-Veg</option>
                          <option value="2">Veg</option>
                          <option value="3">Vegan</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Height
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <select className="form-select">
                          <option value="" disabled>
                            Please Select
                          </option>
                          <option value="1">105 cm (3ft 5in)</option>
                          <option value="2">107 cm (3ft 6in)</option>
                          <option value="3">110 cm (3ft 7in)</option>
                          <option value="4">112 cm (3ft 8in)</option>
                          <option value="5">115 cm (3ft 9in)</option>
                          <option value="6">117 cm (3ft 10in)</option>
                          <option value="7">120 cm (3ft 11in)</option>
                          <option value="8">122 cm (4ft)</option>
                          <option value="9">125 cm (4ft 1in)</option>
                          <option value="10">127 cm (4ft 2in)</option>
                          <option value="11">130 cm (4ft 3in)</option>
                          <option value="12">132 cm (4ft 3in)</option>
                          <option value="13">135 cm (4ft 5in)</option>
                          <option value="14">137 cm (4ft 5in)</option>
                          <option value="15">140 cm (4ft 7in)</option>
                          <option value="16">142 cm (4ft 7in)</option>
                          <option value="17">145 cm (4ft 9in)</option>
                          <option value="18">147 cm (4ft 9in)</option>
                          <option value="19">150 cm (4ft 11in)</option>
                          <option value="20">152 cm (5ft)</option>
                          <option value="21">155 cm (5ft 1in)</option>
                          <option value="22">157 cm (5ft 1in)</option>
                          <option value="23">160 cm (5ft 2in)</option>
                          <option value="24">162 cm (5ft 3in)</option>
                          <option value="25">165 cm (5ft 4in)</option>
                          <option value="26">167 cm (5ft 5in)</option>
                          <option value="27">170 cm (5ft 6in)</option>
                          <option value="28">172 cm (5ft 7in)</option>
                          <option value="29">110 cm (5ft 8in)</option>
                          <option value="30">177 cm (5ft 9in)</option>
                          <option value="31">180 cm (5ft 10in)</option>
                          <option value="32">182 cm (5ft 11in)</option>
                          <option value="33">185 cm (6ft)</option>
                          <option value="34">187 cm (6ft 1in)</option>
                          <option value="35">190 cm (6ft 2in)</option>
                          <option value="36">192 cm (6ft 3in)</option>
                          <option value="37">195 cm (6ft 4in)</option>
                          <option value="38">197 cm (6ft 5in)</option>
                          <option value="39">200 cm (6ft 6in)</option>
                          <option value="40">202 cm (6ft 7in)</option>
                          <option value="41">205 cm (6ft 8in)</option>
                          <option value="42">207 cm (6ft 9in)</option>
                          <option value="43">210 cm (6ft 10in)</option>
                          <option value="44">211 cm (6ft 11in)</option>
                          <option value="45">212 cm (6ft 11in)</option>
                          <option value="46">213 cm (6ft 11in)</option>
                          <option value="47">214 cm (7ft 0in)</option>
                          <option value="48">215 cm (7ft 0in)</option>
                          <option value="49">216 cm (7ft 1in)</option>
                          <option value="50">217 cm (7ft 1in)</option>
                          <option value="51">218 cm (7ft 1in)</option>
                          <option value="52">219 cm (7ft 2in)</option>
                          <option value="53">220 cm (7ft 2in)</option>
                          <option value="54">221 cm (7ft 3in)</option>
                          <option value="55">222 cm (7ft 3in)</option>
                          <option value="56">223 cm (7ft 3in)</option>
                          <option value="57">224 cm (7ft 4in)</option>
                          <option value="58">225 cm (7ft 4in)</option>
                          <option value="59">226 cm (7ft 4in)</option>
                          <option value="60">227 cm (7ft 5in)</option>
                          <option value="61">228 cm (7ft 5in)</option>
                          <option value="62">229 cm (7ft 6in)</option>
                          <option value="63">230 cm (7ft 6in)</option>
                          <option value="64">231 cm (7ft 6in)</option>
                          <option value="65">232 cm (7ft 7in)</option>
                          <option value="66">233 cm (7ft 7in)</option>
                          <option value="67">234 cm (7ft 8in)</option>
                          <option value="68">235 cm (7ft 8in)</option>
                          <option value="69">236 cm (7ft 8in)</option>
                          <option value="70">237 cm (7ft 9in)</option>
                          <option value="71">238 cm (7ft 9in)</option>
                          <option value="72">239 cm (7ft 10in)</option>
                          <option value="73">240 cm (7ft 10in)</option>
                          <option value="74">241 cm (7ft 10in)</option>
                          <option value="75">242 cm (7ft 11in)</option>
                          <option value="76">243 cm (7ft 11in)</option>
                          <option value="77">244 cm (8ft 0in)</option>
                          <option value="78">245 cm (8ft 0in)</option>
                          <option value="79">246 cm (8ft 0in)</option>
                          <option value="80">247 cm (8ft 1in)</option>
                          <option value="81">248 cm (8ft 1in)</option>
                          <option value="82">249 cm (8ft 2in)</option>
                          <option value="83">250 cm (8ft 2in)</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Weight
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <select className="form-select">
                          <option value="" disabled>
                            Please Select
                          </option>
                          <option value="1">41 Kg</option>
                          <option value="2">42 Kg</option>
                          <option value="3">43 Kg</option>
                          <option value="4">44 Kg</option>
                          <option value="5">45 Kg</option>
                          <option value="6">46 Kg</option>
                          <option value="7">47 Kg</option>
                          <option value="8">48 Kg</option>
                          <option value="9">49 Kg</option>
                          <option value="10">50 Kg</option>
                          <option value="11">51 Kg</option>
                          <option value="12">52 Kg</option>
                          <option value="13">53 Kg</option>
                          <option value="14">54 Kg</option>
                          <option value="15">55 Kg</option>
                          <option value="16">56 Kg</option>
                          <option value="17">57 Kg</option>
                          <option value="18">58 Kg</option>
                          <option value="19">59 Kg</option>
                          <option value="20">60 Kg</option>
                          <option value="21">61 Kg</option>
                          <option value="22">62 Kg</option>
                          <option value="23">63 Kg</option>
                          <option value="24">64 Kg</option>
                          <option value="25">65 Kg</option>
                          <option value="26">66 Kg</option>
                          <option value="27">67 Kg</option>
                          <option value="28">68 Kg</option>
                          <option value="29">69 Kg</option>
                          <option value="30">70 Kg</option>
                          <option value="31">71 Kg</option>
                          <option value="32">72 Kg</option>
                          <option value="33">73 Kg</option>
                          <option value="34">74 Kg</option>
                          <option value="35">75 Kg</option>
                          <option value="36">76 Kg</option>
                          <option value="37">77 Kg</option>
                          <option value="38">78 Kg</option>
                          <option value="39">79 Kg</option>
                          <option value="40">80 Kg</option>
                          <option value="41">81 Kg</option>
                          <option value="42">82 Kg</option>
                          <option value="43">83 Kg</option>
                          <option value="44">84 Kg</option>
                          <option value="45">85 Kg</option>
                          <option value="46">86 Kg</option>
                          <option value="47">87 Kg</option>
                          <option value="48">88 Kg</option>
                          <option value="49">89 Kg</option>
                          <option value="50">90 Kg</option>
                          <option value="51">100 Kg</option>
                          <option value="52">91 Kg</option>
                          <option value="53">92 Kg</option>
                          <option value="54">93 Kg</option>
                          <option value="55">94 Kg</option>
                          <option value="56">95 Kg</option>
                          <option value="57">96 Kg</option>
                          <option value="58">97 Kg</option>
                          <option value="59">98 Kg</option>
                          <option value="60">99 Kg</option>
                          <option value="61">101 Kg</option>
                          <option value="62">102 Kg</option>
                          <option value="63">103 Kg</option>
                          <option value="64">104 Kg</option>
                          <option value="65">105 Kg</option>
                          <option value="66">106 Kg</option>
                          <option value="67">107 Kg</option>
                          <option value="68">108 Kg</option>
                          <option value="69">109 Kg</option>
                          <option value="70">110 Kg</option>
                          <option value="71">111 Kg</option>
                          <option value="72">112 Kg</option>
                          <option value="73">113 Kg</option>
                          <option value="74">114 Kg</option>
                          <option value="75">115 Kg</option>
                          <option value="76">116 Kg</option>
                          <option value="77">117 Kg</option>
                          <option value="78">118 Kg</option>
                          <option value="79">119 Kg</option>
                          <option value="80">120 Kg</option>
                          <option value="81">121 Kg</option>
                          <option value="82">122 Kg</option>
                          <option value="83">123 Kg</option>
                          <option value="84">124 Kg</option>
                          <option value="85">125 Kg</option>
                          <option value="86">126 Kg</option>
                          <option value="87">127 Kg</option>
                          <option value="88">128 Kg</option>
                          <option value="89">129 Kg</option>
                          <option value="90">130 Kg</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <div className="d-block d-sm-flex  align-items-center">
                      <div className="col-md-3">
                        <label
                          htmlFor="inputPassword6"
                          className="col-form-label text-danger"
                        >
                          Live-In Place
                        </label>
                      </div>
                      <div className="col-auto col-md-6">
                        <select className="form-select">
                          <option value="" disabled>
                            Please Select
                          </option>
                          <option value="I Own Space/Flat/Home">
                            I Own Space/Flat/Home
                          </option>
                          <option value="Ready to move Partner Place">
                            Ready to move Partner Place
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center">
                    <button className="btn btn-danger w-50">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="border p-3 mb-4">
              <div className="d-flex justify-content-left">
                <img src={Lock} alt="" className="img-fluid wh-30px me-3" />
                <div>
                  <div className="fw-bold">Unlock all profile with premium</div>
                  <div className="d-flex justify-content-left mb-3">
                    <img src={Profile} alt="" className="wh-30px" />
                    <img src={Profile} alt="" className="wh-30px" />
                    <img src={Profile} alt="" className="wh-30px me-2" />
                    <div className="font-14">
                      Ishita Malik, Mrinal Goswami and millions of other members
                      use Premium
                    </div>
                  </div>
                  <div>
                    <button className="btn btn-danger w-100">
                      Active Membership
                    </button>
                  </div>
                  <div className="small text-danger text-center">
                    Connect and chat with unlimited profiles
                  </div>
                </div>
              </div>
            </div>
            <div className="border p-3">
              <div className="d-flex flex-wrap justify-content-evenly align-items-center">
                <div className="pic-holder">
                  <img src={Profile} alt="" className="pic" />

                  <input
                    className="uploadProfileInput"
                    type="file"
                    name="profile_pic"
                    id="newProfilePhoto"
                    accept="image/*"
                    style={{ opacity: "0" }}
                  />
                  <label
                    htmlFor="newProfilePhoto"
                    className="upload-file-block"
                  >
                    <div className="text-center">
                      <div>
                        <i className="fa fa-camera fa-2x"></i>
                      </div>
                    </div>
                  </label>
                </div>

                <div className="pic-holder">
                  <img src={Profile} alt="" className="pic" />

                  <input
                    className="uploadProfileInput"
                    type="file"
                    name="profile_pic"
                    id="newProfilePhoto"
                    accept="image/*"
                    style={{ opacity: "0" }}
                  />
                  <label
                    htmlFor="newProfilePhoto"
                    className="upload-file-block"
                  >
                    <div className="text-center">
                      <div>
                        <i className="fa fa-camera fa-2x"></i>
                      </div>
                    </div>
                  </label>
                </div>

                <div className="pic-holder">
                  <img src={Profile} alt="" className="pic" />

                  <input
                    className="uploadProfileInput"
                    type="file"
                    name="profile_pic"
                    id="newProfilePhoto"
                    accept="image/*"
                    style={{ opacity: "0" }}
                  />
                  <label
                    htmlFor="newProfilePhoto"
                    className="upload-file-block"
                  >
                    <div className="text-center">
                      <div>
                        <i className="fa fa-camera fa-2x"></i>
                      </div>
                    </div>
                  </label>
                </div>

                <div className="pic-holder">
                  <img src={Profile} alt="" className="pic" />

                  <input
                    className="uploadProfileInput"
                    type="file"
                    name="profile_pic"
                    id="newProfilePhoto"
                    accept="image/*"
                    style={{ opacity: "0" }}
                  />
                  <label
                    htmlFor="newProfilePhoto"
                    className="upload-file-block"
                  >
                    <div className="text-center">
                      <div>
                        <i className="fa fa-camera fa-2x"></i>
                      </div>
                    </div>
                  </label>
                </div>

                <div className="pic-holder">
                  <img src={Profile} alt="" className="pic" />

                  <input
                    className="uploadProfileInput"
                    type="file"
                    name="profile_pic"
                    id="newProfilePhoto"
                    accept="image/*"
                    style={{ opacity: "0" }}
                  />
                  <label
                    htmlFor="newProfilePhoto"
                    className="upload-file-block"
                  >
                    <div className="text-center">
                      <div>
                        <i className="fa fa-camera fa-2x"></i>
                      </div>
                    </div>
                  </label>
                </div>

                <div className="pic-holder">
                  <img src={Profile} alt="" className="pic" />

                  <input
                    className="uploadProfileInput"
                    type="file"
                    name="profile_pic"
                    id="newProfilePhoto"
                    accept="image/*"
                    style={{ opacity: "0" }}
                  />
                  <label
                    htmlFor="newProfilePhoto"
                    className="upload-file-block"
                  >
                    <div className="text-center">
                      <div>
                        <i className="fa fa-camera fa-2x"></i>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;