import React, { useState, useEffect } from "react";
import "../../assets/css/formWizard.css";

function Profile() {
    const [formNumber, setFormNumber] = useState(0);

    useEffect(() => {
      updateForm();
      progressForward();
    }, [formNumber]);
  
    const nextClickHandler = () => {
      setFormNumber((prevNumber) => prevNumber + 1);
    };
  
    const backClickHandler = () => {
      setFormNumber((prevNumber) => prevNumber - 1);
      progressBackward(); // Add this line to update the progress bar when going backward
    };
  
    const submitClickHandler = () => {
      const usernameInput = document.querySelector("#user_name");
      if (usernameInput) {
        const shownName = document.querySelector(".shown_name");
        shownName.innerHTML = usernameInput.value;
      }
      setFormNumber((prevNumber) => prevNumber + 1);
      updateForm();
    };
  
    const updateForm = () => {
      const mainForms = document.querySelectorAll(".main");
      if (formNumber >= 0 && formNumber < mainForms.length) {
        mainForms.forEach((mainForm) => mainForm.classList.remove("active"));
        mainForms[formNumber].classList.add("active");
      }
    };
  
    const progressForward = () => {
      const stepList = document.querySelectorAll(".progress-bar li");
      if (formNumber >= 0 && formNumber < stepList.length) {
        stepList[formNumber].classList.add("active");
      }
    };
  
    const progressBackward = () => {
      const stepList = document.querySelectorAll(".progress-bar li");
      const formNum = formNumber; // Adjust formNum to match array indexing
      stepList[formNum].classList.remove("active");
    };
  
    return (
      <div className="container register">
        <div className="card w-100">
          <div className="form">
            <div className="left-side">
              <h2 className="text-white">LiveInGo</h2>
              <ul className="progress-bar">
                <li className="active">Basic</li>
                <li>Details</li>
                <li>Description</li>
              </ul>
            </div>
            <div className="right-side">
              <div className="main active">
                <form>
                  <div className="text">
                    <h2>Create your Profile</h2>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Full Name"
                    />
                    <label htmlFor="floatingPassword">Full Name</label>
                  </div>
                  <div className="row mb-4">
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phone No."
                        />
                        <label htmlFor="floatingPassword">Phone No.</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                        />
                        <label htmlFor="floatingPassword">Email</label>
                      </div>
                    </div>
                  </div>
  
                  <div className="row mb-4">
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Password"
                        />
                        <label htmlFor="floatingPassword">Password</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Confirm Password"
                        />
                        <label htmlFor="floatingPassword">Confirm Password</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 mb-4">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          <option disabled selected>
                            select Country
                          </option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label htmlFor="floatingSelect">Country</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          <option disabled selected>
                            select City
                          </option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label htmlFor="floatingSelect">City</label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      className="btn btn-danger px-5"
                      type="button"
                      onClick={nextClickHandler}
                    >
                      Next
                    </button>
                  </div>
                </form>
              </div>
  
              <div className="main">
                <form>
                  <div className="text">
                    <h2>Details</h2>
                  </div>
  
                  <div className="row mb-4">
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Height"
                        />
                        <label htmlFor="floatingPassword">Height</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Weight"
                        />
                        <label htmlFor="floatingPassword">Weight</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 mb-4">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          <option>Select Language</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label htmlFor="floatingSelect">Language</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          <option>Select Education</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label htmlFor="floatingSelect">Education</label>
                      </div>
                    </div>
                  </div>
  
                  <div className="row">
                    <div className="col-sm-6 mb-4">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          <option>Select Occupation</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label htmlFor="floatingSelect">Occupation</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          <option>Select Profession</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label htmlFor="floatingSelect">Profession</label>
                      </div>
                    </div>
                  </div>
  
                  <div className="row">
                    <div className="col-sm-6 mb-4">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          <option>Select Religion</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label htmlFor="floatingSelect">Religion</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          <option>Select Caste</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label htmlFor="floatingSelect">Caste</label>
                      </div>
                    </div>
                  </div>
  
                  <div>
                    <button
                      className="btn btn-outline-danger px-5 me-3"
                      type="button"
                      onClick={backClickHandler}
                    >
                      Back
                    </button>
                    <button
                      className="btn btn-danger px-5"
                      type="button"
                      onClick={nextClickHandler}
                    >
                      Next
                    </button>
                  </div>
                </form>
              </div>
              <div className="main">
                <form>
                  <div className="text">
                    <h2>Description</h2>
                  </div>
  
                  <div className="row">
                    <div className="col-sm-6 mb-4">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          <option disabled selected>
                            select Marital Status
                          </option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label htmlFor="floatingSelect">Marital Status</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          <option disabled selected>
                            select Salary Range
                          </option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label htmlFor="floatingSelect">Salary Range</label>
                      </div>
                    </div>
                  </div>
  
                  <div className="row">
                    <div className="col-sm-6 mb-4">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          <option disabled selected>
                            select Interest Category
                          </option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label htmlFor="floatingSelect">Interest Category</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          <option disabled selected>
                            select Interest
                          </option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label htmlFor="floatingSelect">Interest</label>
                      </div>
                    </div>
                  </div>
  
                  <div className="row">
                    <div className="col-sm-6 mb-4">
                      <div className="form-floating">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          <option disabled selected>
                            select Gender
                          </option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <label htmlFor="floatingSelect">Gender</label>
                      </div>
                    </div>
                  </div>
  
                  <div className="form-floating mb-4">
                    <textarea
                      className="form-control"
                      placeholder="Leave a comment here"
                      id="floatingTextarea2"
                      row="5"
                    ></textarea>
                    <label for="floatingTextarea2">Comments</label>
                  </div>
                  <div>
                    <button
                      className="btn btn-outline-danger px-5 me-3"
                      type="button"
                      onClick={backClickHandler}
                    >
                      Back
                    </button>
                    <button
                      className="btn btn-danger px-5"
                      type="button"
                      onClick={submitClickHandler}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
  
              <div className="main">
                <svg
                  className="checkmark"
                  xmlns="http:www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
  
                <div className="text congrats">
                  <h2>Congratulations!</h2>
                  <p>
                    Thanks Mr./Mrs. <span className="shown_name"></span> your
                    information have been submitted successfully for the future
                    reference we will contact you soon.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default Profile;