import AuthUser from '../AuthUser';
import React, { useState } from 'react';
import { toast } from 'react-toastify';


function Login() {
  const {http, setToken,setUser} = AuthUser();
  const [email, setEmail] = useState('');
  const [password, SetPassword] = useState('');
  

  function ProceedLogin(e) {
    e.preventDefault();
    if(validate()){
      // console.log('process');     

     http.post('/login', {email:email,password:password}).then((res)=>{
        if (res.data.status) {
          setToken(res.data.token);
          setUser(res.data.user);
          toast.success('Login Successful');
        } else {
          toast.error(res.data.message);
        }
      }).catch(()=>{
        toast.error('Login fail');
      });
      
    }
  }

  const validate = ()=>{
    let result=true;
    
    if(email === '' || email === null){
      result = false
      toast.warning('please enter Username')
    }
    if(password === '' || password === null){
      result = false
      toast.warning('please enter Valid Password')
    }

    return result
  }

  

    return (
      <div className='d-flex justify-content-center align-items-center h-100vh bg-primary'>
       <div className="container">
       <div className="row h-100 align-items-center justify-content-center">
          <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-6">
            <div className="bg-light rounded p-4 p-sm-5 my-4 mx-3">
              <div className="d-block text-center mb-3">
                <h2 className="text-primary">Liveingo</h2>
                <h4>Sign In</h4>
              </div>
              <form onSubmit={ProceedLogin}>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="floatingInput">Email address</label>
              </div>
              <div className="form-floating mb-4">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => SetPassword(e.target.value)}
                />
                <label htmlFor="floatingPassword">Password</label>
              </div>
              {/* <div className="d-flex align-items-center justify-content-between mb-4">
                <Link to="">Forgot Password</Link>
              </div> */}
              <button type="submit" className="btn btn-primary py-3 w-100 mb-4">
                Sign In
              </button>
              {/* <p className="text-center mb-0">
                Don't have an Account? <Link to={'/registration'}>Sign Up</Link>
              </p> */}
              </form>
            </div>
          </div>
        </div>
       </div>
      </div>
    );
}

export default Login;