import React, { useEffect } from "react";
import AuthUser from "../../AuthUser";

function StepOne({ formData, handleChange, errors,setFormData,profileExists}) {
  const {http,getUser} = AuthUser();
  const currentUser = getUser();
  const userRole = currentUser?.role_name
  const userId = currentUser?.id
  const heights = [
    { value: "Below 137", label: "Below 4ft 6in - 137cm" },
    { value: "137", label: "4ft 6in - 137cm" },
    { value: "139", label: "4ft 7in - 139cm" },
    { value: "142", label: "4ft 8in - 142cm" },
    { value: "144", label: "4ft 9in - 144cm" },
    { value: "147", label: "4ft 10in - 147cm" },
    { value: "149", label: "4ft 11in - 149cm" },
    { value: "152", label: "5ft - 152cm" },
    { value: "154", label: "5ft 1in - 154cm" },
    { value: "157", label: "5ft 2in - 157cm" },
    { value: "160", label: "5ft 3in - 160cm" },
    { value: "162", label: "5ft 4in - 162cm" },
    { value: "165", label: "5ft 5in - 165cm" },
    { value: "167", label: "5ft 6in - 167cm" },
    { value: "170", label: "5ft 7in - 170cm" },
    { value: "172", label: "5ft 8in - 172cm" },
    { value: "175", label: "5ft 9in - 175cm" },
    { value: "177", label: "5ft 10in - 177cm" },
    { value: "180", label: "5ft 11in - 180cm" },
    { value: "182", label: "6ft - 182cm" },
    { value: "185", label: "6ft 1in - 185cm" },
    { value: "187", label: "6ft 2in - 187cm" },
    { value: "190", label: "6ft 3in - 190cm" },
    { value: "193", label: "6ft 4in - 193cm" },
    { value: "195", label: "6ft 5in - 195cm" },
    { value: "198", label: "6ft 6in - 198cm" },
    { value: "200", label: "6ft 7in - 200cm" },
    { value: "203", label: "6ft 8in - 203cm" },
    { value: "205", label: "6ft 9in - 205cm" },
    { value: "208", label: "6ft 10in - 208cm" },
    { value: "210", label: "6ft 11in - 210cm" },
    { value: "213", label: "7ft - 213cm" },
    { value: "Above 213", label: "Above 7ft - 213cm" }
  ];
  const weights = Array.from({ length: 101 }, (_, i) => ({
    value: (i + 40).toString(),
    label: `${i + 40} Kg`,
  }));

  useEffect(() => {
    if(profileExists){
      getUserProfile();
    }
  }, [profileExists]);

  async function getUserProfile() {
     try {
    http.get(`user/profile/${userId}`).then((res) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      phone: res.data?.data?.phone || '',
      dob: res.data?.data?.dob || '',
    }));

    });
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
  }

   // Calculate the date 18 years ago from today
   const today = new Date();
   const maxDate = new Date(today.setFullYear(today.getFullYear() - 18)).toISOString().split('T')[0];
  return (
    <div>
      <form>
        <div className="mb-3">
          <h4>Basic Information</h4>
        </div>
        <div className="row mb-4">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                Full Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Full Name"
                value={formData.name || ''}
                onChange={handleChange}
                name="name"
              />
              {errors.name && <div className="text-danger">{errors.name}</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                Phone No.
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Phone No."
                value={formData.phone || ''}
                onChange={handleChange}
                name="phone"
              />
              {errors.phone && (
                <div className="text-danger">{errors.phone}</div>
              )}
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={formData.email || ''}
                onChange={handleChange}
                name="email"
              />
              {errors.email && (
                <div className="text-danger">{errors.email}</div>
              )}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                Date of Birth
              </label>
              <input
                type="date"
                className="form-control"
                placeholder="Date of Birth"
                value={formData.dob || ''}
                onChange={handleChange}
                name="dob"
                max={maxDate}
              />
               {errors.dob && (
                <div className="text-danger">{errors.dob}</div>
              )}
            </div>
          </div>
          {userRole !== 'user' && (
          <div className="col-sm-6">
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                New Password
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Password"
                value={formData.password || ''}
                onChange={handleChange}
                name="password"
              />
               {errors.password && (
                <div className="text-danger">{errors.password}</div>
              )}
            </div>
          </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default StepOne;
