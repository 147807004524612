import React from "react";
import { Link } from "react-router-dom";
import Profile from "../../assets/img/no_image.png";
import Lock from "../../assets/img/lock.png";

function ProfilePerformance() {
  return (
    <div>
      <div className="card p-4 shadow">
        <div className="card-body">
          <div className="fw-bold mb-2 font-18">
            Who's matched to your profile
          </div>
          <div className="mb-1">
            <div>
              Unlock all profiles with{" "}
              <Link to={""} className="text-primary">
                Premium Membership
              </Link>
            </div>
          </div>
          <div className="d-flex justify-content-left align-items-center mb-5">
            <img src={Profile} alt="" className="wh-30px" />
            <img src={Profile} alt="" className="wh-30px" />
            <img src={Profile} alt="" className="wh-30px me-2" />
            <div className="font-14">
              Ishita Malik, Mrinal Goswami and millions of other members use
              Premium
            </div>
          </div>

          <div className="fw-bold font-18 mb-3">
            Profiles you might be interested in
          </div>

          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="d-flex justify-content-left align-items-center">
              <img src={Profile} alt="" className="wh-80px me-4" />
              <div className="font-14">
                Someone who is Female, Age 24 years, and interested in Male
                Partner for live-in relationship. Person is employed.
              </div>
            </div>
            <div className="lock-box">
              <i className="fas fa-lock"></i>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="d-flex justify-content-left align-items-center">
              <img src={Profile} alt="" className="wh-80px me-4" />
              <div className="font-14">
                Someone who is Female, Age 30 years, and interested in Male
                Partner for live-in relationship. Person is employed.
              </div>
            </div>
            <div className="lock-box">
              <i className="fas fa-lock"></i>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="d-flex justify-content-left align-items-center">
              <img src={Profile} alt="" className="wh-80px me-4" />
              <div className="font-14">
                Someone who is Female, Age 37 years, and interested in Male
                Partner for live-in relationship. Person is employed.
              </div>
            </div>
            <div className="lock-box">
              <i className="fas fa-lock"></i>
            </div>
          </div>
          <hr />

          <div className="d-flex justify-content-between pt-4">
            <div className="d-flex justify-content-left">
              <img src={Lock} alt="" className="img-fluid wh-30px me-3" />
              <div>
                <div className="fw-bold">Unlock all profile with premium</div>
                <div className="d-flex justify-content-left align-items-center mb-3">
                  <img src={Profile} alt="" className="wh-30px" />
                  <img src={Profile} alt="" className="wh-30px" />
                  <img src={Profile} alt="" className="wh-30px me-2" />
                  <div className="font-14">
                    Ishita Malik, Mrinal Goswami and millions of other members
                    use Premium
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <button className="btn btn-danger w-100">
                  Active Membership
                </button>
              </div>
              <div className="small text-danger text-center">
                Connect and chat with unlimited profiles
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePerformance;