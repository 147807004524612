import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <div>

        <div className="py-4 bg-light mt-auto">
                    <div className="container-fluid px-4">
                        <div className="d-flex align-items-center justify-content-between small">
                        <div className="col-12 col-sm-6 text-center text-sm-start">
                &copy; <Link href="{'/'}">My Live in Partner</Link>, All Right
                Reserved.
              </div>
                        </div>
                    </div>
                </div>
       
      </div>
    );
  }
}

export default Footer;
