import React, { useEffect, useState } from "react";
import UserProfile from "../assets/img/no_image.png";
import { Link } from "react-router-dom";
import AuthUser from "./AuthUser";

function LiveinPartner() {
  const {http} = AuthUser();
  const [apiData, setApiData] = useState([]);

  useEffect(()=>{
    http.get(`/user-profile`)
    .then((res)=>{
      console.log(res.data);
      setApiData(res.data)
    })
  }, [])

  return (
    <div className="pt-4">
      <div className="card shadow">
        <div className="card-body">
          <h3 className="text-danger text-center mb-3">
            Find Male Partner For Live-In Relationship In Navi Mumbai (New
            Bombay) | Cohabitation With Male Partner In Navi Mumbai (New Bombay)
          </h3>
          <div className="card shadow mb-4 mt-2 py-3 px-2">
            <form action="">
              <div className="d-flex justify-content-evenly align-items-center">
                <div>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Navi Mumbai (New Bombay), Maharashtra, India"
                  />
                </div>
                <div className="form-group">
                  <select name="" id="" className="form-select">
                    <option value="">Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="form-group">
                  <select name="" id="" className="form-select">
                    <option value="">Age</option>
                    <option value="">18-20</option>
                    <option value="">21-30</option>
                    <option value="">31-40</option>
                  </select>
                </div>
                <div className="form-group">
                  <select name="" id="" className="form-select">
                    <option value="">Marital Status</option>
                    <option value="">Awaiting Divorce</option>
                    <option value="">Divorced</option>
                    <option value="">Single</option>
                    <option value="">Widowed</option>
                  </select>
                </div>
                <div className="form-group">
                  <select name="" id="" className="form-select">
                    <option value="">Duration</option>
                    <option value="">Long term</option>
                    <option value="">Short term</option>
                  </select>
                </div>
                <div className="form-group">
                  <select name="" id="" className="form-select">
                    <option value="">Interest</option>
                    <option value="">Serious</option>
                    <option value="">Just For Fun</option>
                  </select>
                </div>
                <div className="form-group">
                  <select name="" id="" className="form-select">
                    <option value="">Occupation</option>
                    <option value="">Employed</option>
                    <option value="">Unemployed</option>
                    <option value="">Student</option>
                    <option value="">Self Employed</option>
                  </select>
                </div>
                <div>
                  <button className="btn btn-danger">Search</button>
                </div>
              </div>
            </form>
          </div>
          <div className="mb-3 font-18">
            Check and choose a MaleLiveInGOfor your live-in
            relationship (cohabitation) in Navi Mumbai (New Bombay). You can
            search and find the best Navi Mumbai (New Bombay) based Male Live-In
            Partners profiles with their preferred locations, sexual
            orientation, social status, financial status, age,based on gender,
            interests, marital status, professional status, religion, and race,
            nationality, food (veg, non-veg, vegan), height & weight, and other
            important aspects for your live-in relationship (cohabitation)
            requirement in Navi Mumbai (New Bombay) location. Get additional
            facilities to search for and choose your Male Live-In Partners based
            on sexual orientations like lesbian, gay, bisexual, and transgender.
            You can check and shortlist the availableLiveInGOprofiles
            based on short term and long term live-in relationship
            (cohabitation) interest in Navi Mumbai (New Bombay).
          </div>

          {/* User profile card start here */}
         <div className="row">
         {/* <div className="col-sm-6">
            <div className="card shadow bg-light mb-3">
              <div className="row p-3">
                <div className="col-md-3 text-center">
                  <div className="mb-3">
                  <img src={UserProfile} alt="" />
                  </div>
                  
                  <div>
                      <div className="d-flex justify-content-evenly">
                        <div className="text-center">
                          <i className="fas fa-thumbs-up text-primary"></i>
                        </div>
                        <div className="text-center">
                          <i className="fas fa-envelope"></i>
                        </div>
                        <div className="text-center">
                          <i className="fas fa-heart text-danger"></i>                          
                        </div>                       
                      </div>
                    </div>
                </div>
                <div className="col-md-9">
                  
                    <div>
                      <Link to={`/user/profile-details`}><h4 className="text-danger">Donna J. Perryman</h4></Link>
                      <div className="d-flex mb-3">
                        <div className="me-2">Member Id:</div>
                        <div className="text-danger">E0EE4E4D</div>
                      </div>
                    </div>  

                  <div className="row">
                      <div className="d-flex align-items-center mb-3">
                          <i className="fas fa-map-marker-alt text-danger fw-bold me-3"></i>: India, Maharastra, Mumbai                      
                      </div>

                      <div className="d-flex align-items-center mb-3">
                      <i className="fas fa-user-graduate text-danger fw-bold me-3"></i>: Master Degree                     
                      </div>                 

                      <div className="d-flex align-items-center mb-3">
                      <i className="fas fa-phone-alt text-danger fw-bold me-3"></i>: <i className="fas fa-lock ms-1"></i>                     
                      </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          

          {apiData.map((profile) => (
              <div className="col-sm-6" key={profile.id}>
                <div className="card shadow bg-light mb-3">
                  <div className="row p-3">
                    <div className="col-md-3 text-center">
                      <div className="mb-3">
                        <img src={`http://localhost:8000/storage/${profile.profile_img}`} alt="" />
                      </div>
                      
                      <div>
                      <div className="d-flex justify-content-evenly">
                        <div className="text-center">
                          <i className="fas fa-thumbs-up text-primary"></i>
                        </div>
                        <div className="text-center">
                          <i className="fas fa-envelope"></i>
                        </div>
                        <div className="text-center">
                          <i className="fas fa-heart text-danger"></i>                          
                        </div>                       
                      </div>
                    </div>
                    </div>
                    <div className="col-md-9">
                      <div>
                        <Link to={`/user/profile-details/${profile.id}`}>
                          <h4 className="text-danger">{profile.user?.name}</h4>
                        </Link>
                      </div>
                      <div className="row">
                        <div className="d-flex align-items-center mb-3">
                          <i className="fas fa-map-marker-alt text-danger fw-bold me-3"></i>: {profile.country?.name}, {profile.state?.name},{profile.city?.name}
                        </div>
                        <div className="d-flex align-items-center mb-3">
                        <i className="fas fa-user-graduate text-danger fw-bold me-3"></i>: {profile.education?.title} 
                        </div>
                        <div className="d-flex align-items-center mb-3">
                      <i className="fas fa-phone-alt text-danger fw-bold me-3"></i>: **********<i className="fas fa-lock ms-1"></i>                     
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

         </div>

        
          {/* User profile card end here */}
        </div>
      </div>
    </div>
  );
}

export default LiveinPartner;
