import React from "react";

function SafetyTips() {
  return (
    <div>
      <div className="pt-4">
        <div className="card">
          <div className="card-header">
            <h3 className="fw-bold">Live-in Partner Safety Tips</h3>
          </div>
          <div className="card-body">
            <div className="mb-4">
              Interacting online profiles and with people is great and always
              gives you the best exciting experience and feelings, but you
              should always beware when interacting with someone you don’t
              actually know. Use your best judgment and put your safety first,
              whether you are chatting or meeting face to face with a person.
              While you can’t control the actions of others, there are some tips
              you can do to help you stay safe during your LiveInGo experience.
            </div>

            <div className="mb-4">
              <h3 className="mb-3 fw-bold">Online Safety</h3>
              <div className="mb-3">
                <div className="fw-bold font-18">
                  Never Send Money or Share Financial Information
                </div>
                <p>
                  Never send money, especially over wire transfer, even if the
                  person claims to be in an emergency. Wiring money is like
                  sending cash — it’s nearly impossible to reverse the
                  transaction or trace where the money went. Never share
                  information that could be used to access your financial
                  accounts. If another user asks you for money, report it at
                  support@LiveInGo.com immediately.
                </p>
              </div>
              <div className="mb-3">
                <div className="fw-bold font-18">
                  Protect Your Personal Information
                </div>
                <p>
                  Never share personal information, such as your government
                  identity number, home or work address, or details about your
                  daily routine (e.g., that you go to a certain gym every
                  Monday) with people you don’t know. If you are a parent, limit
                  the information that you share about your children on your
                  profile and in early communications. Avoid sharing details
                  such as your children’s names, where they go to school, or
                  their ages or genders.
                </p>
              </div>
              <div className="mb-3">
                <div className="fw-bold font-18">Stay on the Platform</div>
                <p>
                  Keep conversations on the LiveInGo platform while you’re
                  getting to know someone. Do not try to connect with an unknown
                  person on your personal phone, Whatsapp, and other
                  communication modes.
                </p>
              </div>
              <div className="mb-3">
                <div className="fw-bold font-18">
                  Be Wary of Long Distance and Overseas Relationships
                </div>
                <p>
                  Watch out for scammers who claim to be from your country but
                  stuck somewhere else, especially if they ask for financial
                  help to return home. Be wary of anyone who will not meet in
                  person or talk on a phone/video call—they may not be who they
                  say they are. If someone is avoiding your questions or pushing
                  for a serious relationship without meeting or getting to know
                  you first — that’s a red flag.
                </p>
              </div>
              <div className="mb-3">
                <div className="fw-bold font-18">
                  Report All Suspicious and Offensive Behavior
                </div>
                <p>
                  You know when someone’s crossed the line and when they do, we
                  want to know about it. Block and report anyone that violates
                  our terms. Here are some examples of violations:
                  <ul>
                    <li>Requests for money or donations</li>
                    <li>Underage users</li>
                    <li>Harassment, threats, and offensive messages</li>
                    <li>
                      Inappropriate or harmful behavior during or after meeting
                      in person
                    </li>
                    <li>Fraudulent profiles</li>
                    <li>
                      Spam or solicitation including links to commercial
                      websites or attempts to sell products or services
                    </li>
                  </ul>
                  You can report any concerns about suspicious behavior from any
                  profile page or at support@LiveInGo.com.
                </p>
              </div>
              <div className="mb-3">
                <div className="fw-bold font-18">Protect Your Account</div>
                <p>
                  Be sure to pick a strong password, and always be careful when
                  logging into your account from a public or shared computer.
                  LiveInGo will never send you an email asking for your username
                  and password information — if you receive an email asking for
                  account information, report it immediately at
                  support@LiveInGo.com.
                </p>
              </div>
            </div>

            <div className="mb-4">
              <h3 className="mb-3 fw-bold">Meeting in Person</h3>
              <div className="mb-3">
                <div className="fw-bold font-18">Don’t Be In A Rush</div>
                <p>
                  Take your time and get to know the other person before
                  agreeing to meet or chat off MyLiveinPartner. Don’t be afraid
                  to ask questions to screen for any red flags or personal
                  dealbreakers. A phone or video call can be a useful screening
                  tool before meeting.
                </p>
              </div>
              <div className="mb-3">
                <div className="fw-bold font-18">
                  Meet in Public and Stay in Public
                </div>
                <p>
                  Meet for the first few times in a populated, public place —
                  never at your home, your date’s home, or any other private
                  location. If your date pressures you to go to a private
                  location, end the date.
                </p>
              </div>
              <div className="mb-3">
                <div className="fw-bold font-18">
                  Tell Friends and Family About Your Plans
                </div>
                <p>
                  Tell a friend or family member of your plans, including when
                  and where you’re going. Have your cell phone charged and with
                  you at all times.
                </p>
              </div>
              <div className="mb-3">
                <div className="fw-bold font-18">
                  Be in Control of Your Transportation
                </div>
                <p>
                  We want you to be in control of how you get to and from your
                  date so that you can leave whenever you want. If you’re
                  driving yourself, it’s a good idea to have a backup plan such
                  as a ride-share app or a friend to pick you up.
                </p>
              </div>
              <div className="mb-3">
                <div className="fw-bold font-18">Know Your Limits</div>
                <p>
                  Be aware of the effects of drugs or alcohol on you
                  specifically — they can impair your judgment and your
                  alertness. If your date tries to pressure you to use drugs or
                  drink more than you’re comfortable with, hold your ground and
                  end the date.
                </p>
              </div>
              <div className="mb-3">
                <div className="fw-bold font-18">
                  Don’t Leave Drinks or Personal Items Unattended
                </div>
                <p>
                  Know where your drink comes from and know where it is at all
                  times — only accept drinks poured or served directly from the
                  bartender or server. Many substances that are slipped into
                  drinks to facilitate sexual assault are odorless, colorless,
                  and tasteless. Also, keep your phone, purse, wallet, and
                  anything containing personal information on you at all times.
                </p>
              </div>
              <div className="mb-3">
                <div className="fw-bold font-18">
                  If You Feel Uncomfortable, Leave
                </div>
                <p>
                  It’s okay to end the date early if you’re feeling
                  uncomfortable. In fact, it’s encouraged. And if your instincts
                  are telling you something is off or you feel unsafe, ask the
                  bartender or server for help.
                </p>
              </div>
              <div className="mb-3">
                <div className="fw-bold font-18">LGBTQ+ Travel</div>
                <p>Be careful while traveling</p>
                <p>
                  We recognize and believe in the importance of being inclusive
                  of all gender identities and sexual orientations, but the
                  reality is this: nowhere in the world is without potential
                  risk, and some countries have specific laws that target LGBTQ+
                  people.
                </p>

                <p>
                  Check out the laws around you when you travel to a new place
                  and research what types of legal protection, if any, are
                  available to you based on sexual orientation. In the event
                  that you’re in unsafe territory, we suggest toggling off “Show
                  me on MyLiveinPartner” which you can find under the settings
                  page.
                </p>

                <p>
                  If you have added a sexual orientation to your profile and
                  choose to be shown on MyLiveinPartner, we will hide your
                  sexual orientation from your profile until you leave that
                  area.
                </p>

                <p>
                  It’s important to exercise extra caution if you choose to
                  connect with new people in these countries - as some law
                  enforcement have been known to use dating apps as tools for
                  potential entrapment. Some countries have also recently
                  introduced laws that criminalize communications between
                  individuals on same-sex dating applications or websites and
                  even aggravate penalties if that communication leads to sexual
                  encounters.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SafetyTips;
