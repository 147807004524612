import React from "react";

function TermsCondition() {
  return (
    <div>
      <div className="pt-4">
        <div className="card">
          <div className="card-header">
            <h3 className="fw-bold">Terms &amp; Conditions</h3>
          </div>
          <div className="card-body">
            <div>
              <h3 className="fw-bold">
                This Live-In Partner/relationship community service system is
                the copyright of Liveingo.
              </h3>
              <p>
                Liveingo is the product of Sterling Technologies. By
                using the service you are deemed to have accepted these terms
                and conditions of use (the “User Terms”)
              </p>

              <p>
                &#11166; Please note that Liveingo is an online platform
                where Live in relationship interested people can contact each
                other, using which users can get information and contact data or
                personal details from his/her wished partner on the basis of
                information made available by his/her wished partner.
              </p>
              <p>
                &#11166; Therefore by using the service at Liveingo.com,
                a Live in relationship interested person entering into a direct
                contract with other Live in relationship interested person and
                Liveingo.com is not a party to that contract anyway.
              </p>
              <p>
                &#11166; This is a mutual agreement between Live in relationship
                interested persons. Live in relationship interested person must
                make sure that all the information provided is true and correct.
                And, there will not be any violation of data privacy.
              </p>
              <p>
                &#11166; Please ensure that your Live in relationship interested
                person should be devoted to keeping your privacy safe.
                Liveingo.com unable to handle your privacy and we will
                not take any responsibility when you contact a Live in
                relationship interested person offline.
              </p>
              <p>
                &#11166; All copyright, database rights, trade marks,
                intellectual property rights and other proprietary rights
                subsisting in our Service and any content made available to you
                from the Service, shall remain our property or the property of
                our licensors or Liveingo.
              </p>
              <p>
                &#11166; All such rights are expressly reserved. We maintain the
                highest standards of security, although the transmission of
                information via the internet is not completely secure. So, we'll
                try our best but we cannot ensure the security of your data
                transmitted through our Service.
              </p>
              <p>
                &#11166; Liveingo.com is 'not an adult' social network,
                so we aim to provide a clean, safe environment for all our users
                at Liveingo.com.
              </p>
              <p>
                &#11166; We reserve all rights related to any updates, deletion
                of the information. Appropriate law will apply to the user terms
                in case of any claim arising from or related to your use of the
                Service. You may contact us on any issue detailed in these User
                Terms at info@Liveingo.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsCondition;
