import React from 'react';
import { Link } from 'react-router-dom';
import Profile from "../../assets/img/user.jpg";

function MessageBox () {
    
    const imagePath = 'https://mehedihtml.com/chatbox/assets/img/upload.svg';
const Arrow = 'https://mehedihtml.com/chatbox/assets/img/arroleftt.svg';


        return (
            <div className="message-area shadow">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="chat-area">
                    
                    <div className="chatlist">
                      <div className="modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="chat-header">
                            <div className="msg-search">
                              <input type="text" className="form-control" id="inlineFormInputGroup" placeholder="Search" aria-label="search" />
                              <Link className="add" to={''}><img className="img-fluid rounded-circle" src={Profile} alt="add" /></Link>
                            </div>
          
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                              <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="Open-tab" data-bs-toggle="tab" data-bs-target="#Open" type="button" role="tab" aria-controls="Open" aria-selected="true">Open</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button className="nav-link" id="Closed-tab" data-bs-toggle="tab" data-bs-target="#Closed" type="button" role="tab" aria-controls="Closed" aria-selected="false">Closed</button>
                              </li>
                            </ul>
                          </div>
          
                          <div className="modal-body">
                            
                            <div className="chat-lists">
                              <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="Open" role="tabpanel" aria-labelledby="Open-tab">
                                    
                                  <div className="chat-list">
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                        <span className="active"></span>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Mehedi Hasan</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Ryhan</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Malek Hasan</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Sadik Hasan</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Bulu </h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Maria SK</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Dipa Hasan</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Jhon Hasan</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Tumpa Moni</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Payel Akter</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Baby Akter</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Zuwel Rana</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Habib </h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Jalal Ahmed</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Hasan Ali</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Mehedi Hasan</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
          
                                  </div>
                                  
                                </div>
                                <div className="tab-pane fade" id="Closed" role="tabpanel" aria-labelledby="Closed-tab">
          
                                
                                  <div className="chat-list">
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                        <span className="active"></span>
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Mehedi Hasan</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Ryhan</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Malek Hasan</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Sadik Hasan</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Bulu </h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Maria SK</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Dipa Hasan</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Jhon Hasan</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Tumpa Moni</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Payel Akter</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Baby Akter</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Zuwel Rana</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Habib </h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Jalal Ahmed</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Hasan Ali</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
                                    <Link to={''} className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <h3>Mehedi Hasan</h3>
                                        <p>front end developer</p>
                                      </div>
                                    </Link>
          
                                  </div>
                                  
                                </div>
                              </div>
          
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="chatbox showbox">
                      <div className="modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="msg-head">
                            <div className="row">
                              <div className="col-8">
                                <div className="d-flex align-items-center">
                                  <span className="chat-icon"><img className="img-fluid rounded-circle" src={Arrow} alt="" /></span>
                                  <div className="flex-shrink-0">
                                    <img className="img-fluid rounded-circle" src={Profile} alt="user img" />
                                  </div>
                                  <div className="flex-grow-1 ms-3">
                                    <h3>Mehedi Hasan</h3>
                                    <p>front end developer</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4">
                                <ul className="moreoption">
                                  <li className="navbar nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to={''} role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></Link>
                                    <ul className="dropdown-menu">
                                      <li><Link className="dropdown-item" to={''}>Action</Link></li>
                                      <li><Link className="dropdown-item" to={''}>Another action</Link></li>
                                      <li>
                                        <hr className="dropdown-divider" />
                                      </li>
                                      <li><Link className="dropdown-item" to={''}>Something else here</Link></li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
          
                          <div className="modal-body">
                            <div className="msg-body">
                              <ul>
                                <li className="sender">
                                  <p> Hey, Are you there? </p>
                                  <span className="time">10:06 am</span>
                                </li>
                                <li className="sender">
                                  <p> Hey, Are you there? </p>
                                  <span className="time">10:16 am</span>
                                </li>
                                <li className="repaly">
                                  <p>yes!</p>
                                  <span className="time">10:20 am</span>
                                </li>
                                <li className="sender">
                                  <p> Hey, Are you there? </p>
                                  <span className="time">10:26 am</span>
                                </li>
                                <li className="sender">
                                  <p> Hey, Are you there? </p>
                                  <span className="time">10:32 am</span>
                                </li>
                                <li className="repaly">
                                  <p>How are you?</p>
                                  <span className="time">10:35 am</span>
                                </li>
                                <li>
                                  <div className="divider">
                                    <h6>Today</h6>
                                  </div>
                                </li>
          
                                <li className="repaly">
                                  <p> yes, tell me</p>
                                  <span className="time">10:36 am</span>
                                </li>
                                <li className="repaly">
                                  <p>yes... on it</p>
                                  <span className="time">junt now</span>
                                </li>
          
                              </ul>
                            </div>
                          </div>
          
                          <div className="send-box">
                            <form action="">
                              <input type="text" className="form-control" aria-label="message…" placeholder="Write message…" />
          
                              <button type="button" className='btn btn-danger'><i className="fa fa-paper-plane" aria-hidden="true"></i> Send</button>
                            </form>
          
                            <div className="send-btns">
                              <div className="attach">
                                <div className="button-wrapper">
                                  <span className="label">
                                    <img src={imagePath} alt="" className='img-fluid rounded-circle' /> attached file
                                  </span><input type="file" name="upload" id="upload" className="upload-box" placeholder="Upload File" aria-label="Upload File" />
                                </div>
          
                              
                              </div>
                            </div>
          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
          
                </div>
              </div>
            </div>
            </div>
            
        );
}

export default MessageBox;