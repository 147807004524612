import React, { useEffect, useState } from "react";
import AuthUser from "../../AuthUser";


function StepFour({ formData, handleChange, setFormData,profileExists }) {
  const { http,getUser} = AuthUser();
  const [id] = useState();
  const currentUser = getUser();
  const userId = currentUser?.id
  const [interestCategory, setInterestCategory] = useState([]);

  const [interest, setInterest] = useState([]);
  const [selectedInterestIds, setSelectedInterestIds] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const interestCategoryResponse = await http.get("/interestCategory");
        setInterestCategory(interestCategoryResponse.data);

        const interestResponse = await http.get("/interest");
        setInterest(interestResponse.data);
        if (userId && profileExists) {
          const userResponse = await http.get(`/user/profile/${userId}`);
          
          const { interest_id,short_dis,comments } = userResponse.data.data;

          if (interest_id && interest_id !== null) {
            setFormData(prevFormData => ({
                  ...prevFormData,
                  interest_id: interest_id,
                  short_dis:short_dis,
                  comments:comments
            }));
            const interestIdsArray = interest_id.split(",").map(id => parseInt(id.trim()));
            setSelectedInterestIds(interestIdsArray);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  const handleInterestClick = (interestId) => {
    setSelectedInterestIds((prevIds) => {
      const updatedIds = prevIds.includes(interestId)
        ? prevIds.filter((id) => id !== interestId)
        : [...prevIds, interestId];
      
        setTimeout(() => {
        handleChange({ target: { name: "interest_id", value: updatedIds } });
      }, 0);
      
      return updatedIds;
    });
  };

  const isInterestSelected = (interestId) => selectedInterestIds.includes(interestId);

  return (
    <div>
      <form>
        <div className="mb-4">
          <h4>Interest</h4>
        </div>
        <div className="row mb-4">
        {interestCategory.map((category) => (
            <div key={category.id} className="col-sm-12 mb-3">
              <div className="card custom-card ">
                <div className="card-header"><h4>{category.title}</h4></div>
                <div className="card-body pt-0">
                  {interest
                    .filter((item) => item.interest_category_id === category.id)
                    .map((item) => (
                      <div
                        key={item.id}
                        className={`badge mr-2 mb-2 ${isInterestSelected(item.id) ? "" : ""}`}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleInterestClick(item.id)}
                      >
                        {isInterestSelected(item.id) &&  <span className="fas fa-check-circle text-success mr-2" style={{ paddingRight: "6px"}}></span>}
                        {item.title}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
          
         <div className="col-sm-12">
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                Short Discription
              </label>
              <input type="text"  name="short_dis"
                id="short_dis"
                className="w-100 form-control"
                value={formData.short_dis || ''}
                onChange={handleChange} />         
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                Summary
              </label>
              <textarea
                name="comments"
                id="comments"
                className="w-100 form-control"
                rows="5"
                value={formData.comments || ''}
                onChange={handleChange}
              />
            </div>
          </div> *
        </div>
      </form>
    </div>
  );
}

export default StepFour;
