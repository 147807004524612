import React, { useState,useEffect  } from 'react';
import { ProgressBar } from 'react-bootstrap';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import Profile from "../../../assets/img/no_image.png";
import AuthUser from '../../AuthUser';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function UserForm({handleClose,handleFormSubmit }) {
  const {http,getUser} = AuthUser();
  const currentUser = getUser()
  const userRole = currentUser?.role_name
  const userId = currentUser?.id
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({profile_img: ''});
  const [errors, setErrors] = useState({});
  const [imagePreviewUrl] = useState(Profile);
  const [profileExists,setProfileExist] = useState(false)
  const [savedData, setSavedData] = useState(null); // State to store saved data
  const [profileImage, setProfileImage] = useState(null)
  const totalSteps = 5; // Total number of steps in the form

  useEffect(() => {
    if (userRole === 'user') {
      fetchData();
    }
  }, [userRole]);

  const fetchData = async () => {
    try {
      const userRes = await http.get(`user/${userId}`);
      const apidata = userRes.data;
      const isProfileExist = apidata?.profileExists;
      setProfileExist(isProfileExist);
      let profiledata = null;
      if (isProfileExist) {
        const profileRes = await http.get(`user/profile/${userId}`);
        profiledata = profileRes?.data;
      }

      setFormData((prevData) => ({
        ...prevData,
        name: apidata?.data?.name || '',
        email: apidata?.data?.email || '',
        phone: profiledata?.data?.phone || '',
        dob: profiledata?.data?.dob || '',
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

const handleChange = (e, selectedOptions) => {
  const { name, value, files } = e.target;
  let selectedValue;
 // selectedNames = ''; // Remove this line
  
  if (selectedOptions) {
 //   selectedNames = selectedOptions.map((option) => option.label).join(",");
    selectedValue = selectedOptions.length > 0 ? selectedOptions[0].value : null;
  }

  if (files) {
    // If the target has files (i.e., file input), update formData with the file
    setFormData((prevData) => ({
      ...prevData,
      [name]: files[0], // Assign the file object
    }));
  }else {
    // If the target doesn't have files, update formData with the value
    setFormData((prevData) => ({
      ...prevData,
      [name]: value || selectedValue // Use selectedValue if available, otherwise use value
    }));
  }
};



  const handleNext = () => {
   // Validate current step's fields
   if (validateStep(step)) {
    // Save form data when moving to the next step
    setSavedData(formData);
    // If on the last step, submit form data
    if (step === totalSteps) {
      submitFormData();
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  }
  };

  const handlePrev = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = () => {
    // Validate all fields before submission
    if (validateStep(1) && validateStep(2)) {
      submitFormData();
    }
  };

  const validateStep = (step) => {
    let isValid = true;
    const stepErrors = {};

    switch (step) {
      case 1:
        if (!formData.name) {
          stepErrors.name = 'Full Name is required';
          isValid = false;
        }
        if (!formData.email) {
          stepErrors.email = 'Email is required';
          isValid = false;
        }
        if (!formData.phone) {
          stepErrors.phone = 'Phone is required';
          isValid = false;
        }
        if (!formData.password && userRole == 'admin') {
          stepErrors.password = 'Password is required';
          isValid = false;
        }
        if (!formData.dob) {
          stepErrors.dob = 'Date of Birth is required';
          isValid = false;
        }
       
        break;
      case 3:
        if (!formData.gender_id) {
          stepErrors.gender_id = 'Select ypur Gender is required';
          isValid = false;
        }
        if (!formData.body_type_id) {
          stepErrors.body_type_id = 'Select Your Body Type is required';
          isValid = false;
        }
        break;
      // Add cases for additional steps if needed
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      ...stepErrors,
    }));

    return isValid;
  };


const submitFormData = () => {
  const formDataWithFile = new FormData();
  // Append all form data fields;
  Object.entries(formData).forEach(([key, value]) => {
    formDataWithFile.append(key, value);
  });
  // Append the file
  formDataWithFile.append('profile_img', formData.profile_img);

  if(userRole == 'user'){
     // Send the FormData object to Laravel backend
  http.post('/user-profile', formDataWithFile, {
    headers: {
      'Content-Type': 'multipart/form-data', // Set content type to multipart/form-data
    },
  })
    .then(response => {
      // Handle any further actions after successful submission
      toast.success(`Profile Updated`)
      //navigate('/admin/user/read');
      handleClose();
      handleFormSubmit();
    })
    .catch(error => {
      console.error('Error submitting form data:', error);
      // Handle any errors
      toast.error(`Profile fail`)
    });
  }
  else{
  // Send the FormData object to Laravel backend
  http.post('/user-profile', formDataWithFile, {
    headers: {
      'Content-Type': 'multipart/form-data', // Set content type to multipart/form-data
    },
  })
    .then(response => {
      // Handle any further actions after successful submission
      toast.success(`Profile added`)
      navigate('/admin/user/read');
    })
    .catch(error => {
      console.error('Error submitting form data:', error);
      // Handle any errors
      toast.error(`Profile fail`)
    });
  }
};



  // Calculate progress percentage
  const progress = ((step - 1) / (totalSteps - 1)) * 100;

  return (
    <div>
      <div className="container p-3">
        <div className="d-flex justify-content-center">
          <div className={userRole === "admin" ? "col-md-10" : "col-md-12"}>
            <div className="card">
              <div className="card-header">
                {/* Step progress bar */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    <i className={step >= 1 ? 'fas fa-check-circle text-success me-2' : 'me-2'}></i>
                    Basic Details
                  </div>
                  <div>
                    <i className={step >= 3 ? 'fas fa-check-circle text-success me-2' : 'me-2'}></i>
                    Details
                  </div>
                  <div>
                    <i className={step >= 2 ? 'fas fa-check-circle text-success me-2' : 'me-2'}></i>
                    User Images
                  </div>
                  <div>
                    <i className={step >= 4 ? 'fas fa-check-circle text-success me-2' : 'me-2'}></i>
                    Interest
                  </div>
                  <div>
                    <i className={step >= 5 ? 'fas fa-check-circle text-success me-2' : 'me-2'}></i>
                    Preference
                  </div>
                  {/* <div>
                    <i className={step >= 6 ? 'fas fa-check-circle text-success me-2' : 'me-2'}></i>
                    Description
                  </div> */}
                </div>
                {/* Fancy progress bar */}
                <ProgressBar now={progress} label={`${progress}%`} animated striped variant="success" className="my-3" />
              </div>
              <div className="card-body">
                {/* Render steps based on current step */}
                {step === 1 && (
                  <StepOne
                    formData={formData}
                    setFormData={setFormData}
                    handleChange={handleChange}
                    errors={errors}
                    savedData={savedData}
                    profileExists={profileExists}
                  />
                )}
                {step === 2 && (
                  <StepThree
                  formData={formData}
                  setFormData={setFormData}
                  handleChange={handleChange}
                  errors={errors}
                  savedData={savedData}
                  profileExists={profileExists}
                />
                )}
                {step === 3 && (
                  <StepTwo
                  formData={formData}
                  setFormData={setFormData}
                  handleChange={handleChange}
                  errors={errors}
                  savedData={savedData}
                  imagePreviewUrl={imagePreviewUrl}
                />
                  
                )}
                
                {step === 4 && (
                  <StepFour
                    formData={formData}
                    setFormData={setFormData}
                    handleChange={handleChange}
                    errors={errors}
                    savedData={savedData}
                    profileExists={profileExists}
                  />
                )}
                {step === 5 && (
                  <StepFive
                    formData={formData}
                    setFormData={setFormData}
                    handleChange={handleChange}
                    errors={errors}
                    savedData={savedData}
                    profileExists={profileExists}
                  />
                )}

                {/* Navigation buttons */}
                <div className="d-flex justify-content-between">
                  <button
                    className="btn btn-primary"
                    onClick={handlePrev}
                    disabled={step === 1}
                  >
                    Previous
                  </button>
                  {step < totalSteps ? (
                    <button
                      className="btn btn-danger"
                      onClick={handleNext}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      className="btn btn-success"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserForm;

