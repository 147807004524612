import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import AuthUser from "../../../AuthUser";
import DataTable from "react-data-table-component";

function ReadInterest() {
  const {http} = AuthUser();
  const [apidata, setApidata] = useState([]);
  const [filterText, setFilterText] = useState('');
  const navigate = useNavigate();

  const columns = [
    { name: "Name", selector: (row) => row.title, sortable:true},
    { name: "Interest Category", selector: (row) => row.interest_category.title, sortable:true }, // Display religion name
    {
      name: 'Status',
      cell: (row) => (
       <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        id="flexSwitchCheckDefault"
        checked={row.status} // Set checked based on isChecked value
        disabled
      />
    </div>
      ),
    },
    {
      name: 'Options',
      cell: (row) => (
        <div>
          <button className="btn btn-success rounded-10 me-2" onClick={() => { handleEdit(row.id) }}>
            <i className="fa fa-edit"></i>
          </button>
          <button
            className="btn btn-danger rounded-10"
            onClick={() => {
              if (window.confirm("Are you sure to delete data ??")) {
                handleDelete(row.id);
              }
            }}
          >
            <i className="fa fa-trash-alt"></i>
          </button>
        </div>
      ),
    },
  ];

  function getdata() {
    http.get("/interest").then((res) => {
      console.log(res.data);
      setApidata(res.data);
    });
  }

  useEffect(() => {
    getdata();
  }, []);

  const filteredData = apidata.filter(item => 
    item.title.toLowerCase().includes(filterText.toLowerCase()) ||
    item.occupation.title.toLowerCase().includes(filterText.toLowerCase())
  );

  const paginationOptions = {
    rowsPerPageText: 'Items per Page' , // Text for "Rows per page" label
  };

  function handleDelete(id) {
    http.delete(`/interest/${id}`).then(() => {
      getdata();
      toast.success("interest Deleted successfully");
    });
  }
  
  function handleEdit(id) {
    navigate(`/admin/master/interest/edit/${id}`);
  }
  

  return (
    <div>
      <div className="container-fluid pt-4 px-4">
        <div className="d-flex align-items-center justify-content-center mb-4">
          <div className="col-md-10">
            
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h4 className="">interests</h4>
              <Link to={"/admin/master/interest/create"} className="btn btn-primary">Add New interest</Link>
            </div>

            <div className="bg-light text-center rounded p-4">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="mb-0">All interests</h5>
                <div className="col-4">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="form-control"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                  </div>
              </div>
                               
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
                paginationComponentOptions={paginationOptions}
              ></DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReadInterest;