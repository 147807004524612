import React, { useState, useEffect } from "react";
import Select from "react-select";
import AuthUser from "../../AuthUser";
import { Hearts } from 'react-loader-spinner'

function StepFive({ formData, handleChange ,setFormData, profileExists}) {
  const { http,getUser } = AuthUser();
  const [id] = useState();
  const currentUser = getUser();
  const userId = currentUser?.id
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [filteredStates, setFilteredStates] = useState([]);
  const [filteredCity, setFilteredCity] = useState([]);

  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [educations, setEducations] = useState([]);
  const [selectedEducation, setSelectedEducation] = useState(null);

  const [maritalstatus, setMaritalstatus] = useState([]);
  const [selectedMaritalstatus, setSelectedMaritalstatus] = useState(null);

  const [occupation, setOccupation] = useState([]);
  const [selectedOccupation, setSelectedOccupation] = useState(null);

  const [profession, setProfession] = useState([]);
  const [selectedProfession, setSelectedProfession] = useState(null);
  const [filteredProfession, setFilteredProfession] = useState([]);

  const [religion, setReligion] = useState([]);
  const [selectedReligion, setSelectedReligion] = useState(null);

  const [caste, setCaste] = useState([]);
  const [selectedCaste, setSelectedCaste] = useState(null);
  const [filteredCaste, setFilteredCaste] = useState([]);

  const [gender, setGender] = useState([]);
  const [selectedGender, setSelectedGender] = useState(null);

  const [bodyType, setBodyType] = useState([]);
  const [selectedBodyType, setSelectedBodyType] = useState(null);

  const [complexion, setComplexion] = useState([]);
  const [selectedComplexion, setSelectedComplexion] = useState(null);
  const [loading, setLoading] = useState(false);

   // Array of height options
   const heightOptions = [
    { value: 'Below 137', label: 'Below 4ft 6in - 137cm' },
    { value: '137', label: '4ft 6in - 137cm' },
    { value: '139', label: '4ft 7in - 139cm' },
    { value: '142', label: '4ft 8in - 142cm' },
    { value: '144', label: '4ft 9in - 144cm' },
    { value: '147', label: '4ft 10in - 147cm' },
    { value: '149', label: '4ft 11in - 149cm' },
    { value: '152', label: '5ft - 152cm' },
    { value: '154', label: '5ft 1in - 154cm' },
    { value: '157', label: '5ft 2in - 157cm' },
    { value: '160', label: '5ft 3in - 160cm' },
    { value: '162', label: '5ft 4in - 162cm' },
    { value: '165', label: '5ft 5in - 165cm' },
    { value: '167', label: '5ft 6in - 167cm' },
    { value: '170', label: '5ft 7in - 170cm' },
    { value: '172', label: '5ft 8in - 172cm' },
    { value: '175', label: '5ft 9in - 175cm' },
    { value: '177', label: '5ft 10in - 177cm' },
    { value: '180', label: '5ft 11in - 180cm' },
    { value: '182', label: '6ft - 182cm' },
    { value: '185', label: '6ft 1in - 185cm' },
    { value: '187', label: '6ft 2in - 187cm' },
    { value: '190', label: '6ft 3in - 190cm' },
    { value: '193', label: '6ft 4in - 193cm' },
    { value: '195', label: '6ft 5in - 195cm' },
    { value: '198', label: '6ft 6in - 198cm' },
    { value: '200', label: '6ft 7in - 200cm' },
    { value: '203', label: '6ft 8in - 203cm' },
    { value: '205', label: '6ft 9in - 205cm' },
    { value: '208', label: '6ft 10in - 208cm' },
    { value: '210', label: '6ft 11in - 210cm' },
    { value: '213', label: '7ft - 213cm' },
    { value: 'Above 213', label: 'Above 7ft - 213cm' },
  ];

  const weightOptions =[
    { "value": '40', "label": "40 Kg" },
    { "value": '41', "label": "41 Kg" },
    { "value": '42', "label": "42 Kg" },
    { "value": '43', "label": "43 Kg" },
    { "value": '44', "label": "44 Kg" },
    { "value": '45', "label": "45 Kg" },
    { "value": '46', "label": "46 Kg" },
    { "value": '47', "label": "47 Kg" },
    { "value": '48', "label": "48 Kg" },
    { "value": '49', "label": "49 Kg" },
    { "value": '50', "label": "50 Kg" },
    { "value": '51', "label": "51 Kg" },
    { "value": '52', "label": "52 Kg" },
    { "value": '53', "label": "53 Kg" },
    { "value": '54', "label": "54 Kg" },
    { "value": '55', "label": "55 Kg" },
    { "value": '56', "label": "56 Kg" },
    { "value": '57', "label": "57 Kg" },
    { "value": '58', "label": "58 Kg" },
    { "value": '59', "label": "59 Kg" },
    { "value": '60', "label": "60 Kg" },
    { "value": '61', "label": "61 Kg" },
    { "value": '62', "label": "62 Kg" },
    { "value": '63', "label": "63 Kg" },
    { "value": '64', "label": "64 Kg" },
    { "value": '65', "label": "65 Kg" },
    { "value": '66', "label": "66 Kg" },
    { "value": '67', "label": "67 Kg" },
    { "value": '68', "label": "68 Kg" },
    { "value": '69', "label": "69 Kg" },
    { "value": '70', "label": "70 Kg" },
    { "value": '71', "label": "71 Kg" },
    { "value": '72', "label": "72 Kg" },
    { "value": '73', "label": "73 Kg" },
    { "value": '74', "label": "74 Kg" },
    { "value": '75', "label": "75 Kg" },
    { "value": '76', "label": "76 Kg" },
    { "value": '77', "label": "77 Kg" },
    { "value": '78', "label": "78 Kg" },
    { "value": '79', "label": "79 Kg" },
    { "value": '80', "label": "80 Kg" },
    { "value": '81', "label": "81 Kg" },
    { "value": '82', "label": "82 Kg" },
    { "value": '83', "label": "83 Kg" },
    { "value": '84', "label": "84 Kg" },
    { "value": '85', "label": "85 Kg" },
    { "value": '86', "label": "86 Kg" },
    { "value": '87', "label": "87 Kg" },
    { "value": '88', "label": "88 Kg" },
    { "value": '89', "label": "89 Kg" },
    { "value": '90', "label": "90 Kg" },
    { "value": '91', "label": "91 Kg" },
    { "value": '92', "label": "92 Kg" },
    { "value": '93', "label": "93 Kg" },
    { "value": '94', "label": "94 Kg" },
    { "value": '95', "label": "95 Kg" },
    { "value": '96', "label": "96 Kg" },
    { "value": '97', "label": "97 Kg" },
    { "value": '98', "label": "98 Kg" },
    { "value": '99', "label": "99 Kg" },
    { "value": '100', "label": "100 Kg" },
    { "value": '101', "label": "101 Kg" },
    { "value": '102', "label": "102 Kg" },
    { "value": '103', "label": "103 Kg" },
    { "value": '104', "label": "104 Kg" },
    { "value": '105', "label": "105 Kg" },
    { "value": '106', "label": "106 Kg" },
    { "value": '107', "label": "107 Kg" },
    { "value": '108', "label": "108 Kg" },
    { "value": '109', "label": "109 Kg" },
    { "value": '110', "label": "110 Kg" },
    { "value": '111', "label": "111 Kg" },
    { "value": '112', "label": "112 Kg" },
    { "value": '113', "label": "113 Kg" },
    { "value": '114', "label": "114 Kg" },
    { "value": '115', "label": "115 Kg" },
    { "value": '116', "label": "116 Kg" },
    { "value": '117', "label": "117 Kg" },
    { "value": '118', "label": "118 Kg" },
    { "value": '119', "label": "119 Kg" },
    { "value": '120', "label": "120 Kg" },
    { "value": '121', "label": "121 Kg" },
    { "value": '122', "label": "122 Kg" },
    { "value": '123', "label": "123 Kg" },
    { "value": '124', "label": "124 Kg" },
    { "value": '125', "label": "125 Kg" },
    { "value": '126', "label": "126 Kg" },
    { "value": '127', "label": "127 Kg" },
    { "value": '128', "label": "128 Kg" },
    { "value": '129', "label": "129 Kg" },
    { "value": '130', "label": "130 Kg" },
    { "value": '131', "label": "131 Kg" },
    { "value": '132', "label": "132 Kg" },
    { "value": '133', "label": "133 Kg" },
    { "value": '134', "label": "134 Kg" },
    { "value": '135', "label": "135 Kg" },
    { "value": '136', "label": "136 Kg" },
    { "value": '137', "label": "137 Kg" },
    { "value": '138', "label": "138 Kg" },
    { "value": '139', "label": "139 Kg" },
    { "value": '140', "label": "140 Kg" },

  ]



  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const countryResponse = await http.get("/countries");
        setCountries(countryResponse.data);

        const stateResponse = await http.get(`/states`);
        setStates(stateResponse.data);

        const cityResponse = await http.get(`/cities`);
        setCities(cityResponse.data);

        const languageResponse = await http.get(`/language`);
        setLanguages(languageResponse.data);

        const educationResponse = await http.get(`/educations`);
        setEducations(educationResponse.data);

        const maritalstatusResponse = await http.get(`/maritalstatus`);
        setMaritalstatus(maritalstatusResponse.data);

        const occupationResponse = await http.get(`/occupation`);
        setOccupation(occupationResponse.data);

        const professionResponse = await http.get(`/profession`);
        setProfession(professionResponse.data);

        const religionResponse = await http.get(`/religion`);
        setReligion(religionResponse.data);

        const casteResponse = await http.get(`/caste`);
        setCaste(casteResponse.data);

        const genderResponse = await http.get(`/gender`);
        setGender(genderResponse.data);

        const bodyTypeResponse = await http.get(`/bodyType`);
        setBodyType(bodyTypeResponse.data);

        const complexionResponse = await http.get(`/complexion`);
        setComplexion(complexionResponse.data);

        if (userId && profileExists) {
          const userResponse = await http.get(`/user/profile/${userId}`);
          const {
            country_id,
            state_id,
            pref_city_id,
            pref_language_id,
            pref_education_id,
            pref_maritalstatus_id,
            pref_occupation_id,
            pref_profession_id,
            pref_religion_id,
            pref_caste_id,
            pref_gender_id,
            pref_body_type_id,
            pref_complexion_id,
            pref_height_from,
            pref_height_to,
            pref_weight_from,
            pref_weight_to
          } = userResponse.data.data;


          setFormData(prevFormData => ({
            ...prevFormData,
            country_id:country_id,
            state_id:state_id,
            pref_city_id:pref_city_id,
            pref_language_id:pref_language_id,
            pref_education_id:pref_education_id,
            pref_maritalstatus_id:pref_maritalstatus_id,
            pref_occupation_id:pref_occupation_id,
            pref_profession_id:pref_profession_id,
            pref_religion_id:pref_religion_id,
            pref_caste_id:pref_caste_id,
            pref_gender_id:pref_gender_id,
            pref_body_type_id:pref_body_type_id,
            pref_complexion_id:pref_complexion_id,
            pref_height_from:pref_height_from,
            pref_height_to:pref_height_to,
            pref_weight_from:pref_weight_from,
            pref_weight_to:pref_weight_to
          }));

          const selectedCountryData = countryResponse.data.find(
            (country) => country.id === country_id
          );
          if (selectedCountryData) {
            setSelectedCountry({
              value: selectedCountryData.id,
              label: selectedCountryData.name,
            });
          }

          const selectedStateData = stateResponse.data.find(
            (state) => state.id === state_id
          );
          if (selectedStateData) {
            setSelectedState({
              value: selectedStateData.id,
              label: selectedStateData.name,
            });
          }

          const cityIds = parsePrefIds(pref_city_id || '');
          if (cityIds) {
            setSelectedCity(cityIds.map(cityid => {
              const city = cityResponse.data.find(city => city.id === parseInt(cityid));
              return { value: city?.id || '', label: city?.name || '' };
            }));
          }
          
          const languageIds = parsePrefIds(pref_language_id || '');
          if (languageIds) {
            setSelectedLanguage(languageIds.map(langId => {
              const language = languageResponse.data.find(language => language.id === parseInt(langId));
              return { value: language?.id || '', label: language?.title || '' };
            }));
          }

          const educationIds = parsePrefIds(pref_education_id || '');
          if (educationIds) {
            setSelectedEducation(educationIds.map(edgId => {
              const education = educationResponse.data.find(education => education.id === parseInt(edgId));
              return { value: education?.id || '', label: education?.title || '' };
            }));
          }

          const maritalstatusIds = parsePrefIds(pref_maritalstatus_id || '');
          if (maritalstatusIds) {
            setSelectedMaritalstatus(maritalstatusIds.map(maritalstatusid => {
              const maritalstatus = maritalstatusResponse.data.find(maritalstatus => maritalstatus.id === parseInt(maritalstatusid));
              return { value: maritalstatus?.id || '', label: maritalstatus?.title || '' };
            }));
          }

          const occupationIds = parsePrefIds(pref_occupation_id || '');
          if (occupationIds) {
            setSelectedOccupation(occupationIds.map(occupationid => {
              const occupation = occupationResponse.data.find(occupation => occupation.id === parseInt(occupationid));
              return { value: occupation?.id || '', label: occupation?.title || '' };
            }));
          }

          const professionIds = parsePrefIds(pref_profession_id || '')
          if (professionIds) {
            setSelectedProfession(professionIds.map(professionid => {
              const profession = professionResponse.data.find(profession => profession.id === parseInt(professionid));
              return { value: profession?.id || '', label: profession?.title || '' };
            }));
          }

          const religionIds = parsePrefIds(pref_religion_id || '')
          if (religionIds) {
            setSelectedReligion(religionIds.map(religionid => {
              const religion = religionResponse.data.find(religion => religion.id === parseInt(religionid));
              return { value: religion?.id || '', label: religion?.title || '' };
            }));
          }

          const casteIds = parsePrefIds(pref_caste_id || '')
          if (casteIds) {
            setSelectedCaste(casteIds.map(casteid => {
              const caste = casteResponse.data.find(caste => caste.id === parseInt(casteid));
              return { value: caste?.id || '', label: caste?.title || '' };
            }));
          }

          const genderIds = parsePrefIds(pref_gender_id || '')
          if (genderIds) {
            setSelectedGender(genderIds.map(genderid => {
              const gender = genderResponse.data.find(gender => gender.id === parseInt(genderid));
              return { value: gender?.id || '', label: gender?.title || '' };
            }));
          }

          const bodytypeIds = parsePrefIds(pref_body_type_id || '')
          if (bodytypeIds) {
            setSelectedBodyType(bodytypeIds.map(bodytypeid => {
              const bodytype = bodyTypeResponse.data.find(bodytype => bodytype.id === parseInt(bodytypeid));
              return { value: bodytype?.id || '', label: bodytype?.title || '' };
            }));
          }

          const complexionIds = parsePrefIds(pref_complexion_id || '')
          if (complexionIds) {
            setSelectedComplexion(complexionIds.map(complexionid => {
              const complexion = complexionResponse.data.find(complexion => complexion.id === parseInt(complexionid));
              return { value: complexion?.id || '', label: complexion?.title || '' };
            }));
          }
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const initialFilteredStates = states.filter(
      (state) => state.country_id === selectedCountry?.value
    );
    setFilteredStates(initialFilteredStates);

    fetchData();
  }, [id]);

  const parsePrefIds = (prefIds) => {
    return prefIds.split(',').map(id => id.trim()).filter(Boolean);
  };


  useEffect(() => {
    if (selectedCountry) {
      const filteredStates = states.filter(state => state.country_id === selectedCountry.value);
      setFilteredStates(filteredStates);
    }
  }, [selectedCountry, states]);

  useEffect(() => {
    if (selectedState) {
      const filteredCities = cities.filter(city => city.state_id === selectedState.value);
      setFilteredCity(filteredCities);
    }
  }, [selectedState, cities]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    handleChange({ target: { name: "country_id", value: selectedOption ? selectedOption.value : null } });
    // Filter states based on selected country
    const filteredStates = states.filter(
      (state) => state.country_id === selectedOption.value
    );
    setFilteredStates(filteredStates);

    // Reset selected state when country changes
    setSelectedState(null);
    setSelectedCity(null);
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
 handleChange({ target: { name: "state_id", value: selectedOption ? selectedOption.value : null } });
    // Filter cities based on selected state
    const filteredCity = cities.filter(
      (city) => city.state_id === selectedOption.value
    );
    setFilteredCity(filteredCity);

    setSelectedCity(null);
  };

  const handleOccupationChange = (selectedOption) => {
        setSelectedOccupation(selectedOption);
    // Filter states based on selected country;
    console.log(selectedOption);
    const filteredProfession = profession.filter(
      profession => selectedOption && selectedOption.some(option => option.value === profession.occupation_id)
    );
    console.log('filteredProfession',filteredProfession);

    const selectedOccupationIds = selectedOption ? selectedOption.map(option => option.value) : [];
    handleChange({ target: { name: "pref_occupation_id", value: selectedOccupationIds } });

    setFilteredProfession(filteredProfession);

    // Reset selected state when country changes
    //setSelectedProfession(null);
  };

  const handleReligionChange = (selectedOption) => {
    setSelectedReligion(selectedOption);

    const selectedReligionIds = selectedOption ? selectedOption.map(option => option.value) : [];
    handleChange({ target: { name: "pref_religion_id", value: selectedReligionIds } });

    // Filter states based on selected country
    const filteredCaste = caste.filter(
      (caste) => selectedOption && selectedOption.some(option => option.value === caste.religion_id)
      
    );
    setFilteredCaste(filteredCaste);
  };


  const handleCityChange = (selectedOptions) => {
    setSelectedCity(selectedOptions);
    const selectedCityIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
    handleChange({ target: { name: "pref_city_id", value: selectedCityIds } });
  };

  const handleLanguageChange = (selectedOptions) => {
    setSelectedLanguage(selectedOptions);
    const selectedLanguageIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
    handleChange({ target: { name: "pref_language_id", value: selectedLanguageIds } });
  };
  
  const handleEducationChange = (selectedOptions) => {
    setSelectedEducation(selectedOptions);
   const selectedEducationIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
    handleChange({ target: { name: "pref_education_id", value: selectedEducationIds } });
  };

  
  const handleProfessionChange = (selectedOptions) => {
    setSelectedProfession(selectedOptions);
    const selectedProfessionIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
    handleChange({ target: { name: "pref_profession_id", value: selectedProfessionIds } });
  };
  
  
const handleMaritalStatusChange = (selectedOptions) => {
  setSelectedMaritalstatus(selectedOptions);
  const selectedMaritalStatusIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
  handleChange({ target: { name: "pref_maritalstatus_id", value: selectedMaritalStatusIds } });
};

const handleGenderChange = (selectedOptions) => {
  setSelectedGender(selectedOptions);
  const selectedGenderIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
  handleChange({ target: { name: "pref_gender_id", value: selectedGenderIds } });
};

  const handleCasteChange = (selectedOptions) => {
    setSelectedCaste(selectedOptions);
    // const selectedCasteIds = selectedOptions.map(option => option.value);
    const selectedCasteIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
    handleChange({ target: { name: "pref_caste_id", value: selectedCasteIds } });
  };
 
  const handleBodyTypeChange = (selectedOptions) => {
    setSelectedBodyType(selectedOptions);
    const selectedBodyTypeIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
    handleChange({ target: { name: "pref_body_type_id", value: selectedBodyTypeIds } });
  };
  
  const handleComplexionChange = (selectedOptions) => {
    setSelectedComplexion(selectedOptions);
    const selectedComplexionIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
    handleChange({ target: { name: "pref_complexion_id", value: selectedComplexionIds } });
  };

  return (
    <div>
      <form>
        <div className="mb-3">
          <h4>User Preference Details</h4>
        </div>
        {loading ? (
        <div className="spinner-container">
          <Hearts
            height="80"
            width="80"
            color="red"
            ariaLabel="loading"
          />
        </div>
      ) :
        <div className="row mb-4">
          <div className="col-sm-4">
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                Country
              </label>
              <Select
                id="countryInput"
                options={countries.map((country) => ({
                  value: country.id,
                  label: country.name,
                }))}
                value={selectedCountry}
                onChange={(selectedOption) => {
                  handleCountryChange(selectedOption);
                  handleChange({
                    target: {
                      name: "country_id",
                      value: selectedOption ? selectedOption.value : null,
                    },
                  });
                }}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                State
              </label>
              <Select
                id="StateInput"
                options={filteredStates.map((state) => ({
                  value: state.id,
                  label: state.name,
                }))} // Use filteredStates
                value={selectedState}
                // onChange={handleStateChange} // Keep it as it is if you don't need any additional logic
                onChange={(selectedOption) => {
                  handleStateChange(selectedOption);
                  handleChange({
                    target: {
                      name: "state_id",
                      value: selectedOption ? selectedOption.value : null,
                    },
                  });
                }}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                City
              </label>
              <Select
                id="CityInput"
                options={filteredCity.map((city) => ({
                  value: city.id,
                  label: city.name,
                }))}
                value={selectedCity}
                isMulti
                onChange={handleCityChange}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-6 mb-4">
            <div>
              <label htmlFor="">Mother Toung</label>
              <Select
                options={languages.map((language) => ({
                  value: language.id,
                  label: language.title,
                }))}
                value={selectedLanguage}
                onChange={handleLanguageChange}
                isMulti
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div>
              <label htmlFor="">Education</label>

              <Select
                options={educations.map((education) => ({
                  value: education.id,
                  label: education.title,
                }))}
                value={selectedEducation}
                onChange={handleEducationChange}
                isMulti
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div>
              <label htmlFor="">Maretial Status</label>
              <Select
                options={maritalstatus.map((maritalstatus) => ({
                  value: maritalstatus.id,
                  label: maritalstatus.title,
                }))}
                value={selectedMaritalstatus}
                isMulti
                onChange={handleMaritalStatusChange}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-6 mb-4">
            <div>
              <label htmlFor="">Gender</label>
              <Select
                options={gender.map((gender) => ({
                  value: gender.id,
                  label: gender.title,
                }))}
                value={selectedGender}
                isMulti
                onChange={handleGenderChange}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-6 mb-4">
            <div>
              <label htmlFor="">Occupation</label>
              <Select
                options={occupation.map((occupation) => ({
                  value: occupation.id,
                  label: occupation.title,
                }))}
                value={selectedOccupation}
                isMulti
                onChange={(selectedOption) => {
                  handleOccupationChange(selectedOption);
                }}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div>
              <label htmlFor="">Profession</label>
              <Select
                options={filteredProfession.map((profession) => ({
                  value: profession.id,
                  label: profession.title,
                }))}
                value={selectedProfession}
                onChange={handleProfessionChange}
                isMulti
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-6 mb-4">
            <div>
              <label htmlFor="">Religion</label>
              <Select
                options={religion.map((religion) => ({
                  value: religion.id,
                  label: religion.title,
                }))}
                value={selectedReligion}
                isMulti
                onChange={(selectedOption) => {
                  handleReligionChange(selectedOption);
                }}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-sm-6 mb-4">
            <div>
              <label htmlFor="">Caste</label>
              <Select
                options={filteredCaste.map((caste) => ({
                  value: caste.id,
                  label: caste.title,
                }))}
                value={selectedCaste}
                onChange={handleCasteChange}
                isMulti
                isSearchable={true}
              />
            </div>
          </div>
          
          <div className="col-sm-6 mb-4">
            <div>
              <label htmlFor="">Body Type</label>
              <Select
                options={bodyType.map((bodyType) => ({
                  value: bodyType.id,
                  label: bodyType.title,
                }))}
                value={selectedBodyType}
                onChange={handleBodyTypeChange}
                isMulti
                isSearchable={true}
              />
            </div>
          </div>

          <div className="col-sm-6 mb-4">
            <div>
              <label htmlFor="">Complexion</label>
              <Select
                options={complexion.map((complexion) => ({
                  value: complexion.id,
                  label: complexion.title,
                }))}
                value={selectedComplexion}
                onChange={handleComplexionChange}
                isMulti
                isSearchable={true}
              />
            </div>
          </div>



          <div className="col-sm-6">
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                Height from
              </label>
              <select
                id="pref_height_from"
                name="pref_height_from"
                onChange={handleChange}
                value={formData.pref_height_from}
              >
                <option value="">Select Height</option>
                {heightOptions.map((height) => (
                  <option key={height.value} value={height.value}>
                    {height.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                Height to
              </label>
              <select
                id="pref_height_to"
                name="pref_height_to"
                onChange={handleChange}
                value={formData.pref_height_to}
              >
                <option value="">Select Height</option>
                {heightOptions.map((height) => (
                  <option key={height.value} value={height.value}>
                    {height.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                Weight from
              </label>
              <select
                id="pref_weight_from"
                name="pref_weight_from"
                onChange={handleChange}
                value={formData.pref_weight_from}
              >
                <option value="">Select Weight</option>
                {weightOptions.map((weight) => (
                  <option key={weight.value} value={weight.value}>
                    {weight.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group mb-3">
              <label className="mb-2" htmlFor="">
                Weight t0
              </label>
              <select
                id="pref_weight_to"
                name="pref_weight_to"
                onChange={handleChange}
                value={formData.pref_weight_to}
              >
                <option value="">Select Weight</option>
                {weightOptions.map((weight) => (
                  <option key={weight.value} value={weight.value}>
                    {weight.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
         }
      </form>
    </div>
  );
}

export default StepFive;