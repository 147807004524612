import React, { useState } from "react";

function Faqs() {
  // Array of FAQ objects
  const faqs = [
    {
      question: "What is LiveInGo?",
      answer:
        "LiveInGo is an online dating platform designed to help individuals connect with like-minded people for meaningful relationships, friendships, or casual dating experiences."
    },
    {
      question: "How does LiveInGo work?",
      answer:
        "Signing up for LiveInGo is easy! Simply create a profile, add some information about yourself, upload photos, and start browsing through profiles of other members. You can search for matches based on various criteria such as age, location, interests, and more. Once you find someone you're interested in, you can send them a message and start getting to know each other."
    },
    {
      question: "Is LiveInGo free to use?",
      answer:
        "Yes, LiveInGo offers a free basic membership that allows you to create a profile, browse through profiles of other members, and send a limited number of messages. However, we also offer premium membership options that provide additional features and benefits, such as unlimited messaging, advanced search options, and the ability to see who has viewed your profile."
    },


    {
        question: "Is LiveInGo safe and secure?",
        answer:
          "At LiveInGo, we take the safety and security of our members very seriously. We employ a variety of measures to ensure that our platform remains a safe and welcoming environment for all users. This includes manual profile verification, encryption of sensitive data, and proactive moderation of content to prevent harassment or inappropriate behavior."
      },

      {
        question: "Can I delete my LiveInGo account?",
        answer:
          "Yes, you can delete your LiveInGo account at any time by accessing the account settings section of your profile. Keep in mind that once you delete your account, all of your profile information, messages, and matches will be permanently removed from our system."
      },

      {
        question: "How can I contact LiveInGo customer support?",
        answer:
          "If you have any questions, concerns, or feedback, our customer support team is here to help! You can reach us by sending an email to <a href='mailto:support@liveingo.com'>support@liveingo.com</a>. We strive to respond to all inquiries promptly and provide assistance whenever needed."
      },

      {
        question: "What should I do if I encounter a suspicious or abusive user?",
        answer:
          "If you encounter a user who is engaging in suspicious or abusive behavior, we encourage you to report them to our moderation team immediately. You can do this by clicking on the 'Report' button on their profile or sending us a message detailing the issue. We will investigate the matter thoroughly and take appropriate action to ensure the safety of our community."
      },

      {
        question: "How can I improve my chances of finding a match on LiveInGo?",
        answer:
          "To increase your chances of finding a compatible match on LiveInGo, we recommend taking the time to create a detailed and genuine profile that accurately reflects your personality and interests. Additionally, actively engage with other members by sending messages, participating in group discussions, and attending virtual events hosted on our platform."
      },
    
  ];

  const [openIndex, setOpenIndex] = useState(0);

  const toggleAccordion = (index) => {
    setOpenIndex(index === openIndex ? -1 : index);
  };

  return (
    <div>
      <div className="pt-4">
        <div className="card shadow">
          <div className="card-header text-center">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="p-4">
            <div className="card">
              <div className="accordion" id="accordionExample">
                {/* Map over the faqs array to generate accordion items */}
                {faqs.map((faq, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={`heading${index}`}>
                      <button
                        className={`accordion-button fw-bold ${index === openIndex ? 'active' : ''}`}
                        type="button"
                        onClick={() => toggleAccordion(index)} 
                      >
                        {faq.question}
                        {index === openIndex ? <i className="fas fa-chevron-up pos-r"></i> : <i className="fas fa-chevron-down pos-r"></i>}
                      </button>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      className={`accordion-collapse collapse ${index === openIndex ? "show" : ""}`}
                      aria-labelledby={`heading${index}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faqs;
