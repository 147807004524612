import React from 'react';

function MyFavourites() {
        return (
            <div>
                <div className="card shadow p-4">
                    No data Found
                </div>
            </div>
        );
}

export default MyFavourites;