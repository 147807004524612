import React, { Component } from "react";
import { NavLink } from "react-router-dom";


class Header extends Component {
  render() {
    return (
      <div id="layoutSidenav_nav">
          <nav
            className="sb-sidenav accordion sb-sidenav-dark"
            id="sidenavAccordion"
          >
            <div className="sb-sidenav-menu">
              <div className="nav">
                <NavLink className="nav-link" to={"admin/dashboard"}>
                  <div className="sb-nav-link-icon">
                    <i className="fas fa-tachometer-alt"></i>
                  </div>
                  Dashboard
                </NavLink>

                <NavLink
                  className="nav-link collapsed"
                  to={"/MasterForm"}
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsePages"
                  aria-expanded="false"
                  aria-controls="collapsePages"
                >
                  <div className="sb-nav-link-icon">
                    <i className="fas fa-book-open"></i>
                  </div>
                  Master
                  <div className="sb-sidenav-collapse-arrow">
                    <i className="fas fa-angle-down"></i>
                  </div>
                </NavLink>
                <div
                  className="collapse"
                  id="collapsePages"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#sidenavAccordion"
                >
                  <nav
                    className="sb-sidenav-menu-nested nav accordion"
                    id="sidenavAccordionPages"
                  >
                    <NavLink
                      className="nav-link collapsed"
                      to={"/Location"}
                      data-bs-toggle="collapse"
                      data-bs-target="#pagesCollapseLocation"
                      aria-expanded="false"
                      aria-controls="pagesCollapseLocation"
                    >
                      <i className="fas fa-map-marker-alt me-2"></i>Localtion
                      <div className="sb-sidenav-collapse-arrow">
                        <i className="fas fa-angle-down"></i>
                      </div>
                    </NavLink>
                    <div
                      className="collapse"
                      id="pagesCollapseLocation"
                      aria-labelledby="headingOne"
                      data-bs-parent="#sidenavAccordionPages"
                    >
                      <nav className="sb-sidenav-menu-nested nav">
                        <NavLink
                          to={"admin/master/country/read"}
                          className="nav-link"
                        >
                          Country
                        </NavLink>
                        <NavLink
                          to={"admin/master/state/read"}
                          className="nav-link"
                        >
                          State
                        </NavLink>
                        <NavLink
                          to={"admin/master/city/read"}
                          className="nav-link"
                        >
                          City
                        </NavLink>
                      </nav>
                    </div>

                    <NavLink
                      className="nav-link collapsed"
                      to={"/Religions"}
                      data-bs-toggle="collapse"
                      data-bs-target="#pagesCollapseReligion"
                      aria-expanded="false"
                      aria-controls="pagesCollapseReligion"
                    >
                      <i className="fas fa-praying-hands me-2"></i>Religions
                      <div className="sb-sidenav-collapse-arrow">
                        <i className="fas fa-angle-down"></i>
                      </div>
                    </NavLink>
                    <div
                      className="collapse"
                      id="pagesCollapseReligion"
                      aria-labelledby="headingOne"
                      data-bs-parent="#sidenavAccordionPages"
                    >
                      <nav className="sb-sidenav-menu-nested nav">
                        <NavLink
                          to={"admin/master/religion/read"}
                          className="nav-link"
                        >
                          Religions
                        </NavLink>
                        <NavLink
                          to={"admin/master/caste/read"}
                          className="nav-link"
                        >
                          Castes
                        </NavLink>
                      </nav>
                    </div>

                    <NavLink
                      className="nav-link collapsed"
                      to={"/Religions"}
                      data-bs-toggle="collapse"
                      data-bs-target="#pagesCollapseInterest"
                      aria-expanded="false"
                      aria-controls="pagesCollapseInterest"
                    >
                      <i className="fas fa-puzzle-piece me-2"></i>Interest
                      <div className="sb-sidenav-collapse-arrow">
                        <i className="fas fa-angle-down"></i>
                      </div>
                    </NavLink>
                    <div
                      className="collapse"
                      id="pagesCollapseInterest"
                      aria-labelledby="headingOne"
                      data-bs-parent="#sidenavAccordionPages"
                    >
                      <nav className="sb-sidenav-menu-nested nav">
                      <NavLink
                      to={"admin/master/interestCategory/read"}
                      className="nav-link"
                    >
                      Category
                    </NavLink>
                    <NavLink
                      to={"admin/master/interest/read"}
                      className="nav-link"
                    >
                      Interested
                      In
                    </NavLink>
                      </nav>
                    </div>

                    <NavLink
                      className="nav-link collapsed"
                      to={"/work"}
                      data-bs-toggle="collapse"
                      data-bs-target="#pagesCollapseOccupation"
                      aria-expanded="false"
                      aria-controls="pagesCollapseOccupation"
                    >
                      <i className="fas fa-briefcase me-2"></i>Work Info
                      <div className="sb-sidenav-collapse-arrow">
                        <i className="fas fa-angle-down"></i>
                      </div>
                    </NavLink>
                    <div
                      className="collapse"
                      id="pagesCollapseOccupation"
                      aria-labelledby="headingOne"
                      data-bs-parent="#sidenavAccordionPages"
                    >
                      <nav className="sb-sidenav-menu-nested nav">
                      <NavLink
                      to={"admin/master/Occupation/read"}
                      className="nav-link"
                    >
                     Occupation
                    </NavLink>
                    <NavLink
                      to={"admin/master/profession/read"}
                      className="nav-link"
                    >
                      Profession
                    </NavLink>
                      </nav>
                    </div>

                    <NavLink
                      to={"admin/master/maritalstatus/read"}
                      className="nav-link"
                    >
                      <i className="fas fa-ring me-2"></i>Marital Statuses
                    </NavLink>
                    <NavLink
                      to={"admin/master/gender/read"}
                      className="nav-link"
                    >
                     <i className="fas fa-transgender-alt me-2"></i>Gender
                    </NavLink>
                    <NavLink
                      to={"admin/master/education/read"}
                      className="nav-link"
                    >
                      <i className="fas fa-graduation-cap me-2"></i>Educations
                    </NavLink>
                    
                    <NavLink
                      to={"admin/master/salaryrange/read"}
                      className="nav-link"
                    >
                      <i className="fas fa-wallet me-2"></i>Salary Range
                    </NavLink>
                    <NavLink
                      to={"admin/master/language/read"}
                      className="nav-link"
                    >
                      <i className="fas fa-language me-2"></i>Language
                    </NavLink>
                    <NavLink
                      to={"admin/master/bodyType/read"}
                      className="nav-link"
                    >
                      <i className="fas fa-child me-2"></i>Body Type
                    </NavLink>
                    
                    <NavLink
                      to={"admin/master/complexion/read"}
                      className="nav-link"
                    >
                      <i className="fas fa-tint me-2"></i>Complexion
                    </NavLink>
                    {/* <NavLink
                      to={"admin/master/height/read"}
                      className="nav-link"
                    >
                      <i className="fas fa-ruler-vertical me-2"></i>Height
                    </NavLink>
                    <NavLink
                      to={"admin/master/weight/read"}
                      className="nav-link"
                    >
                      <i className="fas fa-weight me-2"></i>Weight
                    </NavLink> */}
                  </nav>
                </div>

                <NavLink className="nav-link" to={"admin/user/read"}>
                  <div className="sb-nav-link-icon">
                  <i className="fas fa-user"></i>
                  </div>
                  User
                </NavLink>
              </div>
            </div>            
          </nav>
      </div>
    );
  }
}

export default Header;
