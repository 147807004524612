// App.jsx
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Guest from "./components/guest.jsx";
import Auth from "./components/Auth.jsx";
import AuthUser from "./components/AuthUser.jsx";
import Login from './components/admin/login.jsx';
import PageNotFound from './components/PageNotFound.jsx';

function App() {
  const { getToken, getUser } = AuthUser(); // Assuming this hook returns getToken properly
  const currentUser = getUser();
  return (
    <Routes>
      {getToken() ? (
        // Check the user's role
        currentUser.role_name === 'admin' ? (
          // Render admin routes if the user is an admin
          <Route path="/*" element={<Auth />} />
        ) : (
          // Render user routes if the user is not an admin
          <Route path="/*" element={<Guest />} />
        )
      ) : (
        // If user is not authenticated, render guest routes
        <Route path="/*" element={<Guest />} />
      )}
       <Route path='admin' element={<Login/>}>
        <Route path="login" element={<Login />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;

