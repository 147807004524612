import React from "react";
import bg1 from './../../assets/img/bg1.jpg';
import { useNavigate } from "react-router-dom";

function AfterRegistration() {

  const navigate = useNavigate();

  return (
    <div>
      <div className="container-fluid pt-4 login-bg-color">
        <div className="row p-5">
          <div className="offset-sm-1 col-sm-5 ">
            <img src={bg1} alt="" className='img-fluid'/>
          </div>
          <div className="offset-sm-1 col-sm-5">
            <div className="p-3">
              <div className="card p-4 bg-light shadow">
                <h4 className="text-center mb-4">Verified Successfully</h4>
                <p>Your email verification has been done successfully. Now you can Login</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AfterRegistration;
